import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase";
import { db } from "@/firebase";
import { doc, updateDoc, arrayRemove } from "firebase/firestore";

const notifications = {
  state: {
    notifications: [],
  },
  mutations: {
    setNotifications(state, val) {
      state.notifications = val;
    },
    removeNotification(state, val) {
      let i = state.notifications.findIndex((i) => i.uid === val);
      state.notifications.splice(i, 1);
    },
  },
  actions: {
    async fetchNotifications({ commit }) {
      commit("setLoading", { type: "notifications", val: true });

      const fetch = httpsCallable(functions, "notificationsGet");

      const res = await fetch();
      commit("setNotifications", res.data);
      commit("setLoading", { type: "notifications", val: false });
    },
    async markAsSeen({ commit, dispatch, rootState }, uid) {
      commit("removeNotification", uid);
      await updateDoc(doc(db, "users", rootState.users.userProfile.uid), {
        notifications: arrayRemove(uid),
      });
      dispatch("fetchNotifications");
    },
  },
  getters: {},
};

export default notifications;
