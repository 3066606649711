import Vue from "vue";
import Vuex from "vuex";
import { db } from "@/firebase";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase";
import { getDocs, collection } from "firebase/firestore";

// Import stores
import facebook from "./facebook";
import posts from "./posts";
import inbox from "./inbox";
import users from "./users";
import schedules from "./schedules";
import media from "./media";
import templates from "./templates";
import admin from "./admin";
import workspace from "./workspace";
import notifications from "./notifications";
import chats from "./chats";
import topics from "./topics";
import post from "./post";
import structures from "./structures";
import template from "./template";
import medium from "./medium";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accounts: [],
    openai: false,
    queue: false,
    onboarding: false,
    loading: {
      accounts: false,
      admin: {
        emails: false,
        workspaces: false,
        users: false,
        articles: false,
        calendar: false,
        posts: false,
        backlog: false,
        notifications: false,
      },
      calendar: false,
      categories: false,
      general: false,
      media: false,
      notifications: false,
      posts: false,
      schedules: false,
      userProfile: false,
      workspace: false,
      ai: {
        generate: false,
        complete: false,
        translate: false,
        spelling: false,
        variation: false,
        anything: false,
        topics: false,
      },
      chats: false,
      topics: false,
      joining: false,
      structures: false,
      templates: false,
    },
    notification: {
      show: false,
      text: "Your post has beed added!",
      type: "primary",
      icon: "delete",
      redirect: "",
      action_text: "",
    },
  },
  getters: {},
  mutations: {
    setLoading(state, val) {
      if (val.type.includes(".")) {
        let type = val.type.split(".");
        let a = type[0];
        let b = type[1];
        state.loading[a][b] = val.val;
      } else {
        state.loading[val.type] = val.val;
      }
    },
    setAccounts(state, val) {
      state.accounts = val;
    },
    setNotification(state, val) {
      state.notification = val;
    },
    setOpenai(state, val) {
      state.openai = val;
    },
    setOnboarding(state, val) {
      state.onboarding = val;
    },
    setQueue(state, val) {
      state.queue = val;
    },
  },
  actions: {
    async fetchAccounts({ commit, state }) {
      commit("setLoading", { type: "accounts", val: true });

      // TO DO return accounts without tokens
      let accounts = [];
      const feed = httpsCallable(functions, "facebookFeed");
      const instaFeed = httpsCallable(functions, "instagramFeed");
      const getInsights = httpsCallable(functions, "facebookInsightsGet");
      const validate = httpsCallable(functions, "facebookValidate");

      const querySnapshot = await getDocs(
        collection(
          db,
          "workspaces",
          state.users.userProfile.workspace,
          "accounts"
        )
      );

      for (const doc of querySnapshot.docs) {
        let a = doc.data();
        let token = a.access_token;

        const val = await validate({
          access_token: token,
        });
        let valid = val.data;

        if (a.type === "facebook" && valid) {
          const res = await feed({
            id: a.id,
            access_token: token,
          });
          console.log(res.data);
          a.feed = res.data;

          const ins = await getInsights({ page: a });
          a.insights = ins.data;

          const updatePic = httpsCallable(functions, "facebookPictureUpdate");
          await updatePic({
            workspace: state.users.userProfile.workspace,
            id: a.id,
            token: token,
            type: "facebook",
          });

          a.url = `https://www.facebook.com/${a.id}`;
        } else if (a.type === "instagram") {
          const res = await instaFeed({
            workspace: state.users.userProfile.workspace,
            id: a.id,
            connected_id: a.connected_id,
          });
          a.feed = res.data;
          const updatePic = httpsCallable(functions, "facebookPictureUpdate");
          await updatePic({
            workspace: state.users.userProfile.workspace,
            id: a.id,
            token: token,
            type: "instagram",
          });
        } else if (!valid) {
          a.access_token_valid = false;
        }

        accounts.push(a);
        console.log(a);
      }

      accounts.sort((a, b) => (a.name < b.name ? 1 : b.name < a.name ? -1 : 0));

      commit("setAccounts", accounts);
      commit("setLoading", { type: "accounts", val: false });
    },
  },
  modules: {
    posts,
    inbox,
    users,
    schedules,
    media,
    templates,
    chats,
    admin,
    workspace,
    notifications,
    topics,
    post,
    structures,
    template,
    medium,
    facebook,
  },
});
