import { doc, getDoc, collection, getDocs, orderBy } from "firebase/firestore";
import { db } from "@/firebase";

const content = {
  state: {
    categories: [],
    posts: [],
    schedule: [],
    events: [],
  },
  mutations: {
    setCategories(state, val) {
      state.categories = val;
    },
    addCategory(state, val) {
      state.categories.push(val);
    },
    setPosts(state, val) {
      state.posts = val;
    },
    setSchedule(state, val) {
      state.schedule = val;
    },
    setEvents(state, val) {
      state.events = val;
    },
    addPost(state, val) {
      state.posts.unshift(val);
    },
    updatePost(state, val) {
      const i = state.posts.findIndex((p) => p.uid === val.uid);
      state.posts.splice(i, 1, val);
    },
    deletePost(state, val) {
      const i = state.posts.findIndex((p) => p.uid === val);
      state.posts.splice(i, 1);
    },
  },
  actions: {
    async refreshPost({ commit, state, rootState }, uid) {
      const docSnap = await getDoc(
        doc(
          db,
          "workspaces",
          rootState.users.userProfile.workspace,
          "posts",
          uid
        )
      );
      let p = docSnap.data();
      p.variations = [];
      const messages = await getDocs(
        collection(
          db,
          "workspaces",
          rootState.users.userProfile.workspace,
          "posts",
          p.uid,
          "variations"
        )
      );
      messages.forEach((d) => {
        p.variations.push(d.data());
      });
      p.category = p.category
        ? state.categories.find((c) => c.uid === p.category)
        : null;
      commit("updatePost", p);
    },
    async fetchPosts({ commit, state, rootState }) {
      commit("setLoading", { type: "posts", val: true });

      let posts = [];

      const querySnapshot = await getDocs(
        collection(
          db,
          "workspaces",
          rootState.users.userProfile.workspace,
          "posts"
        ),
        orderBy("created", "asc")
      );
      querySnapshot.forEach(async (doc) => {
        let p = doc.data();
        p.uid = doc.id;
        p.variations = [];
        const messages = await getDocs(
          collection(
            db,
            "workspaces",
            rootState.users.userProfile.workspace,
            "posts",
            doc.id,
            "variations"
          )
        );
        messages.forEach((d) => {
          p.variations.push(d.data());
        });
        p.category = p.category
          ? state.categories.find((c) => c.uid === p.category)
          : null;

        posts.push(p);
      });

      // onSnapshot(q, (snapshot) => {
      //   snapshot.docChanges().forEach(async (change) => {
      //     if (change.type === "added") {
      //       let p = change.doc.data();
      //       p.uid = change.doc.id;
      //       p.variations = [];
      //       const messages = await getDocs(
      //         collection(
      //           db,
      //           "workspaces",
      //           rootState.users.userProfile.workspace,
      //           "posts",
      //           change.doc.id,
      //           "variations"
      //         )
      //       );
      //       messages.forEach((d) => {
      //         p.variations.push(d.data());
      //       });
      //       p.category = p.category
      //         ? state.categories.find((c) => c.uid === p.category)
      //         : null;

      //       commit("addPost", p);
      //     }

      //     if (change.type === "modified") {
      //       let p = change.doc.data();
      //       const messages = await getDocs(
      //         collection(
      //           db,
      //           "workspaces",
      //           rootState.users.userProfile.workspace,
      //           "posts",
      //           change.doc.id,
      //           "variations"
      //         )
      //       );
      //       messages.forEach((d) => {
      //         p.variations.push(d.data());
      //       });
      //       p.category = p.category
      //         ? state.categories.find((c) => c.uid === p.category)
      //         : null;
      //       commit("updatePost", p);
      //     }

      //     if (change.type === "removed") {
      //       commit("deletePost", change.doc.id);
      //     }

      //     // dispatch("getCalendarDays");
      //   });
      // });

      commit("setPosts", posts);
      commit("setLoading", { type: "posts", val: false });
    },
    async fetchCategories({ commit, rootState }) {
      commit("setLoading", { type: "categories", val: true });
      let categories = [];

      const querySnapshot = await getDocs(
        collection(
          db,
          "workspaces",
          rootState.users.userProfile.workspace,
          "categories"
        )
      );
      querySnapshot.forEach((doc) => {
        let cat = doc.data();
        cat.posts = [];
        categories.push(cat);
      });

      categories.sort((a, b) =>
        a.created_at < b.created_at ? -1 : b.created_at < a.created_at ? 1 : 0
      );

      commit("setCategories", categories);
      commit("setLoading", { type: "categories", val: false });
    },
    async fetchEvents({ commit, rootState }) {
      const dayOfMonth = (x, day, year, month) => {
        let y = 0;
        if (x === 2) {
          y = 7;
        } else if (x === 3) {
          y = 14;
        } else if (x === 4) {
          y = 21;
        } else if (x === 5) {
          y = 28;
        }
        // first day of the month
        let date = new Date(year, month, 1);
        let dayDifference = day - date.getDay();

        // get second friday of the month
        if (dayDifference < 0) {
          date.setDate(date.getDate() + (y + 7 + 1 * dayDifference));
        } else if (dayDifference >= 0) {
          date.setDate(date.getDate() + y + dayDifference);
        }

        return date;
      };

      let events = [];
      let today = new Date();

      const contentRef = doc(
        db,
        "workspaces",
        rootState.users.userProfile.workspace
      );
      const content = await getDoc(contentRef);
      let schedule = content.data().schedule;

      commit("setSchedule", schedule);

      for (let x in schedule) {
        // find first day match
        let start = schedule[x].created.toDate();

        let day = null;
        for (let i = 0; i < 7; i++) {
          if (start.getDay() === schedule[x].day) {
            day = start;
            break;
          }
          start.setDate(start.getDate() + 1);
        }

        if (schedule[x].type === "Months") {
          let y = dayOfMonth(
            schedule[x].month_day,
            schedule[x].day,
            day.getFullYear(),
            day.getMonth()
          );
          if (y < today) {
            y = dayOfMonth(
              schedule[x].month_day,
              schedule[x].day,
              day.getFullYear(),
              day.getMonth() + 1
            );
          }
          day = y;
        }

        // Set timeslot for first day
        let hours = schedule[x].timeslot.split(":")[0];
        let minutes = schedule[x].timeslot.split(":")[1];
        day.setHours(hours);
        day.setMinutes(minutes);

        // Create event for that day
        let end = new Date(day.valueOf());
        end.setHours(Number(hours) + 1);

        let event = {
          name: schedule[x].category.name,
          start: day,
          end: end,
          color: schedule[x].category.color,
          timed: true,
        };

        // add (e.g.) 2 weeks to date + create event

        if (schedule[x].type === "Weeks") {
          for (let i = 0; i < 9999; i++) {
            if (day.getYear() === today.getYear() + 5) {
              break;
            }

            const newEvent = structuredClone(event);
            events.push(newEvent);

            day.setDate(day.getDate() + 7 * schedule[x].frequency);
            end.setDate(end.getDate() + 7 * schedule[x].frequency);
          }
        } else if (schedule[x].type === "Months") {
          for (let i = 0; i < 999; i++) {
            if (day.getYear() === today.getYear() + 5) {
              break;
            }

            const newEvent = structuredClone(event);
            events.push(newEvent);

            let y = dayOfMonth(
              schedule[x].month_day,
              schedule[x].day,
              day.getFullYear(),
              day.getMonth() + 1 * schedule[x].frequency
            );

            day.setDate(y.getDate());
            day.setMonth(y.getMonth());
            day.setFullYear(y.getFullYear());
            day.setHours(Number(hours));
            day.setMinutes(minutes);
            end.setDate(y.getDate());
            end.setMonth(y.getMonth());
            end.setFullYear(y.getFullYear());
            end.setHours(Number(hours) + 1);
            end.setMinutes(minutes);
          }
        } else if (schedule[x].type === "Days") {
          for (let i = 0; i < 9999; i++) {
            if (day.getYear() === today.getYear() + 5) {
              break;
            }

            const newEvent = structuredClone(event);
            events.push(newEvent);

            day.setDate(day.getDate() + 1 * schedule[x].frequency);
            end.setDate(end.getDate() + 1 * schedule[x].frequency);
          }
        }
      }

      commit("setEvents", events);
    },
  },
  getters: {},
};

export default content;
