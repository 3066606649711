import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase";

export const post = {
  methods: {
    async askAnything(v) {
      this.$store.commit("setLoading", { type: "ai.anything", val: true });
      let timestamp = Date.now();
      this.variations[this.tab].timestamp = timestamp;
      const anything = httpsCallable(functions, "openaiAskAnything");
      const result = await anything({
        prompt: v,
        text: this.variations[this.tab].text,
      });
      this.$refs.askChat.clear();
      await this.useContent(result.data, timestamp);
      this.$store.commit("setLoading", { type: "ai.anything", val: false });
    },
    async completePost() {
      this.completing = true;
      this.$store.commit("setLoading", { type: "ai.complete", val: true });
      let timestamp = Date.now();
      this.variations[this.tab].timestamp = timestamp;
      this.$refs.askChat.clear();
      const complete = httpsCallable(functions, "openaiCompletePost");
      const result = await complete({
        text: this.variations[this.tab].text,
      });
      await this.useContent(result.data, timestamp);
      this.$store.commit("setLoading", { type: "ai.complete", val: false });
    },
    async generateVariation() {
      if (this.variations[this.tab].text === "") {
        this.$store.commit("setNotification", {
          show: true,
          text: "Input copy can not be empty.",
          type: "error",
        });
      } else {
        this.$store.commit("setLoading", { type: "ai.variation", val: true });
        let timestamp = Date.now();
        this.variations.push({
          text: "",
          type: "variation",
          timestamp: timestamp,
        });

        let copy = this.variations[this.tab].text;
        // this.tab++;
        const vm = this;
        setTimeout(() => {
          vm.tab = vm.variations.length - 1;
        }, 250);
        const variation = httpsCallable(functions, "openaiWriteVariation");
        const result = await variation({
          text: copy,
        });
        await this.useContent(result.data, timestamp);
        this.$store.commit("setLoading", { type: "ai.variation", val: false });
      }
    },
    async linkPost(lang, instructions, content, url) {
      this.$store.commit("setLoading", { type: "ai.generate", val: true });
      let timestamp = Date.now();
      this.variations[this.tab].timestamp = timestamp;
      const linkPost = httpsCallable(functions, "openaiWriteLinkPost");
      const result = await linkPost({
        instructions: instructions,
        lang: lang,
        content: content,
        url: url,
      });
      await this.useContent(result.data, timestamp);
      this.$store.commit("setLoading", { type: "ai.generate", val: false });
    },
    async spellingCheck() {
      this.$store.commit("setLoading", { type: "ai.spelling", val: true });
      let timestamp = Date.now();
      this.variations[this.tab].timestamp = timestamp;
      const spelling = httpsCallable(functions, "openaiCheckSpelling");
      const result = await spelling({
        text: this.variations[this.tab].text,
      });
      this.$refs.askChat.clear();
      await this.useContent(result.data, timestamp);
      this.$store.commit("setLoading", { type: "ai.spelling", val: false });
    },
    async translate(v) {
      this.$store.commit("setLoading", { type: "ai.translate", val: true });
      let timestamp = Date.now();
      this.variations[this.tab].timestamp = timestamp;
      const chat = httpsCallable(functions, "openaiTranslatePost");
      const result = await chat({
        language: v,
        text: this.variations[this.tab].text,
      });
      this.$refs.askChat.clear();
      await this.useContent(result.data, timestamp);
      this.$store.commit("setLoading", { type: "ai.translate", val: false });
    },

    async writePost() {
      this.$store.commit("setLoading", { type: "ai.generate", val: true });
      let timestamp = Date.now();
      this.variations[this.tab].timestamp = timestamp;
      const write = httpsCallable(functions, "openaiWritePost");
      const result = await write({
        instructions: this.instructions,
        topic: this.topic,
      });
      this.$refs.askChat.clear();
      await this.useContent(result.data, timestamp);
      this.$store.commit("setLoading", { type: "ai.generate", val: false });
    },
  },
  computed: {
    topic: {
      get() {
        return this.$store.state.post.topic;
      },
      set(value) {
        this.$store.commit("setPostTopic", value);
      },
    },
    expand: {
      get() {
        return this.$store.state.post.askChatDrawer;
      },
      set(value) {
        this.$store.commit("setPostAskChatDrawer", value);
      },
    },
    preview_dialog: {
      get() {
        return this.$store.state.post.preview_dialog;
      },
      set(value) {
        this.$store.commit("setPostPreviewDialog", value);
      },
    },
    structure: {
      get() {
        return this.$store.state.post.structure;
      },
      set(value) {
        this.$store.commit("setPostStructure", value);
      },
    },
    media: {
      get() {
        return this.$store.state.post.media;
      },
      set(value) {
        this.$store.commit("setPostMedia", value);
      },
    },
    instructions: {
      get() {
        return this.$store.state.post.instructions;
      },
      set(value) {
        this.$store.commit("setPostInstructions", value);
      },
    },
    category: {
      get() {
        return this.$store.state.post.category;
      },
      set(value) {
        this.$store.commit("setPostCategory", value);
      },
    },
    selected_accounts: {
      get() {
        return this.$store.state.post.selected_accounts;
      },
      set(value) {
        this.$store.commit("setPostSelectedAccounts", value);
      },
    },
    comment: {
      get() {
        return this.$store.state.post.comment;
      },
      set(value) {
        this.$store.commit("setPostComment", value);
      },
    },
    dialog: {
      get() {
        return this.$store.state.post.dialog;
      },
      set(value) {
        this.$store.commit("setPostDialog", value);
      },
    },
    show_comment: {
      get() {
        return this.$store.state.post.show_comment;
      },
      set(value) {
        this.$store.commit("setPostShowComment", value);
      },
    },
    variations: {
      get() {
        return this.$store.state.post.variations;
      },
      set(value) {
        this.$store.commit("setPostVariations", value);
      },
    },
    recurring: {
      get() {
        return this.$store.state.post.recurring;
      },
      set(value) {
        this.$store.commit("setPostRecurring", value);
      },
    },
    chat: {
      get() {
        return this.$store.state.post.chat;
      },
      set(value) {
        this.$store.commit("setPostChat", value);
      },
    },
    link_preview: {
      get() {
        return this.$store.state.post.link_preview;
      },
      set(value) {
        this.$store.commit("setPostLinkPreview", value);
      },
    },
    post: {
      get() {
        return this.$store.state.post.post;
      },
      set(value) {
        this.$store.commit("setPostUid", value);
      },
    },
    schedule_date: {
      get() {
        return this.$store.state.post.schedule_date;
      },
      set(value) {
        this.$store.commit("setPostScheduleDate", value);
      },
    },
    status: {
      get() {
        return this.$store.state.post.status;
      },
      set(value) {
        this.$store.commit("setPostStatus", value);
      },
    },
  },
};
