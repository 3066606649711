var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[(!_vm.$vuetify.breakpoint.smAndDown)?_c('div',[_c('div',{staticClass:"top mb-4"},[_c('AddTopic',{ref:"topic"}),_c('v-text-field',{staticStyle:{"border-radius":"8px","max-width":"200px"},style:({
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
            }),attrs:{"label":"Search topics ...","flat":"","solo":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-select',{staticClass:"mb-4",staticStyle:{"border-radius":"8px","max-width":"200px"},style:({
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
            }),attrs:{"label":"Tags","solo":"","hide-details":"","items":_vm.tags,"flat":"","dense":"","clearable":""},model:{value:(_vm.selectedTag),callback:function ($$v) {_vm.selectedTag=$$v},expression:"selectedTag"}})],1),_c('div',{staticClass:"topics"},[_c('v-card',{style:({
              borderBottom: '3px solid #272727',
              borderLeft: '1px solid #272727',
              borderTop: '1px solid #272727',
              borderRight: '3px solid #272727',
            }),attrs:{"max-width":"700","flat":""}},[_c('div',[_c('v-sheet',{staticClass:"d-flex justify-center align-center py-2",staticStyle:{"font-size":"10pt","font-weight":"600","border-top-right-radius":"7px","border-top-left-radius":"7px"},attrs:{"color":"accent"}},[_vm._v(" Topics ")]),_c('v-divider',{staticClass:"primary"})],1),_c('v-data-table',{staticStyle:{"max-width":"1200px"},attrs:{"headers":_vm.headers,"items":_vm.filtered,"mobile-breakpoint":"100","search":_vm.search,"hide-default-header":"","items-per-page":5},scopedSlots:_vm._u([{key:`item.tags`,fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"gap":"3px"}},_vm._l((item.tags),function(t,i){return _c('v-chip',{key:i,attrs:{"color":"info","small":""}},[_vm._v(_vm._s(t))])}),1)]}},{key:`item.actions`,fn:function({ item }){return [_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"px-4",attrs:{"link":""},on:{"click":function($event){return _vm.use(item)}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":"","color":"primary"}},[_vm._v("mdi-send")]),_c('v-list-item-title',[_vm._v("Use")])],1),_c('v-list-item',{staticClass:"px-4",attrs:{"link":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":"","color":"primary"}},[_vm._v("mdi-pencil")]),_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{staticClass:"px-4",attrs:{"link":""},on:{"click":function($event){return _vm.deleteItem(item.uid)}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":"","color":"primary"}},[_vm._v("mdi-delete")]),_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)]}}],null,true)})],1)],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }