<template>
  <div class="admin">
    <div
      style="padding: 0 44px; width: 100%"
      class="left smoke animate__animated animate__slideInDown"
    >
      <v-tabs background-color="transparent" v-model="tab">
        <v-tabs-slider
          class="mx-auto"
          style="width: 50%; border-radius: 5px 5px 0 0"
        ></v-tabs-slider>

        <v-tab
          style="text-transform: none; font-size: 12px"
          v-for="t in items"
          :key="t.route"
          @click="$router.push(t.route)"
          >{{ t.text }}</v-tab
        >
      </v-tabs>
      <v-divider></v-divider>
    </div>
    <transition name="slide-fade">
      <router-view class="desktop"></router-view
    ></transition>
  </div>
</template>

<script>
export default {
  watch: {
    $route: {
      handler: function (val) {
        if (val.name === "Profile") {
          this.tab = 0;
        } else if (val.name.includes("Workspace")) {
          this.tab = 1;
        } else if (val.name.includes("Accounts")) {
          this.tab = 2;
        } else if (val.name.includes("Users")) {
          this.tab = 3;
        } else if (val.name.includes("Stix AI")) {
          this.tab = 4;
        } else if (val.name.includes("Subscription")) {
          this.tab = 5;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      tab: 0,
      items: [
        {
          text: "Profile",
          route: "/settings/profile",
        },
        // {
        //   text: "Referrals",
        //   route: "/settings/referrals",
        // },
        {
          text: "Workspace",
          route: "/settings/workspace",
        },
        {
          text: "Accounts",
          route: "/settings/accounts",
        },
        {
          text: "Users",
          route: "/settings/users",
        },
        {
          text: "Stix AI",
          route: "/settings/stixai",
        },
        {
          text: "Subscription",
          route: "/settings/subscription",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.animate__animated.animate__slideInDown {
  --animate-duration: 0.5s;
}
.desktop {
  padding: 20px 44px 0 44px;
  max-width: 1200px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
  transition-delay: 0.2s;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
