<template>
  <div>
    <v-card color="transparent" flat>
      <div class="buttons" style="gap: 5px">
        <v-btn
          :outlined="mode != 1"
          color="primary"
          :class="mode === 1 && expand ? 'success--text' : 'primary--text'"
          small
          style="font-size: 11px"
          elevation="0"
          @click="select(1)"
          >Generate</v-btn
        >
        <v-btn
          :loading="$store.state.loading.ai.complete"
          color="primary"
          :outlined="!$store.state.loading.ai.complete"
          :class="
            $store.state.loading.ai.complete ? 'success--text' : 'primary--text'
          "
          small
          :disabled="empty"
          elevation="0"
          style="font-size: 11px"
          @click="$emit('complete')"
          >Complete
          <template v-slot:loader>
            <v-progress-circular
              size="14"
              width="1.5"
              indeterminate
              color="success"
            ></v-progress-circular>
          </template>
        </v-btn>
        <v-btn
          @click="select(2)"
          :outlined="mode != 2"
          color="primary"
          :class="mode === 2 && expand ? 'success--text' : 'primary--text'"
          small
          elevation="0"
          style="font-size: 11px"
          :loading="$store.state.loading.ai.translate"
          :disabled="empty"
          >Translate
          <template v-slot:loader>
            <v-progress-circular
              size="14"
              width="1.5"
              indeterminate
              color="success"
            ></v-progress-circular>
          </template>
        </v-btn>
        <v-btn
          :loading="$store.state.loading.ai.spelling"
          color="primary"
          :outlined="!$store.state.loading.ai.spelling"
          :class="checking ? 'success--text' : 'primary--text'"
          small
          elevation="0"
          style="font-size: 11px"
          @click="$emit('spelling')"
          :disabled="empty"
          >Spelling
          <template v-slot:loader>
            <v-progress-circular
              size="14"
              width="1.5"
              indeterminate
              color="success"
            ></v-progress-circular>
          </template>
        </v-btn>
        <v-btn
          small
          :loading="$store.state.loading.ai.anything"
          elevation="0"
          color="primary"
          :outlined="mode != 3"
          :class="mode === 3 && expand ? 'success--text' : 'primary--text'"
          style="font-size: 11px"
          @click="select(3)"
          >Anything..
          <template v-slot:loader>
            <v-progress-circular
              size="14"
              width="1.5"
              indeterminate
              color="success"
            ></v-progress-circular>
          </template>
        </v-btn>
      </div>
      <v-expand-transition>
        <div v-if="expand" class="mt-2">
          <v-card
            v-if="mode === 3"
            flat
            :style="{
              borderBottom: '3px solid #272727',
              borderLeft: '1px solid #272727',
              borderTop: '1px solid #272727',
              borderRight: '3px solid #272727',
            }"
            class="pa-4 translate"
          >
            <div style="gap: 8px; grid-column: 1 / span 2" class="">
              <p style="font-size: 12px; margin-bottom: 5px">Ask anything:</p>
              <v-textarea
                v-model="anything"
                dense
                clearable
                :disabled="$store.state.loading.ai.anything"
                flat
                @keypress="
                  (e) => {
                    if (e.key === 'Enter') {
                      $emit('anything', anything);
                    }
                  }
                "
                placeholder="Anything, E.g.: 'Translate to English', or 'remove emoji's.'"
                hide-details=""
                rows="2"
                solo
                background-color="smoke"
                style="border-radius: 8px"
              >
              </v-textarea>
            </div>
            <div class="d-flex mt-2">
              <v-spacer></v-spacer>
              <div
                :style="{
                  borderBottom: '3px solid #272727',
                  borderLeft: '1px solid #272727',
                  borderTop: '1px solid #272727',
                  borderRight: '3px solid #272727',
                  borderRadius: '8px',
                }"
              >
                <v-btn
                  color="button"
                  small
                  :disabled="!anything"
                  :loading="$store.state.loading.ai.anything"
                  height="30"
                  elevation="0"
                  @click="$emit('anything', anything)"
                  >Send
                  <v-icon small class="ml-1">mdi-send</v-icon>
                </v-btn>
              </div>
            </div>
          </v-card>
          <v-card
            v-if="mode === 2"
            flat
            :style="{
              borderBottom: '3px solid #272727',
              borderLeft: '1px solid #272727',
              borderTop: '1px solid #272727',
              borderRight: '3px solid #272727',
            }"
            class="pa-4 translate"
          >
            <div>
              <p style="font-size: 12px; margin-bottom: 5px">
                Translate my post to:
              </p>
              <v-autocomplete
                v-model="language"
                placeholder="Language"
                :items="languages"
                item-text="name"
                item-value="name"
                style="border-radius: 8px"
                dense
                flat
                :disabled="$store.state.loading.ai.translate"
                hide-details=""
                solo
                background-color="smoke"
              >
              </v-autocomplete>
            </div>
            <div class="d-flex mt-2">
              <v-spacer></v-spacer>
              <div
                :style="{
                  borderBottom: '3px solid #272727',
                  borderLeft: '1px solid #272727',
                  borderTop: '1px solid #272727',
                  borderRight: '3px solid #272727',
                  borderRadius: '8px',
                }"
              >
                <v-btn
                  @click="$emit('translate', language)"
                  color="button"
                  small
                  :disabled="!language"
                  height="30"
                  elevation="0"
                  :loading="$store.state.loading.ai.translate"
                  >Translate
                  <v-icon small class="ml-1">mdi-earth</v-icon>
                </v-btn>
              </div>
            </div>
          </v-card>
          <v-card
            flat
            :style="{
              borderBottom: '3px solid #272727',
              borderLeft: '1px solid #272727',
              borderTop: '1px solid #272727',
              borderRight: '3px solid #272727',
            }"
            class="pa-4 writepost"
            v-if="mode === 1"
          >
            <div class="mb-3">
              <p style="font-size: 12px; margin-bottom: 5px">
                Write a full post about:
              </p>

              <v-textarea
                v-model="topic"
                dense
                light
                clearable
                flat
                placeholder="Anything."
                hide-details=""
                solo
                rows="3"
                background-color="smoke"
                style="border-radius: 8px; font-size: 10pt"
                :disabled="$store.state.loading.ai.generate"
              >
                <template v-slot:append>
                  <v-btn
                    color="primary"
                    small
                    height="30"
                    elevation="0"
                    class="mr-2"
                    @click="$emit('topics')"
                    >Browse topics</v-btn
                  >
                </template>
              </v-textarea>
            </div>
            <div style="gap: 8px; grid-column: 1 / span 2" class="mb-3">
              <p style="font-size: 12px; margin-bottom: 5px">
                Predefined structure (optional):
              </p>

              <v-textarea
                v-model="structure"
                dense
                clearable
                light
                flat
                placeholder="Openening headline, then a teasing question.."
                hide-details=""
                rows="3"
                solo
                background-color="smoke"
                style="border-radius: 8px; font-size: 10pt"
                :disabled="$store.state.loading.ai.generate"
              >
                <template v-slot:append>
                  <v-btn
                    color="primary"
                    small
                    height="30"
                    elevation="0"
                    class="mr-2"
                    @click="$emit('structures')"
                    >Structures</v-btn
                  >
                </template></v-textarea
              >
            </div>
            <div style="gap: 8px; grid-column: 1 / span 2" class="">
              <p style="font-size: 12px; margin-bottom: 5px">
                Instructions (optional):
              </p>

              <v-textarea
                v-model="instructions"
                dense
                :disabled="$store.state.loading.ai.generate"
                clearable
                flat
                light
                placeholder="Use informative tone. Maximum 500 words. Etc."
                rows="2"
                solo
                hide-details=""
                background-color="smoke"
                style="border-radius: 8px; font-size: 10pt"
                @change="enterInstructions"
              >
              </v-textarea>
            </div>

            <div class="mt-3 d-flex justify-end">
              <div
                :style="{
                  borderBottom: '3px solid #272727',
                  borderLeft: '1px solid #272727',
                  borderTop: '1px solid #272727',
                  borderRight: '3px solid #272727',
                  borderRadius: '8px',
                }"
              >
                <v-btn
                  color="button"
                  small
                  light
                  :disabled="!topic || $store.state.loading.ai.generate"
                  elevation="0"
                  :loading="$store.state.loading.ai.generate"
                  @click="$emit('write')"
                  >Write post
                  <v-icon small class="ml-1">mdi-arrow-right</v-icon>
                </v-btn>
              </div>
            </div>
          </v-card>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import { languages } from "@/mixins/languages";
import { post } from "@/mixins/post";

export default {
  props: ["empty"],
  mixins: [post, languages],
  data() {
    return {
      anything: "",
      checking: false,
      menu: true,
      mode: 0,
      language: null,
    };
  },
  mounted() {
    if (
      this.$store.state.workspace.workspace &&
      this.$store.state.workspace.workspace.preferences.language
    ) {
      this.language =
        this.$store.state.workspace.workspace.preferences.language;
    }
  },
  methods: {
    clear() {
      this.mode = null;
      this.expand = false;
    },
    write() {
      this.select(1);
    },
    change(v) {
      if (!v) {
        this.expand = false;
      }
    },
    select(v) {
      if (v === this.mode) {
        this.expand = false;
        this.mode = null;
      } else {
        this.mode = v;
        this.expand = true;
      }
    },
    enterInstructions(k) {
      if (k.key === "Enter") {
        this.$emit("write");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.writepost {
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
  transition-delay: 0.2s;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
