<template>
  <div
    class="signup-container"
    :class="{
      mob: $vuetify.breakpoint.smAndDown,
      desk: !$vuetify.breakpoint.smAndDown,
    }"
  >
    <div class="left">
      <div class="logo">
        <v-img width="40" src="@/assets/logos/icon-dark.svg" contain></v-img>
        <b style="font-size: 18px">Stix</b>
      </div>

      <div
        class="sub-container form"
        :style="{
          width: $vuetify.breakpoint.smAndDown ? '80%' : '60%',
          paddingBottom: $vuetify.breakpoint.smAndDown ? '0' : '130px',
          marginTop: $vuetify.breakpoint.smAndDown ? '30px' : '130px',
        }"
      >
        <div>
          <h1
            :style="{
              fontSize: $vuetify.breakpoint.smAndDown ? '35px' : '40px',
            }"
          >
            Join the
            <span class="accent--text">{{ workspace_name }}</span> workspace.
          </h1>
          <div v-if="userProfile && !verification">
            <p class="mt-4">
              You have been invited to the {{ workspace_name }} workspace. Click
              'Join' to add this workspace to your account. You can switch
              between workspaces if you are linked to multiple workspaces.
            </p>
            <v-btn
              @click="joinWorkspace"
              color="button"
              elevation="0"
              height="50"
              class="mt-4 px-8"
              >Join <v-icon class="ml-2" small>mdi-arrow-right</v-icon></v-btn
            >
          </div>
          <div v-else-if="verification && verification.status === 200">
            <p class="mt-4">Congrats! You're now part of the workspace!</p>
            <div class="d-flex" style="gap: 10px">
              <v-btn
                color="primary"
                elevation="0"
                height="50"
                class="mt-4 px-8"
                @click="$router.push('/')"
              >
                Continue <v-icon small class="ml-2">mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </div>
          <div v-else-if="verification && verification.status === 400">
            <p class="mt-4">
              You are already linked to this workspace. You can just continue to
              manage socials.
            </p>
            <div class="d-flex" style="gap: 10px">
              <v-btn
                color="primary"
                elevation="0"
                height="50"
                class="mt-4 px-8"
                @click="continueLinked"
              >
                Continue <v-icon small class="ml-2">mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </div>
          <div v-else-if="verification && verification.status === 401">
            <p class="mt-4">
              The email {{ userProfile.email }} is not linked to the workspace.
              Please sign out and use the email address where this invite was
              sent to.
            </p>
            <div class="d-flex" style="gap: 10px">
              <v-btn
                color="primary"
                elevation="0"
                height="50"
                class="mt-4 px-8"
                @click="signOut"
              >
                <v-icon small class="mr-2">mdi-arrow-left</v-icon>Sign out
              </v-btn>
            </div>
          </div>
          <div v-else>
            <p class="mt-4">Sign in or create an account first.</p>
            <div class="d-flex" style="gap: 10px">
              <v-btn
                color="button"
                elevation="0"
                height="50"
                class="mt-4 px-8"
                @click="signIn"
                >Sign in
              </v-btn>
              <v-btn text elevation="0" height="50" class="mt-4 px-8"
                >Create account
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button" v-if="!$vuetify.breakpoint.smAndDown"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase";
import { getAuth, signOut } from "firebase/auth";
export default {
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      userProfile: (state) => state.users.userProfile,
    }),
  },
  mounted() {
    if (this.$route.query.workspace) {
      this.workspace_uid = this.$route.query.workspace;
    }

    if (this.$route.query.name) {
      this.workspace_name = this.$route.query.name;
    }
  },
  data() {
    return {
      workspace_name: "",
      workspace_uid: "",
      verification: null,
    };
  },
  methods: {
    continueLinked() {
      this.$router.push("/");
      this.$store.dispatch("changeWorkspace", this.workspace_uid);
    },
    async joinWorkspace() {
      const joinWorkspace = httpsCallable(functions, "workspaceJoin");
      const res = await joinWorkspace({
        uid: this.workspace_uid,
        email: this.userProfile.email,
        user_uid: this.userProfile.uid,
      });
      this.verification = res.data;
    },
    async signIn() {
      this.$router.replace({
        name: "Access",
        query: {
          workspace: this.$route.query.workspace,
          name: this.$route.query.name,
          mode: "signin",
        },
      });
    },
    async signOut() {
      // SIGN USER OUT AND REDIRECT TO /ACCESS WITH CURRENT QUERY
      const auth = getAuth();
      await signOut(auth);
      this.$router.replace({
        name: "Access",
        query: {
          workspace: this.$route.query.workspace,
          name: this.$route.query.name,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.signup-container {
  height: 100vh;
  background-color: white;

  &.desk {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .left {
    display: grid;
    grid-template-rows: 100px auto;

    .logo {
      display: flex;
      gap: 8px;
      width: 100px;
      align-items: center;
      padding-left: 30px;
    }

    .sub-container {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      max-width: 450px;

      &.form {
        justify-content: flex-start;
      }
    }
  }
}
</style>
