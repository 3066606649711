<template>
  <div class="magnet-wrapper">
    <div class="right-side">
      <v-card
        v-if="step != 5"
        flat
        width="800"
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }"
      >
        <v-sheet
          class="d-flex justify-center align-center py-2"
          color="accent"
          style="font-size: 10pt; font-weight: 600"
        >
          Generate Your Carousel PDF
        </v-sheet>
        <v-divider class="primary"></v-divider>
        <v-window v-model="step">
          <v-window-item :value="1" :key="1">
            <div class="pa-4">
              <h4>What topic?</h4>
              <v-text-field
                placeholder="Topic"
                solo
                flat
                hide-details
                background-color="smoke"
              ></v-text-field>
              <p><u>Ask StixAI to generate topics</u></p>
              <v-btn @click="step++">Next</v-btn>
            </div>
          </v-window-item>

          <v-window-item :value="2" :key="2">
            <div class="pa-4">
              <h4>Pick a template</h4>
              <p>You can customize colors later</p>
              <v-img :src="require('@/assets/carousel_templates.png')"></v-img>
            </div>
          </v-window-item>

          <v-window-item :value="3" :key="3">
            <div class="pa-4">
              <h4>Options</h4>
              <p>You can customize colors later</p>
              <v-select label="How many slides?"></v-select>
              <v-textarea label="Custom instructions"></v-textarea>
            </div>
          </v-window-item>

          <v-window-item :value="4" :key="4">
            <div class="pa-4">
              <h4>Generating your carousel..</h4>
              <v-progress-linear
                style="width: 70px"
                indeterminate
                rounded
              ></v-progress-linear>
            </div>
          </v-window-item>
        </v-window>

        <v-divider></v-divider>
        <div class="d-flex pa-2 justify-end">
          <v-btn @click="step++">Next</v-btn>
          <div class="d-flex" v-if="step === 4">
            <v-btn>Redo</v-btn>
            <v-btn @click="step = 1">Start fresh</v-btn>
            <v-btn>Share on LinkedIn</v-btn>
          </div>
        </div>
      </v-card>
      <div v-else style="width: 1230px">
        <div class="d-flex justify-space-between mb-4" style="width: 100%">
          <h1>Edit & Publish</h1>
          <div class="d-flex" style="gap: 10px">
            <v-btn>Preview</v-btn>
            <v-btn>Redo</v-btn>
            <v-btn>Start over</v-btn>
            <v-btn color="blue" class="white--text"
              ><v-icon>mdi-linkedin</v-icon>Share on LinkedIn</v-btn
            >
          </div>
        </div>
        <div class="slides">
          <v-card width="300" height="300" v-for="n in 6" :key="n" class="red">
            <v-btn elevation="2" fab><v-icon>mdi-pencil</v-icon></v-btn>
          </v-card>
          <div
            style="width: 300px; height: 300px"
            class="d-flex align-center justify-center"
          >
            <v-btn>+ slide</v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    selectedItem: 1,
    step: 5,
    items: [
      { text: "Real-Time", icon: "mdi-clock" },
      { text: "Audience", icon: "mdi-account" },
      { text: "Conversions", icon: "mdi-flag" },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.magnet-wrapper {
  margin: 0;
  padding: 0;
}

.right-side {
  width: calc(100vw);
  height: calc(100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: #fff 1px solid;

  .slides {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    overflow: auto;
    width: 80vw;
  }
}
</style>
