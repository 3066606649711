import {
  getAuth,
  signOut,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import router from "@/router";
import { db } from "@/firebase";
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
const auth = getAuth();

const users = {
  state: { userProfile: null },
  mutations: {
    setUserProfile(state, val) {
      state.userProfile = val;
    },
    setPreviewSize(state, val) {
      state.userProfile.preferences.preview_size = val;
    },
    setPreferences(state, val) {
      state.userProfile.preferences[val.type] = val.val;
    },
    setFeed(state, val) {
      state.userProfile.preferences.feed = val;
    },
    setRole(state, val) {
      state.userProfile.role = val;
    },
    setTitle(state, val) {
      state.userProfile.job_title = val;
    },
    setName(state, val) {
      state.userProfile.first_name = val.first_name;
      state.userProfile.last_name = val.last_name;
      state.userProfile.name = val.first_name + " " + val.last_name;
    },
  },
  actions: {
    async updateFeed({ state, commit }, val) {
      const ref = doc(db, "users", state.userProfile.uid);
      let feed = state.userProfile.preferences.feed;

      if (val.type === "add") {
        feed.push(val.value);
        await updateDoc(ref, {
          "preferences.feed": arrayUnion(val.value),
        });
      } else if (val.type === "remove") {
        let i = feed.findIndex((a) => a === val.value);

        await updateDoc(ref, {
          "preferences.feed": arrayRemove(val.value),
        });
        feed.splice(i, 1);
      }

      commit("setFeed", feed);
    },
    async updateRole({ commit, dispatch, state, rootState }, val) {
      if (val.invited) {
        let invites = rootState.workspace.workspace.invites;
        let i = invites.findIndex((inv) => (inv.email = val.email));
        invites[i].role = val.role;

        await setDoc(
          doc(db, "workspaces", state.userProfile.workspace),
          { invites: invites },
          { merge: true }
        );
        commit("setNotification", {
          show: true,
          text: `Role of ${val.email} changed to ${val.role}!`,
          type: "primary",
        });
      } else {
        const ref = doc(db, "users", val.uid);
        await updateDoc(ref, {
          role: val.role,
        });
        commit("setNotification", {
          show: true,
          text: `Role of ${val.first_name} changed to ${val.role}!`,
          type: "primary",
        });
      }

      dispatch("fetchWorkspace");
    },
    async updateTitle({ commit, state }, val) {
      const ref = doc(db, "users", state.userProfile.uid);
      await updateDoc(ref, {
        job_title: val,
      });

      commit("setTitle", val);

      commit("setNotification", {
        show: true,
        text: `Job title updated!`,
        type: "primary",
      });
    },
    async updateName({ commit, state }, val) {
      const ref = doc(db, "users", state.userProfile.uid);
      await updateDoc(ref, {
        first_name: val.first_name,
        last_name: val.last_name,
        name: val.first_name + " " + val.last_name,
      });

      commit("setName", val);

      commit("setNotification", {
        show: true,
        text: `Name updated!`,
        type: "primary",
      });
    },

    async updateNotifications({ state, commit }, val) {
      const ref = doc(db, "users", state.userProfile.uid);
      await updateDoc(ref, {
        [`preferences.notifications.${val.type}`]: val.val,
      });
      commit("setPreferences", val);
    },
    async setPreviewSize({ commit, state }, size) {
      const ref = doc(db, "users", state.userProfile.uid);

      await updateDoc(ref, {
        "preferences.preview_size": size,
      });
      commit("setPreviewSize", size);
    },
    async fetchUserProfile({ commit, dispatch }, user) {
      const now = new Date();
      commit("setLoading", { type: "general", val: true });
      commit("setLoading", { type: "userProfile", val: true });
      const docRef = doc(db, "users", user.uid);
      const userProfile = await getDoc(docRef);
      const fetchedUser = userProfile.data();
      commit("setUserProfile", fetchedUser);
      commit("setLoading", { type: "userProfile", val: false });

      if (user.emailVerified && fetchedUser.workspace) {
        dispatch("fetchAccounts");
        dispatch("fetchTemplates");

        let promises = [
          // await dispatch("fetchAdminArticles"),
          await dispatch("fetchCategories"),
          await dispatch("fetchPosts"),
          await dispatch("fetchEvents"),
          await dispatch("fetchWorkspace", null, { root: true }),
          await dispatch("fetchMedia"),
          await dispatch("getCalendarDays"),
          await dispatch("fetchNotifications"),
          await dispatch("fetchSchedules"),
          await dispatch("fetchChats"),
          await dispatch("fetchTopics"),
          await dispatch("fetchStructures"),
        ];

        await Promise.all(promises);

        if (fetchedUser.superadmin) {
          dispatch("fetchAdmin");
        }
      }

      await updateDoc(docRef, {
        last_login: serverTimestamp(),
      });
      if (user.email && !user.emailVerified) {
        router.push("/verify");
      } else if (
        !fetchedUser.workspace &&
        !router.currentRoute.query.workspace
      ) {
        router.push("/create-workspace");
      } else if (router.currentRoute.path === "/access") {
        if (fetchedUser.workspace && !router.currentRoute.query.workspace) {
          commit("setNotification", {
            show: true,
            text: "Login successful!",
            type: "primary",
            icon: "clapping-hands",
          });
          router.push("/");
        } else if (router.currentRoute.query.workspace) {
          router.replace({
            name: "Join",
            query: {
              workspace: router.currentRoute.query.workspace,
              name: router.currentRoute.query.name,
            },
          });
        }
      }
      const end = new Date();
      console.log(end - now);
      commit("setLoading", { type: "general", val: false });
    },
    async logout({ commit }) {
      console.log("logging out");
      const auth = getAuth();
      await signOut(auth);

      commit("setUserProfile", null);
      commit("setWorkspace", null);
      commit("setAccounts", []);
      commit("setTopics", []);
      commit("setMediaTags", []);
      commit("setSchedules", []);
      commit("setTopicTags", []);
      commit("setCalendar", []);
      commit("setPosts", []);
      commit("setCategories", []);
      commit("setSchedule", []);
      commit("setEvents", []);
      commit("setNotifications", []);
      commit("setMedia", []);

      router.replace("/access");
    },
    async signupWithEmail({ dispatch }, form) {
      console.log("Signing up new user");
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        form.email,
        form.password
      );
      let user = userCredential.user;
      console.log(user);

      // CREATING USER DOC

      await setDoc(doc(db, "users", user.uid), {
        first_name: form.first_name,
        last_name: form.last_name,
        name: `${form.first_name} ${form.last_name}`,
        email: form.email,
        created_at: serverTimestamp(),
        workspace: null,
        workspaces: [],
        last_login: serverTimestamp(),
        notifications: [],
        role: form.role,
        job_title: form.job_title,
        referrals: [],
        permissions: {
          publish: true,
          create: true,
        },
        avatar: null,
        preferences: {
          feed: [],
          notifications: {
            newsletter: form.newsletter,
            summary: true,
          },
          preview_size: "small",
        },
      });

      // CREATING WORKSPACE
      dispatch("createWorkspace", {
        keywords: form.keywords,
        invites: form.invites,
        first_name: form.first_name,
        uid: user.uid,
        main_use: form.main_use,
        company_size: form.company_size,
        timezone: form.timezone,
        country: form.country,
        type: "company",
        referral: form.referral,
        workspace_name: form.workspace_name,
      });

      // FETCH USER & REDIRECT
      dispatch("fetchUserProfile", user);
    },
  },
  getters: {},
};

export default users;
