<template>
  <div v-if="workspace">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="chat-card">
        <v-toolbar color="primary" flat tile>
          <v-btn class="ml-0" icon v-if="step > 1" dark @click="step--">
            <v-icon size="20">mdi-chevron-left</v-icon>
          </v-btn>
          <div v-else style="width: 48px"></div>

          <v-spacer></v-spacer>
          <h1 class="white--text">Ask Stix AI</h1>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="close">
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
          <v-progress-linear
            absolute
            bottom
            v-model="progress"
            color="success"
          ></v-progress-linear>
        </v-toolbar>

        <v-window v-model="step">
          <v-window-item :value="1">
            <div class="d-flex justify-center pt-10 align-center flex-column">
              <div
                class="d-flex"
                style="gap: 25px; max-width: 800px; width: 50vw"
              >
                <v-card
                  flat
                  width="50%"
                  class="py-4 px-6"
                  :style="{
                    borderBottom: '3px solid #272727',
                    borderLeft: '1px solid #272727',
                    borderTop: '1px solid #272727',
                    borderRight: '3px solid #272727',
                  }"
                >
                  <div class="d-flex flex-column justify-space-between">
                    <h1 class="mb-1" style="font-size: 20pt; font-weight: 700">
                      Topics
                    </h1>
                    <p style="font-size: 11pt">
                      Ask Stix AI to generate topic suggestions.
                    </p>

                    <v-btn
                      class="mt-4"
                      elevation="0"
                      color="button"
                      block
                      @click="step = 2"
                      >Start</v-btn
                    >
                  </div>
                </v-card>

                <v-card
                  width="50%"
                  :style="{
                    borderBottom: '3px solid #272727',
                    borderLeft: '1px solid #272727',
                    borderTop: '1px solid #272727',
                    borderRight: '3px solid #272727',
                  }"
                  class="py-4 px-6"
                  flat
                >
                  <div class="d-flex flex-column justify-space-between">
                    <h1 class="mb-1" style="font-size: 20pt; font-weight: 700">
                      Post
                    </h1>
                    <p style="font-size: 11pt">
                      Ask Stix AI to write a full post.
                    </p>

                    <v-btn
                      class="mt-4"
                      elevation="0"
                      color="button"
                      block
                      @click="step = 3"
                      >Start</v-btn
                    >
                  </div>
                </v-card>

                <!-- <v-card
                  max-width="30%"
                  :style="{
                    borderBottom: '3px solid #272727',
                    borderLeft: '1px solid #272727',
                    borderTop: '1px solid #272727',
                    borderRight: '3px solid #272727',
                  }"
                  flat
                  class="py-4 px-6"
                >
                  <div class="d-flex flex-column justify-space-between">
                    <h1 class="mb-1" style="font-size: 20pt; font-weight: 700">
                      Chat
                    </h1>
                    <p style="font-size: 11pt">
                      Chat with Stix AI for inspiration.
                    </p>

                    <v-btn
                      class="mt-4"
                      elevation="0"
                      color="button"
                      block
                      @click="$router.push('/chat')"
                      >Start</v-btn
                    >
                  </div>
                </v-card> -->
              </div>
              <div
                class="mt-8 d-flex flex-column align-center"
                style="width: 50vw; max-width: 800px; gap: 10px"
              >
                <h3 class="mb-4">Topics</h3>
                <v-sheet
                  :style="{
                    borderBottom: '3px solid #272727',
                    borderLeft: '1px solid #272727',
                    borderTop: '1px solid #272727',
                    borderRight: '3px solid #272727',
                  }"
                  v-for="t in topics"
                  :key="t.uid"
                  class="topic"
                  style="width: 100%"
                >
                  <p>{{ t.topic }}</p>
                  <div class="d-flex" style="gap: 5px">
                    <v-btn icon><v-icon small>mdi-heart-outline</v-icon></v-btn>
                    <v-btn @click="useTopic(t)" elevation="0" icon
                      ><v-icon small>mdi-send</v-icon></v-btn
                    >
                  </div>
                </v-sheet>
              </div>
            </div>
          </v-window-item>

          <v-window-item :value="2">
            <div class="d-flex justify-center pt-10">
              <div style="max-width: 800px; width: 50vw">
                <h1>Generate topic ideas</h1>
                <p style="font-size: 11pt">
                  Related to which keywords do you want ideas for topics?
                </p>
                <div>
                  <p style="font-size: 10px" class="mb-2 mt-4">
                    Relevant keywords
                    <v-tooltip bottom color="primary" max-width="300">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small class="ml-1" v-bind="attrs" v-on="on"
                          >mdi-information</v-icon
                        >
                      </template>
                      <div class="pa-2">
                        <span class="white--text"
                          >Keywords that are related to your company or
                          products. This is used as input for Stix AI to
                          generate relevant topic ideas.</span
                        >
                      </div>
                    </v-tooltip>
                  </p>
                  <div class="d-flex justify-space-between">
                    <v-text-field
                      label="Add keywords"
                      solo
                      v-model="keyword"
                      flat
                      @keypress="enter"
                      class="mb-4"
                      hide-details=""
                      dense
                      style="border-radius: 6px"
                      background-color="white"
                      hint="Press enter to add"
                    ></v-text-field>
                  </div>
                  <div class="keys d-flex">
                    <div v-for="(k, index) in workspace.keywords" :key="index">
                      <v-chip color="white" @click:close="remove(index)" close>
                        {{ k }}
                      </v-chip>
                    </div>
                  </div>
                </div>
                <v-btn
                  class="my-8"
                  elevation="0"
                  color="button"
                  block
                  @click="generateTopics"
                  :loading="generating"
                  >Generate topics</v-btn
                >

                <div v-if="new_topics.length > 0">
                  <h3 class="mb-3" style="font-size: 10pt">New topics:</h3>
                  <div class="topics">
                    <v-sheet
                      :style="{
                        borderBottom: '3px solid #272727',
                        borderLeft: '1px solid #272727',
                        borderTop: '1px solid #272727',
                        borderRight: '3px solid #272727',
                      }"
                      v-for="(t, index) in new_topics"
                      :key="t.topic"
                      class="topic"
                      style="position: relative"
                    >
                      <v-btn
                        color="button"
                        x-small
                        style="position: absolute; top: -10px; left: 5px"
                      >
                        <span>New</span></v-btn
                      >
                      <p>{{ t.topic }}</p>
                      <div class="d-flex" style="gap: 5px">
                        <v-btn icon @click="saveTopic(t, index)"
                          ><v-icon small>mdi-floppy</v-icon></v-btn
                        >
                        <v-btn icon><v-icon small>mdi-delete</v-icon></v-btn>
                      </div>
                    </v-sheet>
                  </div>
                </div>

                <h3 style="font-size: 10pt" class="mt-4 mb-3">Saved topics:</h3>
                <div class="topics">
                  <v-sheet
                    :style="{
                      borderBottom: '3px solid #272727',
                      borderLeft: '1px solid #272727',
                      borderTop: '1px solid #272727',
                      borderRight: '3px solid #272727',
                    }"
                    v-for="t in topics"
                    :key="t.uid"
                    class="topic"
                    style="position: relative"
                  >
                    <p>{{ t.topic }}</p>
                    <div class="d-flex" style="gap: 5px">
                      <v-btn icon><v-icon small>mdi-delete</v-icon></v-btn>
                    </div>
                  </v-sheet>
                </div>
              </div>
            </div>
          </v-window-item>

          <v-window-item :value="3">
            <div class="d-flex justify-center pt-10">
              <div style="max-width: 700px; width: 30vw">
                <h1>Generate full post</h1>
                <p style="font-size: 11pt">
                  Define your topic and choose the style and length to start
                  generating a full post.
                </p>
                <v-textarea
                  label="Add keywords"
                  solo
                  v-model="topic"
                  flat
                  @keypress="enter"
                  class="mb-4"
                  hide-details=""
                  dense
                  style="border-radius: 6px"
                  hint="Press enter to add"
                  full-width
                  rows="2"
                ></v-textarea>
                <div
                  style="
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 10px;
                  "
                >
                  <v-text-field
                    solo
                    flat
                    hide-details=""
                    dense
                    full-width
                    label="Max. words"
                    style="border-radius: 6px"
                    type="number"
                    v-model="words"
                  ></v-text-field>
                  <v-select
                    solo
                    flat
                    hide-details=""
                    dense
                    full-width
                    label="Style"
                    style="border-radius: 6px"
                    clearable
                    :items="[
                      'CTA generation',
                      'Educational',
                      'Fun',
                      'Holiday related',
                      'Inspiring',
                      'Interactive',
                      'Promotional',
                    ]"
                    v-model="style"
                  ></v-select>
                </div>
                <div
                  style="
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 10px;
                  "
                >
                  <v-btn
                    class="mt-4"
                    :color="post ? 'white' : 'button'"
                    elevation="0"
                    @click="generatePost"
                    :loading="generating"
                  >
                    Generate <span class="ml-1" v-if="post">again</span>
                  </v-btn>
                  <v-btn
                    @click="usePost"
                    class="mt-4"
                    color="button"
                    elevation="0"
                    :disabled="generating || !post"
                  >
                    Use post
                  </v-btn>
                </div>
                <v-card
                  :style="{
                    borderBottom: '3px solid #272727',
                    borderLeft: '1px solid #272727',
                    borderTop: '1px solid #272727',
                    borderRight: '3px solid #272727',
                  }"
                  class="mt-4 mb-10"
                >
                  <PreviewFacebook :message="post" />
                </v-card>
              </div>
            </div>
          </v-window-item>
        </v-window>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PreviewFacebook from "@/components/preview/PreviewFacebook.vue";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase";
export default {
  components: {
    PreviewFacebook,
  },
  data() {
    return {
      generating: false,
      headers: [
        {
          text: "Topics",
          align: "start",
          sortable: false,
          value: "topic",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      keyword: "",
      keywords: [],
      new_topics: [
        { topic: "Exploring the Benefits of Automating Social Media Tasks" },
        {
          topic:
            "How Social Media Automation Can Help Streamline Your Workflow",
        },
      ],
      post: "The top trend within marketing automation is The Future of Marketing is here. This is not a new statement, but it is a movement that is gaining traction that they believe in. The goal is to make sure that marketing is effective and efficient so that businesses can make more money. This is a movement that is happening all over the world and it is important for businesses to understand.",
      step: 1,
      style: null,
      topic: "top trend within marketing automation",
      words: 100,
    };
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      workspace: (state) => state.workspace.workspace,
      loading: (state) => state.loading,
      topics: (state) => state.topics.topics,
    }),
    progress() {
      return (this.step / 3) * 100;
    },
    dialog: {
      get() {
        return this.$store.state.openai;
      },
      set(value) {
        this.$store.commit("setOpenai", value);
      },
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.post = "";
      this.step = 1;
      this.topic = "";
    },
    enter(v) {
      if (v.key === "Enter" && this.keyword != "") {
        this.$store.dispatch("addTag", this.keyword);
        this.keyword = "";
      }
    },
    generateTopics() {
      this.generating = true;
      let prompt = `Create 2 topics to write a social media post about. The suggestions should be related to the following keywords: social media automation. Return the topics as an object in a JSON array: [{topic: 'topic suggestion'}]. Do not add any other characters, space or commentary.`;

      let data = {
        prompt: prompt,
        temperature: 0.6,
        max_tokens: 50,
      };
      const completeAI = httpsCallable(functions, "completeAI");
      completeAI(data).then((result) => {
        console.log(result.data.choices[0].text);
        this.new_topics = JSON.parse(result.data.choices[0].text);
        this.generating = false;
      });
    },
    generatePost() {
      this.generating = true;
      let prompt = `Write a social media post about "${this.topic}".`;
      if (this.words) {
        prompt = prompt.concat(` Use maximum of ${this.words} words.`);
      }
      if (this.style) {
        prompt = prompt.concat(` Write in the style "${this.style}".`);
      }
      let data = {
        prompt: prompt,
        temperature: this.post ? 0.6 : 0,
        max_tokens: 100,
      };
      const completeAI = httpsCallable(functions, "completeAI");
      completeAI(data).then((result) => {
        this.post = result.data.choices[0].text;
        this.generating = false;
      });
    },
    saveTopic(t, i) {
      this.$store.dispatch("saveTopic", t);
      this.new_topics.splice(i, 1);
    },
    useTopic(t) {
      this.step = 3;
      this.topic = t.topic;
    },
    remove(i) {
      this.$store.dispatch("removeTag", this.workspace.keywords[i]);
    },
    usePost() {
      this.$router.replace({
        name: "Post",
        props: {
          test: "test123",
        },
      });
      this.dialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
.subtitle {
  margin-bottom: 5px;
  font-size: 10pt;
  font-weight: 600;
  letter-spacing: 0.5pt;
}

.chat-card {
  background-color: #f0f0f0;
}

.keys {
  gap: 10px;
  flex-wrap: wrap;
}
.topics {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.topic {
  padding: 10px 25px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    padding: 0;
    margin: 0;
    font-size: 11pt;
  }
}
</style>
