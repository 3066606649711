<template>
  <v-list nav dark>
    <v-menu v-model="menu" :nudge-width="200" offset-x right open-on-hover>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item
          class="primary py-2 lighten-1"
          link
          v-bind="attrs"
          v-on="on"
        >
          <v-img height="30" contain src="@/assets/logo/logo-white.svg"></v-img>
        </v-list-item>
      </template>

      <v-card>
        <v-sheet
          class="d-flex justify-center align-center py-2"
          color="accent"
          style="font-size: 10pt; font-weight: 600"
        >
          Your workspaces
        </v-sheet>
        <v-divider class="primary"></v-divider>
        <v-list class="py-0">
          <v-list-item
            @click="select(w)"
            link
            v-for="w in workspaces"
            :key="w.uid"
            class="ma-2"
          >
            <v-icon v-if="w.uid === workspace.uid" class="mr-2" size="16"
              >mdi-arrow-right-thick</v-icon
            >
            <v-list-item-avatar v-if="w.brand.logo_dark.url">
              <img
                :src="w.brand.logo_dark.url"
                :alt="w.name"
                width="15"
                height="15"
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                style="font-size: 14px; font-weight: 500"
                class="d-flex align-center"
              >
                {{ w.about.name }}</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="deleteWorkspace(w.uid)"
                ><v-icon size="15">mdi-delete</v-icon></v-btn
              >
            </v-list-item-action>
          </v-list-item>

          <v-divider class=""></v-divider>
          <div class="px-2 pb-2">
            <v-btn
              @click="$router.push('/create-workspace')"
              class="mt-2"
              block
              text
              small
              ><v-icon small class="mr-1">mdi-plus</v-icon>Create new
              workspace</v-btn
            >
          </div>
        </v-list>

        <v-divider></v-divider>
      </v-card>
    </v-menu>
  </v-list>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      userProfile: (state) => state.users.userProfile,
      workspace: (state) => state.workspace.workspace,
      workspaces: (state) => state.workspace.workspaces,
    }),
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    async deleteWorkspace(uid) {
      let r = confirm("Are you sure?");
      if (r) {
        const res = await this.$store.dispatch("deleteWorkspace", uid);
        console.log(res);
      }
    },
    select(v) {
      if (v.uid != this.workspace.uid) {
        this.$store.dispatch("changeWorkspace", v.uid);
      }
    },
  },
};
</script>
