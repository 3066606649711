<template>
  <div>
    <div class="d-flex mb-6 align-center" style="gap: 10px">
      <div
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
          width: '59.42px',
          height: '32.5px',
          borderRadius: '10px',
        }"
      >
        <v-btn small color="button " @click="save" elevation="0">Save</v-btn>
      </div>

      <v-btn small text @click="$router.go(-1)">Close without saving</v-btn>
    </div>

    <h1 class="mb-4">Template</h1>
    <div class="editor" v-if="!loading">
      <v-card
        flat
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }"
      >
        <div>
          <v-sheet
            class="px-6 d-flex justify-center align-center py-2"
            color="accent"
            style="
              font-size: 10pt;
              font-weight: 600;
              border-top-right-radius: 7px;
              border-top-left-radius: 7px;
            "
          >
            Details
          </v-sheet>
          <v-divider class="primary"> </v-divider>
        </div>

        <div class="py-2">
          <div class="px-4 py-2">
            <v-text-field
              label="Title"
              style="border-radius: 6px; font-size: 10pt"
              flat
              class=""
              solo
              v-model="name"
              hide-details=""
            ></v-text-field>
          </div>

          <v-divider></v-divider>

          <div class="px-4 py-2">
            <v-select
              style="border-radius: 6px; font-size: 10pt"
              label="Tags"
              hide-details=""
              solo
              dense
              multiple
              v-model="tags"
              :items="[
                'Blog',
                'Did you know',
                'FAQ',
                'General',
                'Graphs',
                'Polls',
                'Quotes',
                'Tips',
              ]"
              flat
            ></v-select>
          </div>

          <v-divider></v-divider>

          <div class="px-4 py-4">
            <v-btn
              v-if="!background.type"
              elevation="0"
              block
              small
              @click="openMediaDrawer('template.background')"
              >Add background</v-btn
            >
            <v-card v-else outlined class="py-2 px-4 upload">
              <p
                class="mb-0"
                style="
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  font-size: 10pt;
                "
                v-if="background.type"
              >
                {{ background.title }}
              </p>

              <v-btn
                v-if="background.type"
                class="smoke"
                icon
                @click="openMediaDrawer('template.background')"
                ><v-icon size="20">mdi-image-refresh</v-icon></v-btn
              >
            </v-card>
          </div>

          <v-divider></v-divider>

          <div class="edit-item px-4 py-4">
            <div>
              <v-btn-toggle dense v-model="align_items">
                <v-btn value="flex-start" color="white">
                  <v-icon small>mdi-format-align-top</v-icon>
                </v-btn>
                <v-btn value="center" color="white">
                  <v-icon small>mdi-format-align-middle</v-icon>
                </v-btn>
                <v-btn value="flex-end" color="white">
                  <v-icon small>mdi-format-align-bottom</v-icon>
                </v-btn>
              </v-btn-toggle>
            </div>
            <div>
              <v-select
                v-model="aspect_ratio"
                solo
                flat
                return-object
                hide-details=""
                dense
                label="Aspect"
                :items="aspect_ratios"
              ></v-select>
            </div>
          </div>

          <v-divider></v-divider>
          <div class="pa-4">
            <div>
              <div class="sliders">
                <v-slider
                  dense
                  prepend-icon="mdi-align-vertical-distribute"
                  hide-details=""
                  v-model="padding.y"
                ></v-slider>
                <v-slider
                  dense
                  prepend-icon="mdi-align-horizontal-distribute"
                  hide-details=""
                  v-model="padding.x"
                ></v-slider>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }"
      >
        <div>
          <v-sheet
            class="px-6 d-flex justify-center align-center py-2"
            color="accent"
            style="
              font-size: 10pt;
              font-weight: 600;
              border-top-right-radius: 7px;
              border-top-left-radius: 7px;
            "
          >
            Blocks
          </v-sheet>
          <v-divider class="primary"> </v-divider>
        </div>
        <div class="blocks-list d-flex flex-column justify-space-between">
          <p
            v-if="blocks.length > 0"
            class="py-2 mb-0 text-center align-center"
            style="font-size: 9pt"
          >
            <v-icon color="primary" class="mr-1 mb-1" x-small
              >mdi-arrow-up-down</v-icon
            >
            <i>Drag blocks to order</i>
          </p>
          <div>
            <draggable
              class="blocks"
              v-model="blocks"
              @start="drag = true"
              @end="drag = false"
              handle=".handle"
              ghost-class="ghost"
            >
              <div v-for="(b, i) in blocks" :key="i">
                <v-menu
                  v-model="b.menu"
                  :close-on-content-click="false"
                  :nudge-width="200"
                  max-width="300"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      block
                      elevation="0"
                      tile
                      class="px-2 mx-0"
                    >
                      <div
                        class="d-flex justify-space-between align-center"
                        style="width: 100%"
                      >
                        <v-icon class="mr-3 handle" small>mdi-drag</v-icon>
                        <v-icon class="mr-1" small>{{
                          b.type === "image" ? "mdi-image" : "mdi-pencil"
                        }}</v-icon>
                        <p style="font-size: 12px" class="mb-0 mr-auto">
                          {{
                            b.type === "image"
                              ? b.media
                                ? b.media.title
                                : "Image block"
                              : b.text
                          }}
                        </p>
                        <v-icon small>mdi-chevron-right</v-icon>
                      </div>
                    </v-btn>
                  </template>

                  <v-card class="smoke">
                    <div v-if="b.type === 'image'" class="pa-4">
                      <div class="block">
                        <div v-if="!b.media">
                          <v-btn
                            color="button"
                            class="primary--text ml-2"
                            elevation="0"
                            small
                            @click="openMediaDrawer(`template.block.${i}`)"
                            >Add media</v-btn
                          >
                        </div>
                        <v-card v-else outlined class="py-2 px-4 upload">
                          <p
                            class="mb-0"
                            style="
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              font-size: 10pt;
                            "
                          >
                            {{ b.media.title }}
                          </p>

                          <v-btn
                            class="smoke"
                            icon
                            @click="openMediaDrawer(`template.block.${i}`)"
                            ><v-icon size="20">mdi-image-refresh</v-icon></v-btn
                          >
                        </v-card>
                      </div>

                      <div>
                        <v-select
                          v-model="b.position"
                          :items="['relative', 'absolute']"
                        ></v-select>
                        <v-slider
                          label="Size"
                          v-model="b.width"
                          hide-details=""
                        ></v-slider>
                      </div>
                      <div v-if="b.position === 'absolute'" class="d-flex">
                        <v-text-field
                          v-model="b.top"
                          type="number"
                        ></v-text-field>
                        <v-text-field
                          v-model="b.left"
                          type="number"
                        ></v-text-field>
                      </div>
                      <div>
                        <v-slider
                          label="Dropshadow"
                          v-model="b.dropshadow"
                          hide-details=""
                          min="0"
                          max="10"
                        ></v-slider>
                        <v-slider
                          label="Opacity"
                          v-model="b.opacity"
                          hide-details=""
                          min="0"
                          max="1"
                          step="0.1"
                        ></v-slider>
                      </div>
                      <div class="mt-4" v-if="b.media">
                        <p style="font-size: 10pt" class="mb-1">Placement:</p>
                        <v-btn-toggle v-model="b.align" dense>
                          <v-btn small value="flex-start" elevation="0">
                            <v-icon small>mdi-format-vertical-align-top</v-icon>
                          </v-btn>

                          <v-btn small value="center" elevation="0">
                            <v-icon small
                              >mdi-format-vertical-align-center</v-icon
                            >
                          </v-btn>

                          <v-btn small value="flex-end" elevation="0">
                            <v-icon small
                              >mdi-format-vertical-align-bottom</v-icon
                            >
                          </v-btn>
                        </v-btn-toggle>
                        <v-btn-toggle v-model="b.justify" dense>
                          <v-btn small value="flex-start" elevation="0">
                            <v-icon small
                              >mdi-format-horizontal-align-left</v-icon
                            >
                          </v-btn>

                          <v-btn small value="center" elevation="0">
                            <v-icon small
                              >mdi-format-horizontal-align-center</v-icon
                            >
                          </v-btn>

                          <v-btn small value="flex-end" elevation="0">
                            <v-icon small
                              >mdi-format-horizontal-align-right</v-icon
                            >
                          </v-btn>
                        </v-btn-toggle>
                      </div>
                    </div>

                    <div class="pa-4" v-if="b.type === 'text'">
                      <div>
                        <v-text-field
                          solo
                          flat
                          style="border-radius: 6px; font-size: 10pt"
                          hide-details=""
                          v-model="b.text"
                          full-width
                          dense
                        ></v-text-field>
                      </div>

                      <div class="pa-2 d-flex align-center" style="gap: 5px">
                        <p style="font-size: 10pt" class="mb-0 mt-1">
                          Font size:
                        </p>
                        <v-slider
                          class="mt-2"
                          v-model="b.font.size"
                          hide-details=""
                        ></v-slider>
                      </div>

                      <div class="pa-2 d-flex align-center" style="gap: 5px">
                        <p style="font-size: 10pt" class="mb-0 mt-1">
                          Font weight:
                        </p>
                        <v-slider
                          v-model="b.font.weight"
                          hide-details=""
                          :min="300"
                          :max="900"
                          :step="100"
                        ></v-slider>
                      </div>

                      <div class="pa-2 d-flex align-center" style="gap: 5px">
                        <p style="font-size: 10pt" class="mb-0 mt-1">
                          Line height:
                        </p>
                        <v-slider
                          class="mt-2"
                          v-model="b.line_height"
                          hide-details=""
                          min="10"
                          max="200"
                        ></v-slider>
                      </div>

                      <div class="pa-2">
                        <p class="mb-1" style="font-size: 10pt">Styling:</p>
                        <v-btn-toggle v-model="b.text_align" dense>
                          <v-btn small elevation="0" value="left">
                            <v-icon small>mdi-format-align-left</v-icon>
                          </v-btn>

                          <v-btn small elevation="0" value="center">
                            <v-icon small>mdi-format-align-center</v-icon>
                          </v-btn>

                          <v-btn small elevation="0" value="right">
                            <v-icon small>mdi-format-align-right</v-icon>
                          </v-btn>

                          <v-btn small elevation="0" value="justify">
                            <v-icon small>mdi-format-align-justify</v-icon>
                          </v-btn>
                        </v-btn-toggle>
                        <v-btn-toggle
                          v-model="b.format"
                          dense
                          background-color="smoke"
                          multiple
                        >
                          <v-btn small value="italic">
                            <v-icon small>mdi-format-italic</v-icon>
                          </v-btn>
                          <v-btn small value="underline">
                            <v-icon small>mdi-format-underline</v-icon>
                          </v-btn>
                          <ColorPicker :index="i" @color="pickColor" />
                        </v-btn-toggle>
                      </div>
                    </div>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-btn small icon @click.stop="blocks.splice(i, 1)">
                        <v-icon small>mdi-delete</v-icon></v-btn
                      >
                      <!-- <v-spacer></v-spacer>

                      <v-btn small text @click="b.menu = false"> Cancel </v-btn>
                      <v-btn small color="primary" text @click="b.menu = false">
                        Apply
                      </v-btn> -->
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </div>
            </draggable>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-divider></v-divider>
            <div class="edit-item pa-4">
              <v-btn block elevation="0" small @click="addBlock('text')">
                Add Text
              </v-btn>
              <v-btn block elevation="0" small @click="addBlock('image')">
                Add Image
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>

      <div>
        <v-card
          flat
          :style="{
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
          }"
          style="max-width: 500px"
        >
          <div>
            <v-sheet
              class="px-6 d-flex justify-center align-center py-2"
              color="accent"
              style="
                font-size: 10pt;
                font-weight: 600;
                border-top-right-radius: 7px;
                border-top-left-radius: 7px;
              "
            >
              Preview
            </v-sheet>
            <v-divider class="primary"> </v-divider>
          </div>

          <div id="my-node" class="gradient">
            <v-img
              v-if="background"
              :src="background.src"
              alt=""
              :aspect-ratio="
                aspect_ratio.value ? aspect_ratio.value : undefined
              "
              contain
            >
              <div
                :style="{
                  padding: `${padding.y}px ${padding.x}px`,
                  'justify-content': align_items,
                  'align-items': justify_content,
                }"
                style="
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  border: 1px solid black;
                "
              >
                <div
                  v-for="(b, index) in blocks"
                  :key="index"
                  style="width: 100%"
                >
                  <div
                    v-if="b.type === 'image'"
                    class="d-flex"
                    :style="{
                      'justify-content': b.justify,
                      'align-items': b.align,
                    }"
                    style="width: 100%; height: 100%"
                  >
                    <img
                      :style="{
                        width: b.width + '%',
                        opacity: b.opacity,
                        position: b.position,
                        top: b.top + 'px',
                        left: b.left + 'px',
                        // zIndex: index * -1,
                      }"
                      :src="b.media && b.media.src ? b.media.src : ''"
                      alt=""
                      :class="`elevation-${b.dropshadow}`"
                    />
                  </div>
                  <p
                    v-if="b.type === 'text'"
                    :style="{
                      zIndex: index * -1,
                      color: b.color,
                      'line-height': b.line_height + '%',
                      'font-size': b.font.size + 'px',
                      'font-weight': b.font.weight,
                      'font-style':
                        b.format && b.format.includes('italic') ? 'italic' : '',
                      'margin-bottom': b.margin.bottom + 'px',
                      'margin-top': b.margin.top + 'px',
                      'margin-left': b.margin.left + 'px',
                      'margin-right': b.margin.right + 'px',
                      'text-align': b.text_align,
                      'text-decoration':
                        b.format && b.format.includes('underline')
                          ? 'underline'
                          : '',
                    }"
                  >
                    {{ b.text }}
                  </p>
                </div>
              </div>
            </v-img>
          </div>
        </v-card>
      </div>
    </div>
    <div v-else-if="loading">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { template } from "@/mixins/template";
import draggable from "vuedraggable";
import ColorPicker from "@/components/ColorPicker.vue";
import {
  addDoc,
  collection,
  doc,
  setDoc,
  arrayUnion,
  updateDoc,
} from "firebase/firestore";
import { db } from "@/firebase";
import * as htmlToImage from "html-to-image";

export default {
  mixins: [template],
  components: {
    draggable,
    ColorPicker,
  },
  mounted() {
    if (this.$route.params.id && this.$route.params.id != "new") {
      this.$store.dispatch("setTemplate", this.$route.params.id);
    }
  },
  data() {
    return {
      aspect_ratios: [
        {
          text: "Original",
          value: null,
        },
        {
          text: "16:9",
          value: 1.777,
        },
        {
          text: "1:1",
          value: 1,
        },
      ],

      changes: true,
      drag: false,
      media_drawer: false,
      media_drawer_block: null,
    };
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
    }),
    template() {
      return {
        uid: this.uid,
        justify_content: this.justify_content,
        background: this.background,
        tags: this.tags,
        align_items: this.align_items,
        blocks: this.blocks,
        padding: this.padding,
        name: this.name,
        aspect_ratio: this.aspect_ratio,
        thumbnail: this.thumbnail,
        created_by: this.created_by,
        created_by_email: this.created_by_email,
        created_at: this.created_at,
      };
    },
  },
  methods: {
    addBlock(type) {
      if (type === "image") {
        this.blocks.push({
          media: null,
          width: 20,
          type: "image",
          margin: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
          top: 0,
          left: 0,
          position: "relative",
          align: "center",
          justify: "center",
          title: "Image",
          opacity: 1,
          dropshadow: 0,
        });
      } else if (type === "text") {
        this.blocks.push({
          text: "Lorem ipsum...",
          type: "text",
          color: "#c4c4c4",
          line_height: 100,
          font: {
            size: 30,
            weight: 500,
          },
          margin: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
        });
      }
    },
    change(v) {
      if (v === false) {
        console.log("Closing Editor");
        this.$store.commit("clearTemplate");
      }
    },
    pickColor(i) {
      this.blocks[i.index].color = i.color;
    },
    async save() {
      if (this.uid) {
        this.updateTemplate();
      } else {
        this.thumbnail = await htmlToImage.toJpeg(
          document.getElementById("my-node"),
          { quality: 1 }
        );
        this.created_at = Math.floor(Date.now() / 1000);
        this.created_by = this.userProfile.uid;
        this.created_by_email = this.userProfile.email;

        const docRef = await addDoc(
          collection(db, "workspaces", this.userProfile.workspace, "templates"),
          this.template
        );
        console.log("New Template written with ID: ", docRef.id);

        await updateDoc(
          doc(
            db,
            "workspaces",
            this.userProfile.workspace,
            "templates",
            docRef.id
          ),
          {
            uid: docRef.id,
          }
        );

        this.$store.commit("setNotification", {
          show: true,
          text: "Template saved!",
          type: "primary",
          icon: "",
        });
        this.$store.dispatch("fetchTemplates");
        this.$router.go(-1);
      }
    },
    openMediaDrawer(type) {
      console.log(type);
      this.$store.commit("setMediumType", type);
    },
    async updateTemplate() {
      const ref = doc(
        db,
        "workspaces",
        this.userProfile.workspace,
        "templates",
        this.uid
      );
      this.thumbnail = await htmlToImage.toJpeg(
        document.getElementById("my-node"),
        { quality: 1 }
      );
      setDoc(ref, this.template, { merge: true });

      this.blocks.map(async (b) => {
        if (b.type === "image" && b.media.uid) {
          await updateDoc(
            doc(
              db,
              "workspaces",
              this.userProfile.workspace,
              "media",
              b.media.uid
            ),
            {
              templates: arrayUnion(this.uid),
            }
          );
        }
      });

      this.$store.commit("setNotification", {
        show: true,
        text: "Template saved!",
        type: "primary",
        icon: "",
      });
      this.$store.commit("clearTemplate");

      this.$store.dispatch("fetchTemplates");
      this.$router.go(-1);
      console.log("template updated");
    },
  },
};
</script>

<style lang="scss" scoped>
.editor {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  gap: 10px;
  max-width: 1200px;
}

.edit-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.blocks {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.blocks-list {
  height: calc(100% - 36px);
}
.upload {
  display: grid;
  grid-template-columns: auto 40px;
  align-items: center;
}

.gradient {
  width: 100%;
  background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  );
}
</style>
