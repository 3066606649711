import { render, staticRenderFns } from "./SchedulePostDialog.vue?vue&type=template&id=54f132b7&scoped=true&"
import script from "./SchedulePostDialog.vue?vue&type=script&lang=js&"
export * from "./SchedulePostDialog.vue?vue&type=script&lang=js&"
import style0 from "./SchedulePostDialog.vue?vue&type=style&index=0&id=54f132b7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54f132b7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardActions,VDatePicker,VDialog,VDivider,VSpacer,VTextField})
