<template>
  <div>
    <v-card
      flat
      :style="{
        borderBottom: '3px solid #272727',
        borderLeft: '1px solid #272727',
        borderTop: '1px solid #272727',
        borderRight: '3px solid #272727',
      }"
      class="pb-4"
      max-width="800"
    >
      <div>
        <v-sheet
          class="d-flex justify-center align-center py-2"
          color="accent"
          style="
            font-size: 10pt;
            font-weight: 600;
            border-top-right-radius: 7px;
            border-top-left-radius: 7px;
          "
        >
          Accounts
        </v-sheet>
        <v-divider class="primary"></v-divider>
      </div>
      <div class="d-flex mb-2 px-6 pt-4">
        <div>
          <v-icon size="18" class="mr-2">mdi-plus</v-icon>
          <v-btn icon @click="Facebook">
            <v-icon color="facebook">mdi-facebook</v-icon>
          </v-btn>
          <v-btn @click="instagramAccess" icon class="mx-1">
            <v-icon color="instagram">mdi-instagram</v-icon>
          </v-btn>
          <v-btn icon class="mx-1">
            <v-icon color="linkedin">mdi-linkedin</v-icon>
          </v-btn>
        </div>
      </div>
      <v-divider></v-divider>

      <v-data-table
        :headers="headers"
        :items="accounts"
        mobile-breakpoint="100"
        sort-by="email"
        :hide-default-footer="accounts.length < 11"
        no-data-text="Click on one of the buttons above to link your accounts"
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-avatar
            class="mr-3"
            style="
              border-left: 1px solid black;
              border-top: 1px solid black;
              border-right: 3px solid black;
              border-bottom: 3px solid black;
            "
          >
            <img :src="item.profile_picture" alt="" />
          </v-avatar>
          {{ item.name }}
          <v-btn
            class="ml-3"
            color="error"
            v-if="!item.access_token_valid"
            small
            text
            >Reconnect</v-btn
          >
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="deleteAccount(item.id)">
            <v-icon color="primary" small> mdi-delete </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <v-icon :color="item.type">mdi-{{ item.type }}</v-icon>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-icon color="green" v-if="item.access_token_valid"
            >mdi-check-circle</v-icon
          >
          <v-icon v-else color="error">mdi-close-circle</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "@/firebase";

export default {
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      workspace: (state) => state.workspace.workspace,
      loading: (state) => state.loading,
      accounts: (state) => state.accounts,
    }),
  },
  data() {
    return {
      edit: false,
      headers: [
        {
          text: "Account",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Platform",
          align: "center",
          sortable: false,
          value: "type",
        },
        {
          text: "Connection",
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: "Actions",
          align: "end",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  methods: {
    async deleteAccount(id) {
      console.log(id);
      await deleteDoc(
        doc(db, "workspaces", this.workspace.uid, "accounts", id)
      );
      this.$store.dispatch("fetchAccounts");
    },
    instagramAccess() {
      const vm = this;
      window.FB.login(
        function (response) {
          if (response.authResponse) {
            console.log(response);

            // update facebook access profiles
            const updateFacebookAccess = httpsCallable(
              functions,
              "facebookUpdateAccessToken"
            );
            updateFacebookAccess({
              id: response.authResponse.userID,
              access_token: response.authResponse.accessToken,
            });
            vm.$store.dispatch("fetchAccounts");
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        },
        {
          scope: " instagram_basic, instagram_content_publish",
          enable_profile_selector: true,
        }
      );
    },
    Facebook() {
      const vm = this;
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          window.FB.logout(function (response) {
            console.log(response);
            vm.FacebookAccess();
          });
        } else {
          console.log(response);
          vm.FacebookAccess();
        }
      });
    },
    FacebookAccess() {
      const vm = this;
      window.FB.login(
        function (response) {
          if (response.authResponse) {
            console.log(response);
            // update facebook access profiles
            const updateFacebookAccess = httpsCallable(
              functions,
              "facebookUpdateAccessToken"
            );
            updateFacebookAccess({
              id: response.authResponse.userID,
              access_token: response.authResponse.accessToken,
            });
            console.log("updated");
            vm.$store.dispatch("fetchAccounts");
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        },
        {
          scope:
            "public_profile,email,pages_show_list,pages_manage_posts, pages_manage_engagement,pages_manage_metadata, pages_messaging, pages_read_engagement, read_insights",
          enable_profile_selector: true,
        }
      );
    },
    async status() {
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          console.log(response.authResponse);
        }
      });
    },
    logout() {
      window.FB.logout(function (response) {
        console.log(response);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
