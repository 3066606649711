<template>
  <div>
    <div class="backlog" style="position: sticky; top: 0; z-index: 9">
      <div v-for="state in states" :key="state">
        <div class="mb-4">
          <v-chip color="primary" label
            ><span
              style="font-size: 9pt"
              class="smoke--text font-weight-bold"
              >{{ capitalize(state) }}</span
            >
            <v-icon x-small color="smoke" class="ml-1">mdi-arrow-down</v-icon>
          </v-chip>
          <v-btn
            v-if="state === 'done'"
            icon
            small
            @click="$store.dispatch('clearDoneState')"
            ><v-icon size="20">mdi-close</v-icon></v-btn
          >
        </div>
      </div>
    </div>
    <div class="backlog" v-if="!loading.admin.backlog">
      <div v-for="state in states" :key="state">
        <draggable
          :value="backlog[stateCamel(state)]"
          @change="updateList($event, stateCamel(state))"
          group="backlog"
          :disabled="publicView"
        >
          <v-card
            style="cursor: pointer"
            @click="$emit('open', element)"
            flat
            class="card"
            v-for="element in backlog[stateCamel(state)]"
            :key="element.uid"
          >
            <!-- <v-simple-checkbox :ripple="false"></v-simple-checkbox> -->
            <div>
              <p class="ma-0 mb-2" style="font-weight: 600; font-size: 10pt">
                {{ element.title }}
              </p>
              <p style="font-size: 10pt">{{ element.description }}</p>
              <v-chip small label
                ><v-icon small class="mr-1">mdi-bug-outline</v-icon
                >{{ element.type }}</v-chip
              >
            </div>
          </v-card>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapState } from "vuex";

export default {
  props: {
    publicView: {
      default: true,
    },
  },
  components: {
    draggable,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      backlog: (state) => state.admin.backlog,
    }),
  },
  data() {
    return {
      states: [
        "requested",
        "backlog",
        "in development",
        "ready for review",
        "done",
      ],
    };
  },
  methods: {
    capitalize(string) {
      let firstCharacter = string.charAt(0);
      firstCharacter = firstCharacter.toUpperCase();
      let capitalizedString = firstCharacter + string.slice(1);
      return capitalizedString;
    },
    stateCamel(state) {
      let newstate = state.replaceAll(" ", "_");
      return newstate;
    },
    updateList(v, state) {
      this.$store.dispatch("updateBacklog", {
        event: v,
        state: state,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.backlog {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
}
.card {
  padding: 20px 20px;
  margin-bottom: 10px;
  border-bottom: 3px solid #272727;
  border-left: 1px solid #272727;
  border-top: 1px solid #272727;
  border-right: 3px solid #272727;
}
</style>
