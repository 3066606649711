import { db } from "@/firebase";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  addDoc,
} from "firebase/firestore";

const structures = {
  state: {
    structures: [],
  },
  mutations: {
    addStructure(state, val) {
      state.structures.unshift(val);
    },
    setStructures(state, val) {
      state.structures = val;
    },
    updateStructure(state, val) {
      let i = state.structures.findIndex((t) => t.uid === val.uid);
      state.structures[i].structure = val.structure;
      state.structures[i].title = val.title;
    },
    removeStructure(state, val) {
      let i = state.structures.findIndex((t) => t.uid === val);
      state.structures.splice(i, 1);
    },
  },
  actions: {
    async fetchStructures({ commit, rootState }) {
      commit("setLoading", { type: "structures", val: true });
      let structures = [];

      const querySnapshot = await getDocs(
        collection(
          db,
          "workspaces",
          rootState.workspace.workspace.uid,
          "structures"
        )
      );
      querySnapshot.forEach((doc) => {
        structures.push(doc.data());
      });

      structures.sort((a, b) =>
        a.created_at < b.created_at ? 1 : b.created_at < a.created_at ? -1 : 0
      );

      commit("setStructures", structures);
      commit("setLoading", { type: "structures", val: false });
    },
    async saveStructure({ commit, rootState }, val) {
      commit("setLoading", { type: "structures", val: true });
      if (val.uid) {
        await updateDoc(
          doc(
            db,
            "workspaces",
            rootState.users.userProfile.workspace,
            "structures",
            val.uid
          ),
          {
            structure: val.text,
            title: val.title,
          }
        );
        commit("updateStructure", val);
        commit("setNotification", {
          show: true,
          text: "Structure updated!",
          type: "primary",
          icon: "",
        });
      } else {
        let new_structure = {
          structure: val.text,
          created_by_email: rootState.users.userProfile.email,
          created_by: rootState.users.userProfile.uid,
          created_at: Math.floor(Date.now() / 1000),
          title: val.title,
          favorites: [],
        };
        console.log(new_structure);
        const docRef = await addDoc(
          collection(
            db,
            "workspaces",
            rootState.users.userProfile.workspace,
            "structures"
          ),
          new_structure
        );
        await updateDoc(
          doc(
            db,
            "workspaces",
            rootState.users.userProfile.workspace,
            "structures",
            docRef.id
          ),
          {
            uid: docRef.id,
          }
        );
        new_structure.uid = docRef.id;
        commit("addStructure", new_structure);
        commit("setNotification", {
          show: true,
          text: "Structure added!",
          type: "primary",
          icon: "",
        });
      }

      commit("setLoading", { type: "structures", val: false });
    },
    async deleteStructure({ commit, rootState }, val) {
      commit("setLoading", { type: "structures", val: true });
      await deleteDoc(
        doc(
          db,
          "workspaces",
          rootState.users.userProfile.workspace,
          "structures",
          val
        )
      );
      commit("setNotification", {
        show: true,
        text: `Structure deleted!`,
        type: "primary",
        icon: "",
      });

      commit("removeStructure", val);
      commit("setLoading", { type: "structures", val: false });
    },
  },
  getters: {},
};

export default structures;
