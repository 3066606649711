<template>
  <div>
    <v-select
      ref="selectCategory"
      label="Category"
      :value="category"
      no-data-text="No categories setup"
      item-color="button darken-2"
      solo
      flat
      :clearable="clearable"
      dense
      style="border-radius: 8px; font-size: 10pt"
      item-text="name"
      @input="sync"
      return-object
      :items="categories"
      hide-details=""
      :style="{
        borderBottom: '3px solid #272727',
        borderLeft: '1px solid #272727',
        borderTop: '1px solid #272727',
        borderRight: '3px solid #272727',
      }"
      :menu-props="{ offsetY: true }"
    >
      <template v-slot:item="{ item }">
        <v-icon size="15" class="mr-2" :color="item.color">mdi-circle</v-icon
        >{{ item.name }}
      </template>
      <template v-slot:selection="{ item }">
        <v-icon size="15" class="mr-2" :color="item.color">mdi-circle</v-icon
        >{{ item.name }}
      </template>
      <template v-slot:append-item>
        <v-divider></v-divider>
        <div class="d-flex mt-2 px-2 justify-center align-center">
          <NewCategory @new="pickNew" type="list" />
        </div>
      </template>
    </v-select>
  </div>
</template>
<script>
import { mapState } from "vuex";
import NewCategory from "@/components/NewCategory.vue";

export default {
  props: {
    category: {
      type: Object,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    NewCategory,
  },
  computed: {
    ...mapState({
      categories: (state) => state.posts.categories,
    }),
  },
  methods: {
    sync(v) {
      this.$emit("update:category", v);
      this.$emit("input", v);
    },
    pickNew(uid) {
      let v = this.categories.find((i) => i.uid === uid);
      this.$emit("update:category", v);
      this.$emit("input", v);
    },
  },
};
</script>
