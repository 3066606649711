import axios from "axios";

const facebook = {
  state: {},
  mutations: {},
  actions: {
    deletePostFacebook({ commit, dispatch }, val) {
      return new Promise((resolve, reject) => {
        (async () => {
          try {
            console.log("Deleting post from Facebook");
            commit("setLoading", { type: "general", val: true });

            let id = val.id;
            let token = val.token;

            let endpoint = `https://graph.facebook.com/v22.0/${id}?access_token=${token}`;
            const res = await axios.delete(endpoint);
            console.log(res.data);
            dispatch("fetchAccounts");
            commit("setLoading", { type: "general", val: false });

            commit("setNotification", {
              show: true,
              text: "Post deleted!",
              type: "primary",
              icon: "",
            });
            return resolve(200);
          } catch (err) {
            commit("setLoading", { type: "general", val: false });

            commit("setNotification", {
              show: true,
              text: "Deletion not possible",
              type: "error",
              icon: "",
            });

            return reject();
          }
        })();
      });
    },
  },
  getters: {},
};

export default facebook;
