import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@fortawesome/fontawesome-free/css/all.css"; // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: "#272727",
        secondary: "#67d6fa",
        accent: "#cfb8ff",
        error: "#fa7a68",
        info: "#ffd978",
        success: "#c9fa75",
        button: "#D3F987",
        warning: "#b6b6b6",
        smoke: "#f0f0f0",
        dark: "#191919",
        facebook: "#1877F2",
        instagram: "#C13584",
        linkedin: "#0072b1",
        pink: "#E2BAC2",
        faded: "#5C5C5C",
      },
    },
  },
});
