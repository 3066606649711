<template>
  <div v-if="!$store.state.loading.general && !$route.meta.noNav">
    <v-menu offset-y top :close-on-content-click="false" v-model="menu">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="error"
          dark
          v-bind="attrs"
          v-on="on"
          class="feed primary--text"
          tile
          style="font-size: 9pt"
        >
          Feedback
        </v-btn>
      </template>
      <div class="pa-6 primary" style="width: 400px">
        <v-select
          solo
          flat
          background-color="smoke"
          label="Type of feedback"
          :items="items"
          hide-details=""
          style="border-radius: 6px"
          v-model="type"
          class="mb-4"
        ></v-select>
        <v-text-field
          v-if="
            this.$store.state.users.userProfile &&
            this.$store.state.users.userProfile.superadmin
          "
          v-model="title"
          placeholder="Title here.."
          flat
          solo
          style="border-radius: 6px"
          hide-details=""
          class="mb-2"
          background-color="smoke"
        ></v-text-field>
        <v-textarea
          background-color="smoke"
          solo
          flat
          v-model="message"
          style="border-radius: 6px"
          placeholder="Type your feedback here.."
        ></v-textarea>
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="button"
            class="primary--text"
            :loading="saving"
            @click="saveFeedback"
            >Send</v-btn
          >
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { collection, addDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "@/firebase";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase";
export default {
  data() {
    return {
      items: ["Bug/issue", "Question", "Suggestion", "Other"],
      type: null,
      message: "",
      menu: false,
      title: "",
      saving: false,
    };
  },
  methods: {
    async saveFeedback() {
      this.saving = true;
      const email = httpsCallable(functions, "email");

      if (this.type === "Bug/issue" || this.type === "Suggestion") {
        const docRef = await addDoc(collection(db, "backlog"), {
          created_at: Math.floor(Date.now() / 1000),
          description: this.message,
          type: this.type,
          status: "requested",
          images: [],
          suggested_by: this.$store.state.users.userProfile.uid,
          title: this.title ? this.title : "",
          votes: [this.$store.state.users.userProfile.uid],
        });
        console.log("Document written with ID: ", docRef.id);

        const ref = doc(db, "backlog", docRef.id);

        await updateDoc(ref, {
          uid: docRef.id,
        });

        await email({
          email: [this.$store.state.users.userProfile.email],
          template: {
            name: "feedback-confirmation",
            data: {
              type: this.type,
              title: this.title,
              feedback: this.message,
            },
          },
        });
      } else {
        await email({
          email: [this.$store.state.users.userProfile.email],
          template: {
            name: "feedback-confirmation",
            data: {
              type: this.type,
              title: this.title,
              feedback: this.message,
            },
          },
        });
      }

      this.$store.commit("setNotification", {
        show: true,
        text: `Your ${this.type} is sent! Thanks!`,
        type: "primary",
        icon: "",
      });
      this.saving = false;
      this.menu = false;
      this.message = "";
      this.title = "";
      this.type = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.feed {
  margin: 0;
  position: fixed;
  bottom: 0;
  right: 15%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 98;
}
</style>
