var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"gap":"20px","padding":"0 44px"}},[_c('div',{staticClass:"d-flex py-3 justify-space-between sticky",staticStyle:{"gap":"20px"}},[_c('div',{staticStyle:{"gap":"20px","display":"grid","grid-template-columns":"200px 200px"}},[_c('CategorySelect',{attrs:{"category":_vm.category,"clearable":true},on:{"update:category":function($event){_vm.category=$event}}}),_c('AccountsSelect',{attrs:{"selected_accounts":_vm.selected_accounts},on:{"update:selected_accounts":function($event){_vm.selected_accounts=$event}}})],1),(_vm.$route.path.includes('/calendar'))?_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"gap":"20px"}},[_c('v-btn',{attrs:{"disabled":this.$store.state.schedules.window === 0,"icon":""},on:{"click":_vm.previous}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('span',{staticStyle:{"font-size":"16px","font-weight":"600","width":"85px","text-align":"center"}},[_vm._v(_vm._s(_vm.$store.state.schedules.month))]),_c('v-btn',{attrs:{"disabled":this.$store.state.schedules.window ===
            this.$store.state.schedules.calendar.length - 1,"icon":""},on:{"click":_vm.next}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1),_c('v-btn',{attrs:{"text":"","small":""},on:{"click":_vm.today}},[_vm._v("Today")])],1):_vm._e()]),_c('v-card',{staticClass:"calendarcard",style:({
        borderBottom: '3px solid #272727',
        borderLeft: '1px solid #272727',
        borderTop: '1px solid #272727',
        borderRight: '3px solid #272727',
      }),attrs:{"flat":""}},[_c('div',[_c('v-sheet',{staticClass:"d-flex justify-center align-center py-2",staticStyle:{"font-size":"10pt","font-weight":"600","border-top-right-radius":"7px","border-top-left-radius":"7px"},attrs:{"color":"accent"}},[_vm._v(" Calendar ")]),_c('v-divider',{staticClass:"primary"})],1),_c('v-window',{model:{value:(_vm.window),callback:function ($$v) {_vm.window=$$v},expression:"window"}},_vm._l((_vm.calendar),function(n,i){return _c('v-window-item',{key:i},[_c('div',{staticClass:"calendar"},_vm._l((n.days),function(d,index){return _c('div',{key:index,staticClass:"day"},[_c('div',[_c('div',{staticClass:"day-title",class:{ today: d.today }},[_c('span',[_vm._v(" "+_vm._s(d.text))])]),_c('v-divider')],1),_c('div',{staticClass:"pa-2"},[_c('v-btn',{attrs:{"elevation":"0","small":"","text":"","disabled":d.past && !d.today,"block":""},on:{"click":function($event){return _vm.addScheduledPost(d.unix)}}},[_vm._v("Add post")])],1),(!_vm.loading.calendar)?_c('div',{staticClass:"px-2 posts"},_vm._l((d.posts),function(p){return _c('div',{key:p.uid},[(_vm.showPost(p))?_c('Post',{attrs:{"post":p,"calendar_uid":d.uid}}):_vm._e()],1)}),0):_vm._e()])}),0)])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }