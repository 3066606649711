import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getApp } from "firebase/app";

let firebaseConfig;
if (window.location.origin.includes("app.stix.social")) {
  console.log("Running Production Environment");
  firebaseConfig = {
    apiKey: "AIzaSyA2UsrY2lIVqa_cbcnqmuVyokdWsxZKGsQ",
    authDomain: "stix-production.firebaseapp.com",
    projectId: "stix-production",
    storageBucket: "stix-production.firebasestorage.app",
    messagingSenderId: "342845329004",
    appId: "1:342845329004:web:68d8aa53263c01a50ed249",
    measurementId: "G-LH3KXQP4C8",
  };
} else {
  console.log("Running Staging Environment");
  firebaseConfig = {
    apiKey: "AIzaSyB8EkS_lE4jnAxOD65aYmLF4VfydUwRfvs",
    authDomain: "stix-staging-f240f.firebaseapp.com",
    projectId: "stix-staging-f240f",
    storageBucket: "stix-staging-f240f.appspot.com",
    messagingSenderId: "156531495317",
    appId: "1:156531495317:web:ace2dfd8329579a6c4669c",
    measurementId: "G-Z064NBGKN6",
  };
}

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
let emulator = true;
const functions = getFunctions(getApp(), "europe-west1");

if (emulator && window.location.origin.includes("localhost")) {
  console.log("Starting Functions Emulators");
  connectFunctionsEmulator(functions, "localhost", 5001);
}

// utils
const db = getFirestore(app);
const auth = getAuth;

// export utils/refs
export { db, auth, functions, storage };
