// import { getAuth, signOut } from "firebase/auth";
// import router from "@/router";
import { db } from "@/firebase";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase";
import { DateTime } from "luxon";

const schedules = {
  state: {
    schedules: [],
    queue: [],
    calendar: [],
    week: 0,
    window: 0,
    week_day: null,
    month: "",
  },
  mutations: {
    setSchedules(state, val) {
      state.schedules = val;
    },
    setQueue(state, val) {
      state.queue = val;
    },
    setCalendar(state, val) {
      state.calendar = val;
    },
    setWeek(state, val) {
      state.week = val;
    },
    setMonth(state, val) {
      state.month = val;
    },
    nextWeek(state) {
      if (state.window < state.calendar.length) {
        let next = state.week_day.plus({ week: 1 });
        state.week_day = next;
        state.week = next.weekNumber;
        state.window = state.window + 1;
        state.month = next.toFormat("LLLL");
      }
    },
    previousWeek(state) {
      if (state.window > 0) {
        let previous = state.week_day.minus({ week: 1 });
        state.week_day = previous;
        state.week = previous.weekNumber;
        state.window = state.window - 1;
        state.month = previous.toFormat("LLLL");
      }
    },
    today(state) {
      const i = state.calendar.findIndex(
        (w) => w.week === DateTime.now().weekNumber
      );
      state.month = DateTime.now().toFormat("LLLL");
      state.week_day = DateTime.now();
      state.window = i;
    },
  },
  actions: {
    async deleteSchedule({ rootState, dispatch }, uid) {
      await deleteDoc(
        doc(
          db,
          "workspaces",
          rootState.users.userProfile.workspace,
          "schedules",
          uid
        )
      );

      dispatch("fetchSchedules");
      dispatch("fetchCategories");
    },
    async getCalendarDays({ commit }) {
      commit("setLoading", { type: "calendar", val: true });
      const getCalendar = httpsCallable(functions, "calenderGet");
      const res = await getCalendar();
      commit("setCalendar", res.data);
      commit("today");
      commit("setLoading", { type: "calendar", val: false });
    },
    async fetchSchedules({ commit, rootState }) {
      commit("setLoading", { type: "schedules", val: true });
      let schedules = [];

      const querySnapshot = await getDocs(
        collection(
          db,
          "workspaces",
          rootState.users.userProfile.workspace,
          "schedules"
        )
      );
      querySnapshot.forEach(async (d) => {
        let schedule = d.data();
        const docRef = doc(
          db,
          "workspaces",
          rootState.users.userProfile.workspace,
          "categories",
          schedule.category
        );
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          schedule.category = docSnap.data();
        } else {
          console.log("No such document!");
        }

        schedules.push(schedule);
      });

      commit("setSchedules", schedules);
      commit("setLoading", { type: "schedules", val: false });
    },
  },
  getters: {},
};

export default schedules;
