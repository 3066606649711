<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="admin_notifications"
      :items-per-page="-1"
      class="smoke"
      hide-default-footer
      :search="search"
      sort-by="created_at"
      sort-desc=""
      :loading="loading.admin.emails"
    >
      <template v-slot:top>
        <div class="d-flex align-center pa-4" style="gap: 10px">
          <v-text-field
            v-model="search"
            label="Search"
            solo
            flat
            style="border-radius: 6px"
            hide-details=""
          ></v-text-field>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="300"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                height="48"
                elevation="0"
                color="primary"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </template>

            <v-card>
              <div class="pa-4">
                <v-text-field
                  solo
                  hide-details=""
                  background-color="smoke"
                  placeholder="Title"
                  class="mb-2"
                  flat
                  style="border-radius: 6px"
                  v-model="notification.title"
                ></v-text-field>
                <v-textarea
                  solo
                  class="mb-2"
                  hide-details=""
                  background-color="smoke"
                  placeholder="Message"
                  flat
                  style="border-radius: 6px"
                  v-model="notification.message"
                ></v-textarea>
                <v-text-field
                  solo
                  hide-details=""
                  background-color="smoke"
                  placeholder="Read more link"
                  flat
                  style="border-radius: 6px"
                  class="mb-2"
                  v-model="notification.read_more"
                ></v-text-field>
                <v-select
                  solo
                  flat
                  style="border-radius: 6px"
                  label="Color"
                  background-color="smoke"
                  :items="['success', 'primary', 'info', 'secondary']"
                  clearable
                  v-model="notification.color"
                  hide-details=""
                  class="mb-2"
                ></v-select>

                <v-autocomplete
                  solo
                  flat
                  style="border-radius: 6px"
                  label="Users"
                  hint="Leave empty for all"
                  persistent-hint
                  background-color="smoke"
                  :items="admin_users"
                  clearable
                  item-text="name"
                  item-value="uid"
                  multiple
                  v-model="notification.users"
                ></v-autocomplete>

                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="notification.end_date"
                      label="End date"
                      prepend-icon="mdi-calendar"
                      readonly
                      solo
                      hide-details=""
                      background-color="smoke"
                      placeholder="End date"
                      flat
                      style="border-radius: 6px"
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="notification.end_date"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </div>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn text @click="menu = false"> Cancel </v-btn>
                <v-btn color="primary" text @click="send"> Send </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
      </template>

      <template v-slot:[`item.title`]="{ item }">
        <v-text-field
          v-model="item.title"
          hide-details=""
          solo
          style="border-radius: 6px"
          flat
          @change="update(item)"
        ></v-text-field>
      </template>

      <template v-slot:[`item.read_more`]="{ item }">
        <v-text-field
          v-model="item.read_more"
          hide-details=""
          solo
          style="border-radius: 6px"
          flat
          @change="update(item)"
        ></v-text-field>
      </template>

      <template v-slot:[`item.message`]="{ item }">
        <v-textarea
          v-model="item.message"
          hide-details=""
          solo
          style="border-radius: 6px"
          flat
          class="my-2"
          @change="update(item)"
        ></v-textarea>
      </template>

      <template v-slot:[`item.end_date`]="{ item }">
        <v-menu
          v-model="item.menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="item.end_date"
              label="End date"
              prepend-icon="mdi-calendar"
              readonly
              solo
              hide-details=""
              placeholder="End date"
              flat
              style="border-radius: 6px"
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="item.end_date"
            @input="
              update(item);
              item.menu = false;
            "
          ></v-date-picker>
        </v-menu>
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        {{ formatDate(item.created_at) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" elevation="0" color="primary"
              ><v-icon small>mdi-dots-vertical</v-icon></v-btn
            >
          </template>

          <v-card>
            <v-list>
              <v-list-item @click="openInFb(item.uid)" link>
                <v-list-item-icon
                  ><v-icon small color="primary"
                    >mdi-firebase</v-icon
                  ></v-list-item-icon
                >
                Firebase
              </v-list-item>

              <v-list-item @click="deleteItem(item.uid)" link>
                <v-list-item-icon
                  ><v-icon color="primary" small
                    >mdi-delete</v-icon
                  ></v-list-item-icon
                >
                Delete
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { mapState } from "vuex";
import {
  collection,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "@/firebase";
import moment from "moment";

export default {
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      admin_notifications: (state) => state.admin.notifications,
      admin_users: (state) => state.admin.users,
    }),
  },
  data() {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      notification: {
        title: "",
        message: "",
        read_more: "",
        color: null,
        created_at: Math.floor(Date.now() / 1000),
        end_date: null,
      },
      search: "",
      headers: [
        { text: "Created", value: "created_at" },
        { text: "Views", value: "views.length" },
        { text: "End Date", value: "end_date" },

        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "title",
        },
        { text: "Message", value: "message" },
        { text: "Read more", value: "read_more" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  methods: {
    async deleteItem(uid) {
      await deleteDoc(doc(db, "notifications", uid));
      this.$store.dispatch("fetchAdminNotifications");
    },
    formatDate(d) {
      let date = d.toDate();
      return moment(date).format("DD-MM-YYYY");
    },
    async update(v) {
      const ref = doc(db, "notifications", v.uid);
      await updateDoc(ref, {
        title: v.title,
        message: v.message,
        read_more: v.read_more,
        end_date: v.end_date,
      });
      this.$store.commit("setNotification", {
        show: true,
        text: "Notification updated!",
        type: "primary",
        icon: "",
      });
    },
    openInFb(uid) {
      let production = window.location.origin.includes("app.stix.social");
      let url = `https://console.firebase.google.com/u/0/project/${
        production ? "one-startup-stack" : "stix-staging-f240f"
      }/firestore/data/~2Fnotifications~2F${uid}`;
      window.open(url, "_blank");
    },
    async send() {
      const docRef = await addDoc(
        collection(db, "notifications"),
        this.notification
      );
      const ref = doc(db, "notifications", docRef.id);
      await updateDoc(ref, {
        uid: docRef.id,
      });
      this.$store.dispatch("fetchAdminNotifications");

      this.menu = false;
      this.notification = {
        title: "",
        message: "",
        read_more: "",
        color: null,
        created_at: Math.floor(Date.now() / 1000),
        end_date: null,
      };
    },
  },
};
</script>
