<template>
  <v-navigation-drawer
    :value="show"
    app
    color="primary"
    temporary
    right
    width="600"
    @input="change"
  >
    <div class="pa-8">
      <div class="d-flex justify-space-between align-center">
        <h1 class="white--text mt-6">Media browser</h1>
        <v-btn @click="show = false" icon color="white"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>

      <p class="mb-4 white--text" style="font-size: 12px">
        You can browse through free stock photos and videos from Pexels, or
        upload your own media.
      </p>

      <v-tabs
        class="ml-2"
        height="35"
        background-color="transparent"
        hide-slider
        active-class="active-tab"
        v-model="tab"
      >
        <v-tab class="tab mr-1">Uploads</v-tab>
        <v-tab class="tab mr-1">Stock photos</v-tab>
        <v-tab class="tab" v-if="!type || !type.includes('template.background')"
          >Templates</v-tab
        >
      </v-tabs>
      <v-card flat class="pt-4" color="white" v-if="userProfile">
        <div v-if="tab === 1">
          <div
            style="display: grid; grid-template-columns: 1fr 1fr; gap: 10px"
            class="px-4 pb-4"
          >
            <v-text-field
              label="Search"
              style="border-radius: 6px"
              solo
              v-model="search"
              flat
              dense
              hide-details=""
              background-color="smoke"
              @keypress="enter"
            ></v-text-field>
            <v-btn
              block
              elevation="0"
              height="38"
              color="accent"
              class="primary--text"
              :loading="search_loading"
              @click="searchMedia"
            >
              Search stock
            </v-btn>
          </div>

          <div v-if="items.length > 0">
            <p
              class="text-center"
              style="font-size: 12px; font-weight: 00; margin-bottom: 5px"
            >
              Preview size:
            </p>
            <div class="pb-4 px-4 d-flex align-center">
              <v-divider class="mr-4"></v-divider>
              <v-btn-toggle
                v-model="userProfile.preferences.preview_size"
                color="primary"
                dense
              >
                <v-btn value="small" @click="setPreviewSize('small')">
                  Small
                </v-btn>
                <v-btn value="medium" @click="setPreviewSize('medium')">
                  Medium
                </v-btn>
                <v-btn value="large" @click="setPreviewSize('large')">
                  Large
                </v-btn>
              </v-btn-toggle>
              <v-divider class="ml-4"></v-divider>
            </div>
          </div>
          <div
            class="stockphotos px-4"
            :class="userProfile.preferences.preview_size"
          >
            <div
              v-for="(i, index) in items"
              :key="index"
              @click="selectMedia({ ...i, type: 'stock' })"
            >
              <img :src="i.src.large" />
            </div>
          </div>
          <div
            class="d-flex justify-center"
            v-if="!search_loading && items.length > 0"
          >
            <v-btn @click="nextPage" outlined elevation="0" class="px-6 my-4"
              >Load more</v-btn
            >
          </div>
        </div>
        <div v-if="tab === 0">
          <div class="d-flex justify-space-between align-center mb-6 px-4">
            <v-btn
              height="38"
              elevation="0"
              color="accent"
              class="primary--text"
              @click="trigger"
              style="font-size: 10pt"
              >Upload media</v-btn
            >
            <input
              v-if="!userProfile.trialEnded"
              ref="headerRef"
              type="file"
              multiple
              @change="onFileChange"
              @click="resetValue"
              accept="image/jpeg, image/png, image/jpg, video/mp4"
            />
            <v-text-field
              class="px-4"
              solo
              flat
              label="Filter media"
              hide-details=""
              dense
              background-color="smoke"
              style="border-radius: 6px"
              prepend-inner-icon="mdi-magnify"
              v-model="search_media"
            ></v-text-field>
          </div>
          <div
            v-if="search_loading"
            style="width: 100%"
            class="d-flex justify-center mt-6"
          >
            <v-progress-circular
              indeterminate
              color="accent"
              width="2"
            ></v-progress-circular>
          </div>
          <v-data-table
            :headers="headers"
            :items="media"
            :items-per-page="5"
            hide-default-header
            @click:row="selectMedia"
            style="cursor: pointer"
            :search="search_media"
            :loading="media_loading"
          >
            <template v-slot:[`item.url`]="{ item }">
              <div style="width: 100px">
                <v-img
                  :src="item.thumbnail ? item.thumbnail : item.url"
                  height="100"
                  width="100"
                  cover
                ></v-img>
              </div>
            </template>

            <template v-slot:[`item.title`]="{ item }">
              <div>
                <p class="mb-1">{{ item.title }}</p>
                <p
                  class="mb-0"
                  style="font-size: 10px; font-weight: 600; opacity: 0.4"
                >
                  {{ Number.parseFloat(item.size / 1024).toFixed(1) }}MB &#183;
                  {{ item.extension.toUpperCase() }} &#183;
                  {{ item.created_by_email.toUpperCase() }} &#183;
                  {{ formatDate(item.created_at) }}
                </p>
              </div>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-menu transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon small>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    link
                    class="px-4"
                    @click="$store.dispatch('deleteMedia', item.uid)"
                  >
                    <v-icon small class="mr-3">mdi-delete</v-icon>
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </div>

        <div v-if="tab === 2">
          <div class="d-flex justify-space-between align-center mb-6 px-4">
            <v-btn
              height="38"
              elevation="0"
              color="accent"
              class="primary--text"
              style="font-size: 10pt"
              >Add template</v-btn
            >

            <v-text-field
              class="px-4"
              solo
              flat
              label="Search templates"
              hide-details=""
              dense
              background-color="smoke"
              style="border-radius: 6px"
              prepend-inner-icon="mdi-magnify"
              v-model="search_templates"
            ></v-text-field>
          </div>
          <div
            v-if="loading.templates"
            style="width: 100%"
            class="d-flex justify-center mt-6"
          >
            <v-progress-circular
              indeterminate
              color="accent"
              width="2"
            ></v-progress-circular>
          </div>
          <v-data-table
            :headers="headers_templates"
            :items="filteredTemplates"
            :items-per-page="5"
            hide-default-header
            @click:row="selectTemplate"
            style="cursor: pointer"
            :loading="loading.templates"
          >
            <template v-slot:[`item.url`]="{ item }">
              <div style="width: 100px" class="pa-1">
                <v-img :src="item.thumbnail" contain></v-img>
              </div>
            </template>

            <template v-slot:[`item.title`]="{ item }">
              <div class="py-2">
                <p class="mb-1">{{ item.name }}</p>

                <p
                  class="mb-0"
                  style="font-size: 10px; font-weight: 600; opacity: 0.4"
                >
                  <!-- {{ Number.parseFloat(item.size / 1024).toFixed(1) }}MB &#183; -->
                  <!-- {{ item.extension.toUpperCase() }} &#183; -->
                  {{ item.created_by_email.toUpperCase() }} &#183;
                  {{ formatDate(item.created_at) }}
                </p>
                <div class="d-flex flex-wrap mt-2" style="gap: 5px">
                  <v-chip small color="info" v-for="i in item.tags" :key="i">{{
                    i
                  }}</v-chip>
                </div>
              </div>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-menu transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon small>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    link
                    class="px-4"
                    @click="$store.dispatch('deleteMedia', item.uid)"
                  >
                    <v-icon small class="mr-3">mdi-delete</v-icon>
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { db } from "@/firebase";
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase";
import { medium } from "@/mixins/medium";

export default {
  mixins: [medium],
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      accounts: (state) => state.accounts,
      categories: (state) => state.posts.categories,
      media: (state) => state.media.media,
      media_loading: (state) => state.loading.media,
      loading: (state) => state.loading,
      templates: (state) => state.templates.templates,
    }),
    filteredTemplates() {
      let list = this.templates;
      if (this.search_templates) {
        list = list.filter((i) => {
          let pass = false;
          if (
            i.name.includes(this.search_templates.toLowerCase()) ||
            i.created_by_email.includes(this.search_templates.toLowerCase())
          ) {
            pass = true;
          }
          for (let x in i.tags) {
            if (
              i.tags[x]
                .toLowerCase()
                .includes(this.search_templates.toLowerCase())
            ) {
              pass = true;
            }
          }
          return pass;
        });
      }
      return list;
    },
  },
  beforeDestroy() {
    this.$store.commit("clearMedium");
  },
  data() {
    return {
      headers: [
        { text: "Image", value: "url", align: "start" },
        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "title",
        },

        { text: "Actions", value: "actions", align: "end" },
      ],
      headers_templates: [
        { text: "Image", value: "url", align: "start" },
        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "title",
        },
        { text: "Actions", value: "actions", align: "end" },
      ],
    };
  },
  methods: {
    enter(v) {
      if (v.code === "Enter") {
        this.searchMedia();
      }
    },
    change(v) {
      if (!v) {
        this.$store.commit("setMediumShow", false);
      }
    },
    async searchMedia() {
      if (this.search) {
        this.search_loading = true;
        this.tab = 1;
        const get = httpsCallable(functions, "pexelsGet");
        const res = await get({ query: this.search });
        this.items = [];
        this.items = res.data.photos;
        this.next_page = res.data.next_page;
        this.nextPage();
        this.search_loading = false;
      }
    },
    async nextPage() {
      const get = httpsCallable(functions, "pexelsGetNext");
      const res = await get({ url: this.next_page });

      res.data.photos.forEach((p) => {
        this.items.push(p);
      });
      this.next_page = res.data.next_page;
    },
    formatDate(d) {
      return moment(d).format("DD-MM-YYYY");
    },
    async onFileChange() {
      var files = this.$refs.headerRef.files;
      const uploads = [...files];

      for (let x in uploads) {
        this.uploadFiles(uploads[x]);
      }
    },
    setPreviewSize(size) {
      this.$store.dispatch("setPreviewSize", size);
    },
    selectMedia(i) {
      this.$store.dispatch("selectMedia", i);
    },
    selectTemplate(i) {
      let x = i;
      x.type = "template";
      this.selectMedia(x);
    },
    resetValue(event) {
      event.target.value = "";
    },
    trigger() {
      this.$refs.headerRef.click();
    },
    async uploadFiles(upload) {
      this.search_loading = true;
      console.log(upload);
      var fileName = upload.name;
      var fileExtension = fileName.split(".").pop();
      const filesize = Math.floor(upload.size / 1024);

      function isType(fileExtension) {
        switch (fileExtension.toLowerCase()) {
          case "m4v":
            return "video";
          case "avi":
            return "video";
          case "mpg":
            return "video";
          case "mp4":
            return "video";
          case "jpg":
            return "image";
          case "gif":
            return "image";
          case "bmp":
            return "image";
          case "png":
            return "image";
          case "jpeg":
            return "image";
        }
        return null;
      }

      const storage = getStorage();

      const metadata = {
        contentType: upload.type,
      };
      const docRef = await addDoc(
        collection(db, "workspaces", this.userProfile.workspace, "media"),
        {
          title: fileName,
          extension: fileExtension,
          class: "media",
          created_at: Math.floor(Date.now() / 1000),
          created_by: this.userProfile.uid,
          created_by_email: this.userProfile.email,
          workspace: this.userProfile.workspace,
          size: filesize,
          posts: [],
          templates: [],
          tags: [],
          type: isType(fileExtension),
        }
      );
      const storageRef = ref(
        storage,
        `${this.userProfile.workspace}/media/${docRef.id}.${fileExtension}`
      );

      await uploadBytesResumable(storageRef, upload, metadata);

      const downloadURL = await getDownloadURL(storageRef);
      const updateRef = doc(
        db,
        "workspaces",
        this.userProfile.workspace,
        "media",
        docRef.id
      );

      await updateDoc(updateRef, {
        uid: docRef.id,
        src: downloadURL,
      });

      this.$store.commit("setNotification", {
        show: true,
        text: "Media added!",
        type: "primary",
        icon: "",
      });

      this.$store.dispatch("fetchMedia");
      this.search_loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
}

.tab {
  background-color: white;
  border-radius: 6px 6px 0 0;
  text-transform: none;
  font-size: 12px;
  opacity: 0.6;
}
.active-tab {
  opacity: 1;
}

.stockphotos {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 60vh;
  overflow: scroll;
  &.small {
    img {
      max-height: 100px;
    }
  }

  &.medium {
    img {
      max-height: 150px;
    }
  }

  &.large {
    img {
      max-width: 100%;
    }
  }

  img {
    border-radius: 6px;
  }

  img:hover {
    outline: 2px solid #cfb8ff;
  }
}
</style>
