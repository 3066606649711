<template>
  <v-navigation-drawer
    :value="drawer"
    app
    color="primary"
    temporary
    right
    width="600"
    style="z-index: 8"
    @input="change"
  >
    <div class="pa-8">
      <div class="d-flex justify-space-between align-center">
        <h1 class="white--text mt-6">Structures</h1>
        <v-btn @click="$emit('close')" icon color="white"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>

      <p class="mb-4 white--text" style="font-size: 12px">
        You can browse through post structures that you have created.
      </p>

      <v-card flat class="pt-4" color="white" v-if="userProfile">
        <v-data-table
          :headers="headers"
          :items="structures"
          :items-per-page="5"
          hide-default-header
          @click:row="select"
          style="cursor: pointer"
          :search="search"
          :loading="structures_loading"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu transition="slide-y-transition" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon small>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item link class="px-4" @click="select(item)">
                  <v-icon small class="mr-3">mdi-send</v-icon>
                  <v-list-item-title>Use</v-list-item-title>
                </v-list-item>
                <v-list-item link class="px-4">
                  <v-icon small class="mr-3">mdi-pencil</v-icon>
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  class="px-4"
                  @click="$store.dispatch('deleteMedia', item.uid)"
                >
                  <v-icon small class="mr-3">mdi-delete</v-icon>
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  props: ["drawer"],
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      structures: (state) => state.structures.structures,
      structures_loading: (state) => state.loading.structures,
    }),
  },
  data() {
    return {
      headers: [
        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "title",
        },

        // { text: "Actions", value: "actions", align: "end" },
      ],
      items: [],
      loading: false,
      next_page: "",
      search: "",
      search_media: "",
      tab: 0,
    };
  },
  methods: {
    change(v) {
      if (!v) {
        this.$emit("close");
      }
    },
    formatDate(d) {
      return moment(d.toDate()).format("DD-MM-YYYY");
    },
    select(i) {
      this.$emit("structure", i.structure);
    },
  },
};
</script>

<style lang="scss" scoped></style>
