<template>
  <div>
    <div>
      <div v-if="!$vuetify.breakpoint.smAndDown">
        <div class="top mb-4">
          <AddTopic ref="topic" />
          <v-text-field
            label="Search topics ..."
            :style="{
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
            }"
            flat
            solo
            dense
            hide-details=""
            style="border-radius: 8px; max-width: 200px"
            v-model="search"
            clearable
          >
          </v-text-field>
          <v-select
            label="Tags"
            solo
            style="border-radius: 8px; max-width: 200px"
            :style="{
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
            }"
            hide-details=""
            class="mb-4"
            :items="tags"
            v-model="selectedTag"
            flat
            dense
            clearable
          ></v-select>
        </div>

        <div class="topics">
          <v-card
            max-width="700"
            flat
            :style="{
              borderBottom: '3px solid #272727',
              borderLeft: '1px solid #272727',
              borderTop: '1px solid #272727',
              borderRight: '3px solid #272727',
            }"
          >
            <div>
              <v-sheet
                class="d-flex justify-center align-center py-2"
                color="accent"
                style="
                  font-size: 10pt;
                  font-weight: 600;
                  border-top-right-radius: 7px;
                  border-top-left-radius: 7px;
                "
              >
                Topics
              </v-sheet>
              <v-divider class="primary"></v-divider>
            </div>
            <v-data-table
              style="max-width: 1200px"
              :headers="headers"
              :items="filtered"
              mobile-breakpoint="100"
              :search="search"
              hide-default-header
              :items-per-page="5"
            >
              <template v-slot:[`item.tags`]="{ item }">
                <div class="d-flex flex-wrap" style="gap: 3px">
                  <v-chip
                    color="info"
                    small
                    v-for="(t, i) in item.tags"
                    :key="i"
                    >{{ t }}</v-chip
                  >
                </div>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon small
                      ><v-icon color="primary" small
                        >mdi-dots-vertical</v-icon
                      ></v-btn
                    >
                  </template>
                  <v-list>
                    <v-list-item link class="px-4" @click="use(item)">
                      <v-icon small color="primary" class="mr-3"
                        >mdi-send</v-icon
                      >
                      <v-list-item-title>Use</v-list-item-title>
                    </v-list-item>

                    <v-list-item link class="px-4" @click="edit(item)">
                      <v-icon small color="primary" class="mr-3"
                        >mdi-pencil</v-icon
                      >
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      link
                      class="px-4"
                      @click="deleteItem(item.uid)"
                    >
                      <v-icon small color="primary" class="mr-3"
                        >mdi-delete</v-icon
                      >
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <!-- <div
                  class="d-flex align-center animate__animated animate__slideInRight"
                  style="gap: 5px"
                >
                  <v-btn text small> Create post </v-btn>
                  <v-btn icon>
                    <v-icon color="primary" small> mdi-pencil </v-icon>
                  </v-btn>
                  <v-btn @click="deleteItem(item)" icon>
                    <v-icon color="primary" small> mdi-delete </v-icon>
                  </v-btn>
                </div> -->
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AddTopic from "@/components/AddTopic.vue";

export default {
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      workspace: (state) => state.workspace.workspace,
      loading: (state) => state.loading,
      topics: (state) => state.topics.topics,
      tags: (state) => state.topics.tags,
    }),
    filtered() {
      let l = this.topics;
      if (this.selectedTag) {
        l = l.filter((t) => t.tags.includes(this.selectedTag));
      }
      return l;
    },
  },
  components: {
    AddTopic,
  },
  data() {
    return {
      headers: [
        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "topic",
        },
        {
          text: "Tags",
          align: "end",
          sortable: false,
          value: "tags",
        },
        {
          text: "Title",
          align: "end",
          sortable: false,
          value: "actions",
        },
      ],
      hover: "",
      search: "",
      show_favorites: false,
      selectedTags: [],
      selectedTag: null,
    };
  },
  mounted() {
    if (this.$route.query.new) {
      const child = this.$refs.topic;
      child.open();
    }
  },
  methods: {
    edit(i) {
      const child = this.$refs.topic;
      child.edit(i);
    },
    deleteItem(i) {
      this.$store.dispatch("deleteTopic", i);
    },
    use(i) {
      this.$router.replace({
        name: "Post",
        params: {
          topic: i.topic,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./styles.scss";
</style>
