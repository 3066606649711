<template>
  <v-app-bar
    style="z-index: 6"
    class="smoke animate__animated animate__slideInDown white"
    v-if="
      !$route.meta.noNav &&
      !$route.path.includes('demo') &&
      !$route.path.includes('/admin') &&
      $route.name != 'Edit template'
    "
    app
    flat
  >
    <div style="padding: 0 28px; width: 100%" class="d-flex">
      <v-btn v-if="$vuetify.breakpoint.smAndDown">Add post</v-btn>
      <MobileMenu
        v-if="$vuetify.breakpoint.smAndDown"
        style="padding-right: 12px"
      />

      <div
        v-if="!$vuetify.breakpoint.smAndDown"
        class="d-flex align-center"
        style="width: 100%"
      >
        <AddButton />
        <!-- <Search class="ml-4" /> -->

        <v-spacer></v-spacer>
        <!-- <OnboardingCard class="animate__animated animate__slideInLeft mr-3" /> -->

        <!-- <v-btn @click="$store.commit('setQueue', true)" icon class="mr-3s"
          ><v-icon size="20" color="primary">mdi-playlist-play</v-icon></v-btn
        > -->
        <Notifications />

        <v-btn class="mr-3 ml-1" icon @click="openHelp"
          ><v-icon size="20" color="primary">mdi-help-circle</v-icon></v-btn
        >
        <DotsMenu />
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import DotsMenu from "@/components/DotsMenu.vue";
import MobileMenu from "@/components/MobileMenu.vue";
import AddButton from "@/components/add-button/AddButton.vue";
import Notifications from "@/components/NotificationsCard.vue";
// import Search from "@/components/search/MainSearch.vue";
// import OnboardingCard from "@/components/OnboardingCard.vue";

export default {
  components: {
    DotsMenu,
    MobileMenu,
    AddButton,
    Notifications,
    // OnboardingCard,
    // Search,
  },
  data() {
    return {
      queue_drawer: false,
    };
  },
  methods: {
    openHelp() {
      window.open("https://help.stix.social", "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.openai {
  #openai-logo {
    transition: all 0.3s ease;
  }
  &:hover {
    #openai-logo {
      transform: rotate(180deg);
    }
  }
}

.animate__animated.animate__slideInLeft {
  --animate-duration: 0.5s;
}
</style>
