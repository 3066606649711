<template>
  <div
    class="signup-container"
    :class="{
      mob: $vuetify.breakpoint.smAndDown,
      desk: !$vuetify.breakpoint.smAndDown,
    }"
  >
    <v-overlay
      :value="overlay"
      color="button"
      opacity="1"
      v-if="overlay"
      class="animate__animated animate__slideInRight text-center d-flex justify-center align-center flex-column"
    >
      <p class="primary--text mb-4">Creating your awesome workspace..</p>
      <v-progress-linear
        rounded
        indeterminate
        color="primary"
        style="width: 100px"
        class="mx-auto"
      ></v-progress-linear>
    </v-overlay>
    <div class="left">
      <div class="logo">
        <v-img width="40" src="@/assets/logos/icon-dark.svg" contain></v-img>
        <b style="font-size: 18px">stix</b>
      </div>
      <div
        class="sub-container signin"
        v-if="mode === 'signin' || mode === 'signup'"
        :style="{
          width: $vuetify.breakpoint.smAndDown ? '80%' : '60%',
          paddingBottom: $vuetify.breakpoint.smAndDown ? '0' : '130px',
        }"
      >
        <div class="mb-4">
          <v-btn
            elevation="0"
            @click="mode = 'signin'"
            class="primary--text"
            :color="mode === 'signin' ? 'accent' : ''"
            :text="mode === 'signup'"
            >Sign in</v-btn
          >
          <v-btn
            elevation="0"
            @click="mode = 'signup'"
            class="primary--text"
            :color="mode === 'signup' ? 'accent' : ''"
            :text="mode === 'signin'"
            >Sign up</v-btn
          >
        </div>
        <h1
          style="margin-bottom: 10px"
          :style="{
            fontSize: $vuetify.breakpoint.smAndDown ? '35px' : '40px',
          }"
          v-if="!$route.query.workspace"
        >
          Turn <span class="accent--text"><u>autopilot on</u></span> for your
          social media
        </h1>
        <h1
          style="margin-bottom: 10px"
          :style="{
            fontSize: $vuetify.breakpoint.smAndDown ? '35px' : '40px',
          }"
          v-else
        >
          Join the
          <span class="accent--text">{{ $route.query.name }}</span> workspace
        </h1>
        <p style="font-size: 13px; font-weight: 400">
          By signing {{ mode === "signup" ? "up" : "in" }}, I agree with stix'
          <u
            class="accent--text"
            style="cursor: pointer"
            @click="
              openTab('https://www.stix.social/utilities/terms-of-service')
            "
            >Terms</u
          >
          and
          <u
            style="cursor: pointer"
            class="accent--text"
            @click="openTab('https://www.stix.social/utilities/privacy-policy')"
            >Privacy policy</u
          >.
        </p>
        <div class="login-buttons">
          <v-btn
            v-if="!email"
            @click="google"
            elevation="0"
            height="50"
            outlined
            block
            :style="{
              borderBottom: '3px solid #272727',
              borderLeft: '1px solid #272727',
              borderTop: '1px solid #272727',
              borderRight: '3px solid #272727',
            }"
          >
            <v-img
              src="@/assets/google-icon.png"
              contain
              max-width="40px"
            ></v-img>
            {{ mode === "signup" ? "Create account" : "Sign in" }} with Google
          </v-btn>
          <div class="or" v-if="!email">
            <hr />
            <span style="font-size: 12px"
              >OR SIGN {{ mode === "signup" ? "UP" : "IN" }} THROUGH EMAIL</span
            >
            <hr />
          </div>
          <div class="email">
            <p
              @click="email = ''"
              v-show="email"
              class="mb-0"
              style="font-size: 12px; cursor: pointer"
            >
              <v-icon x-small>mdi-chevron-left</v-icon
              ><u>Sign {{ mode === "signup" ? "up" : "in" }} with Google</u>
            </p>
            <v-text-field
              placeholder="Enter your email to continue.."
              flat
              solo
              hide-details=""
              :style="{
                borderBottom: '3px solid #272727',
                borderLeft: '1px solid #272727',
                borderTop: '1px solid #272727',
                borderRight: '3px solid #272727',
              }"
              style="border-radius: 8px"
              v-model="email"
            ></v-text-field>
            <div
              class="d-flex animate__animated animate__slideInRight"
              v-if="email && mode === 'signup'"
              style="gap: 10px"
            >
              <v-text-field
                placeholder="First name"
                flat
                solo
                hide-details=""
                :style="{
                  borderBottom: '3px solid #272727',
                  borderLeft: '1px solid #272727',
                  borderTop: '1px solid #272727',
                  borderRight: '3px solid #272727',
                }"
                style="border-radius: 8px"
                v-model="first_name"
              ></v-text-field>
              <v-text-field
                placeholder="Last name"
                flat
                solo
                hide-details=""
                :style="{
                  borderBottom: '3px solid #272727',
                  borderLeft: '1px solid #272727',
                  borderTop: '1px solid #272727',
                  borderRight: '3px solid #272727',
                }"
                style="border-radius: 8px"
                v-model="last_name"
              ></v-text-field>
            </div>
            <v-text-field
              v-if="email"
              class="animate__animated animate__slideInRight"
              placeholder="Create a password"
              flat
              solo
              hide-details=""
              :style="{
                borderBottom: '3px solid #272727',
                borderLeft: '1px solid #272727',
                borderTop: '1px solid #272727',
                borderRight: '3px solid #272727',
              }"
              style="border-radius: 8px"
              v-model="password"
              type="password"
            ></v-text-field>
            <p
              style="text-align: end; cursor: pointer"
              class="animate__animated animate__slideInRight"
              v-if="email && mode === 'signin'"
              @click="$router.push('/reset')"
            >
              <u>Reset password</u>
            </p>
            <v-checkbox
              v-if="email && mode === 'signup'"
              class="animate__animated animate__slideInRight my-0 py-0"
              v-model="newsletter"
              label="Subscribe to newsletter"
            ></v-checkbox>
            <v-btn
              elevation="0"
              color="primary"
              height="50"
              block
              :disabled="!email || !password"
              @click="mode === 'signup' ? signUp() : signIn()"
              >Continue</v-btn
            >
          </div>
        </div>
      </div>
    </div>
    <div class="button" v-if="!$vuetify.breakpoint.smAndDown"></div>
  </div>
</template>

<script>
import { db } from "@/firebase";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  getAdditionalUserInfo,
  signInWithEmailAndPassword,
} from "firebase/auth";
// import axios from "axios";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { mapState } from "vuex";
import axios from "axios";

export default {
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      userProfile: (state) => state.users.userProfile,
    }),
  },
  mounted() {
    if (this.$route.query.ref) {
      this.referral = this.$route.query.ref;
    }
    if (this.$route.query.mode) {
      this.mode = this.$route.query.mode;
    }
  },

  data() {
    return {
      email: "",
      first_name: "",
      last_name: "",
      join_workspace: {
        name: "",
        uid: "",
      },
      mode: "signup",
      newsletter: false,
      overlay: false,
      password: "",
      referral: null,
    };
  },
  methods: {
    sendInvites() {
      this.overlay = true;
      this.$store.commit("setLoading", { type: "general", val: true });
      setTimeout(wait, 3000);
      const vm = this;
      function wait() {
        vm.step = 1;
        this.overlay = false;
        this.$store.commit("setLoading", { type: "general", val: false });
        vm.$router.push("/");
      }
    },
    signIn() {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log(user);
        })
        .catch((error) => {
          if (error.code.includes("invalid-email")) {
            this.$store.commit("setNotification", {
              show: true,
              text: `There is no account found with this email.`,
              type: "error",
            });
          } else if (error.code.includes("wrong-password")) {
            this.$store.commit("setNotification", {
              show: true,
              text: "Wrong password! Please try again.",
              type: "error",
            });
          }
        });
    },
    signupWithEmail() {
      this.$store
        .dispatch("signupWithEmail", {
          email: this.email,
          password: this.password,
          last_name: this.last_name,
          first_name: this.first_name,
          role: "admin",
          workspace_name: this.workspace,
          job_title: this.job_title,
          company_size: this.company_size,
          main_use: this.main_use,
          invites: this.invites,
          keywords: this.keywords,
          timezone: this.timezone,
          country: this.country,
          referral: this.referral,
          newsletter: this.newsletter,
        })
        .catch((err) => {
          console.log(err);
          console.log("already in use");
          this.useError = true;
        });
    },
    signUp() {
      this.$store.commit("setLoading", { type: "general", val: true });
      this.$router.replace({
        name: "Verify",
        params: {
          email: this.email,
        },
      });
      this.$store.commit("setLoading", { type: "general", val: false });
    },
    async google() {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      const vm = this;
      signInWithPopup(auth, provider).then(async function (result) {
        vm.$store.commit("setLoading", { type: "general", val: true });
        const user = result.user;
        const details = getAdditionalUserInfo(result);
        vm.join_workspace.email = user.email;

        if (details.isNewUser) {
          console.log("Sign up: new user");
          let dataUri = null;
          if (process.env.NODE_ENV === "production") {
            const image = await axios.get(result.user.photoURL, {
              responseType: "arraybuffer",
            });
            const avatar = Buffer(image.data, "binary").toString("base64");
            var prefix = "data:" + image.headers["content-type"] + ";base64,";
            dataUri = prefix + avatar;
          }
          const name = result.user.displayName;
          const nameArray = name.split(" ");
          let firstName = nameArray[0];
          nameArray.splice(0, 1);

          // Add a new document in collection "users"
          await setDoc(doc(db, "users", user.uid), {
            first_name: firstName,
            last_name: nameArray.join(" "),
            name: name,
            uid: user.uid,
            avatar: dataUri,
            email: user.email,
            created_at: serverTimestamp(),
            last_login: serverTimestamp(),
            role: "admin",
            job_title: "",
            notifications: [],
            workspace: null,
            workspaces: [],
            permissions: {
              publish: true,
              create: true,
            },
            referrals: [],
            preferences: {
              feed: [],
              notifications: {
                newsletter: false,
                summary: true,
              },
              preview_size: "small",
            },
          });
        }
      });
    },
    openTab(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
@import "./styles.scss";
</style>
