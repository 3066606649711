<template>
  <div>
    <div class="top mb-4">
      <StructureCard ref="structure" />
      <v-text-field
        label="Search topics ..."
        :style="{
          borderBottom: '2px solid #272727',
          borderLeft: '0.75px solid #272727',
          borderTop: '0.75px solid #272727',
          borderRight: '2px solid #272727',
        }"
        flat
        solo
        dense
        hide-details=""
        class="mb-4"
        style="border-radius: 8px; max-width: 200px"
        v-model="search"
        clearable
      >
      </v-text-field>
    </div>
    <v-card
      max-width="700"
      flat
      :style="{
        borderBottom: '3px solid #272727',
        borderLeft: '1px solid #272727',
        borderTop: '1px solid #272727',
        borderRight: '3px solid #272727',
      }"
    >
      <div>
        <v-sheet
          class="d-flex justify-center align-center py-2"
          color="accent"
          style="
            font-size: 10pt;
            font-weight: 600;
            border-top-right-radius: 7px;
            border-top-left-radius: 7px;
          "
        >
          Structures
        </v-sheet>
        <v-divider class="primary"></v-divider>
      </div>
      <v-data-table
        style="max-width: 1200px"
        :headers="headers"
        :items="structures"
        mobile-breakpoint="100"
        :search="search"
        hide-default-header
        :items-per-page="5"
      >
        <template v-slot:[`item.tags`]="{ item }">
          <div class="d-flex flex-wrap" style="gap: 3px">
            <v-chip color="info" small v-for="(t, i) in item.tags" :key="i">{{
              t
            }}</v-chip>
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-menu transition="slide-y-transition" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small
                ><v-icon color="primary" small>mdi-dots-vertical</v-icon></v-btn
              >
            </template>
            <v-list>
              <v-list-item link class="px-4" @click="use(item)">
                <v-icon small color="primary" class="mr-3">mdi-send</v-icon>
                <v-list-item-title>Use</v-list-item-title>
              </v-list-item>

              <v-list-item link class="px-4" @click="edit(item)">
                <v-icon small color="primary" class="mr-3">mdi-pencil</v-icon>
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>

              <v-list-item link class="px-4" @click="deleteItem(item.uid)">
                <v-icon small color="primary" class="mr-3">mdi-delete</v-icon>
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import StructureCard from "@/components/StructureCard.vue";
import { mapState } from "vuex";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "@/firebase";

export default {
  components: {
    StructureCard,
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      workspace: (state) => state.workspace.workspace,
      loading: (state) => state.loading,
      structures: (state) => state.structures.structures,
    }),
  },
  data() {
    return {
      headers: [
        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "title",
        },
        {
          text: "Title",
          align: "end",
          sortable: false,
          value: "actions",
        },
      ],
      search: "",
    };
  },
  methods: {
    async deleteItem(uid) {
      let r = confirm("Are you sure?");
      if (r) {
        await deleteDoc(
          doc(db, "workspaces", this.workspace.uid, "structures", uid)
        );
        this.$store.dispatch("fetchStructures");
      }
    },
    edit(i) {
      const child = this.$refs.structure;
      child.edit(i);
    },
    use(i) {
      this.$router.replace({
        name: "Post",
        params: {
          structure: i.structure,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./styles.scss";
</style>
