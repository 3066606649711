<template>
  <div
    class="signup-container"
    :class="{
      mob: $vuetify.breakpoint.smAndDown,
      desk: !$vuetify.breakpoint.smAndDown,
    }"
  >
    <div class="left">
      <div class="logo">
        <v-img width="40" src="@/assets/logos/icon-dark.svg" contain></v-img>
        <b style="font-size: 18px">Stix</b>
      </div>

      <div
        class="sub-container form"
        :style="{
          width: $vuetify.breakpoint.smAndDown ? '80%' : '60%',
          paddingBottom: $vuetify.breakpoint.smAndDown ? '0' : '130px',
          marginTop: $vuetify.breakpoint.smAndDown ? '30px' : '130px',
        }"
      >
        <div v-if="userProfile && userProfile.workspace">
          <v-btn small class="mb-4" text @click="$router.push('/')"
            ><v-icon small class="mr-2">mdi-arrow-left</v-icon>Back to
            dashboard</v-btn
          >
        </div>
        <div class="steps">
          <v-btn
            outlined
            rounded
            elevation="0"
            small
            :style="{
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
              backgroundColor: '#D3F987',
            }"
          ></v-btn>
          <v-btn
            outlined
            small
            rounded
            elevation="0"
            :style="{
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
              backgroundColor: step > 1 ? '#D3F987' : '',
            }"
          ></v-btn>
          <v-btn
            small
            outlined
            rounded
            elevation="0"
            :style="{
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
              backgroundColor: step > 2 ? '#D3F987' : '',
            }"
          ></v-btn>
          <v-btn
            outlined
            small
            rounded
            elevation="0"
            :style="{
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
              backgroundColor: step > 3 ? '#D3F987' : '',
            }"
          ></v-btn>
          <v-btn
            outlined
            small
            rounded
            elevation="0"
            :style="{
              borderBottom: '2px solid #272727',
              borderLeft: '0.75px solid #272727',
              borderTop: '0.75px solid #272727',
              borderRight: '2px solid #272727',
              backgroundColor: step > 4 ? '#D3F987' : '',
            }"
          ></v-btn>
        </div>
        <v-window v-model="step">
          <v-window-item :value="1">
            <div>
              <h1
                :style="{
                  fontSize: $vuetify.breakpoint.smAndDown ? '35px' : '40px',
                }"
              >
                Let's create your workspace
              </h1>
              <p class="my-2">
                Your workspace is where you will create, review, publish and
                edit all your social media activities, together with your
                colleagues.
              </p>
              <v-text-field
                class="mb-2 mt-4"
                solo
                placeholder="Workspace name.."
                flat
                v-model="workspace"
                hide-details=""
                :style="{
                  borderBottom: '2px solid #272727',
                  borderLeft: '0.75px solid #272727',
                  borderTop: '0.75px solid #272727',
                  borderRight: '2px solid #272727',
                  borderRadius: '8px',
                }"
              ></v-text-field>

              <v-btn
                height="50"
                elevation="0"
                color="button"
                class="mt-4 px-6"
                @click="step = 2"
                :disabled="!workspace"
              >
                Continue
                <v-icon small class="ml-2">mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </v-window-item>

          <v-window-item :value="2">
            <div>
              <h1
                :style="{
                  fontSize: $vuetify.breakpoint.smAndDown ? '35px' : '40px',
                }"
              >
                How are you planning to use Stix?
              </h1>
              <div class="options mt-4">
                <v-card
                  class="px-6 pt-2 pb-5 animate__animated animate__slideInRight"
                  color="smoke"
                  flat
                  outlined
                >
                  <v-select
                    label="How big is your company?"
                    :items="sizes"
                    v-model="company_size"
                    hide-details=""
                    class="mb-4"
                  ></v-select>
                  <v-text-field
                    placeholder="e.g. Marketing Specialist"
                    label="What is your job title?"
                    v-model="job_title"
                    hide-details=""
                    class="mb-4"
                  ></v-text-field>
                  <v-text-field
                    label="What is your company's website?"
                    v-model="website"
                    hide-details=""
                  ></v-text-field>
                </v-card>
              </div>
              <v-btn
                height="50"
                elevation="0"
                color="button"
                class="mt-4 px-6"
                @click="step = 3"
                :disabled="!job_title || !website || !company_size"
              >
                Continue
                <v-icon small class="ml-2">mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </v-window-item>

          <v-window-item :value="3">
            <div>
              <h1
                :style="{
                  fontSize: $vuetify.breakpoint.smAndDown ? '35px' : '40px',
                }"
              >
                About {{ workspace }}
              </h1>
              <p style="font-size: 10px" class="mt-4 mb-2">
                Which country & timezone is your business located?
              </p>
              <div>
                <v-autocomplete
                  :menu-props="{ offsetY: true }"
                  label="Country"
                  v-model="country"
                  :items="sorted_countries"
                  item-text="name"
                  return-object
                  solo
                  hide-details=""
                  flat
                  background-color="smoke"
                  style="border-radius: 8px"
                  @change="checkTZ"
                ></v-autocomplete>
                <v-autocomplete
                  class="mt-2"
                  v-if="country && country.zones.length > 1"
                  :menu-props="{ offsetY: true }"
                  label="Timezone"
                  v-model="timezone"
                  :items="country.zones"
                  item-text="name"
                  solo
                  hide-details=""
                  flat
                  background-color="smoke"
                  style="border-radius: 8px"
                ></v-autocomplete>

                <div>
                  <p style="font-size: 10px" class="mt-4 mb-2">
                    A description of your company:
                  </p>
                  <v-textarea
                    class="mb-2"
                    solo
                    placeholder="A short description.."
                    flat
                    v-model="description"
                    hide-details=""
                    :style="{
                      borderBottom: '2px solid #272727',
                      borderLeft: '0.75px solid #272727',
                      borderTop: '0.75px solid #272727',
                      borderRight: '2px solid #272727',
                      borderRadius: '8px',
                    }"
                  ></v-textarea>
                </div>
              </div>
              <div>
                <v-btn
                  height="50"
                  elevation="0"
                  color="button"
                  class="mt-4 px-6"
                  @click="step = 4"
                >
                  Continue
                  <v-icon small class="ml-2">mdi-arrow-right</v-icon>
                </v-btn>
              </div>
            </div>
          </v-window-item>

          <v-window-item :value="4">
            <div>
              <h1
                :style="{
                  fontSize: $vuetify.breakpoint.smAndDown ? '35px' : '40px',
                }"
              >
                Help Stix AI help you
              </h1>

              <div>
                <p style="font-size: 10px" class="mt-4 mb-2">
                  A description of your services:
                </p>
                <v-textarea
                  class="mb-2"
                  solo
                  placeholder="Describe your products or service"
                  flat
                  v-model="product"
                  hide-details=""
                  :style="{
                    borderBottom: '2px solid #272727',
                    borderLeft: '0.75px solid #272727',
                    borderTop: '0.75px solid #272727',
                    borderRight: '2px solid #272727',
                    borderRadius: '8px',
                  }"
                ></v-textarea>
                <p style="font-size: 10px" class="mt-4 mb-2">
                  A description of your target audience:
                </p>
                <v-textarea
                  class="mb-2"
                  solo
                  placeholder="Describe your target audience"
                  flat
                  v-model="audience"
                  hide-details=""
                  :style="{
                    borderBottom: '2px solid #272727',
                    borderLeft: '0.75px solid #272727',
                    borderTop: '0.75px solid #272727',
                    borderRight: '2px solid #272727',
                    borderRadius: '8px',
                  }"
                ></v-textarea>
              </div>
              <div>
                <v-btn
                  height="50"
                  elevation="0"
                  color="button"
                  class="mt-4 px-6"
                  @click="step = 5"
                >
                  Continue
                  <v-icon small class="ml-2">mdi-arrow-right</v-icon>
                </v-btn>
              </div>
            </div></v-window-item
          >

          <v-window-item :value="5">
            <div>
              <h1
                :style="{
                  fontSize: $vuetify.breakpoint.smAndDown ? '35px' : '40px',
                }"
              >
                Let's invite your colleagues
              </h1>
              <div class="mt-2" v-for="(i, index) in invites" :key="index">
                <v-text-field
                  v-model="i.email"
                  class="mb-2 mt-2"
                  solo
                  placeholder="peter@yourcompany.com"
                  flat
                  hide-details=""
                  :style="{
                    borderBottom: '2px solid #272727',
                    borderLeft: '0.75px solid #272727',
                    borderTop: '0.75px solid #272727',
                    borderRight: '2px solid #272727',
                    borderRadius: '8px',
                  }"
                ></v-text-field>
              </div>

              <v-btn
                elevation="0"
                class="mb-4 mt-2"
                @click="invites.push({ email: '' })"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
              <div class="d-flex" style="gap: 10px">
                <v-btn
                  height="50"
                  elevation="0"
                  color="button"
                  class="mt-4 px-6"
                  :disabled="!invites[0].email"
                  @click="createWorkspace(false)"
                >
                  Invite & Continue
                  <v-icon small class="ml-2">mdi-arrow-right</v-icon>
                </v-btn>
                <v-btn
                  @click="createWorkspace(true)"
                  text
                  height="50"
                  class="mt-4"
                  >Skip & continue</v-btn
                >
              </div>
            </div>
          </v-window-item>
        </v-window>
      </div>
    </div>
    <div class="button" v-if="!$vuetify.breakpoint.smAndDown"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { timezones } from "@/mixins/timezones";

export default {
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      userProfile: (state) => state.users.userProfile,
    }),
    sorted_countries() {
      let list = this.countries;
      list.sort((a, b) => (a.name < b.name ? -1 : b.name < a.name ? 1 : 0));
      return list;
    },
  },
  mixins: [timezones],
  mounted() {},
  data() {
    return {
      company_size: "",
      country: null,
      description: "",
      audience: "",
      product: "",
      email: "",
      invites: [
        {
          email: "",
          role: "",
          invited: true,
        },
        {
          email: "",
          role: "",
          invited: true,
        },
      ],
      first_name: "",
      last_name: "",
      job_title: "",
      overlay: false,
      password: "",
      referral: null,
      reset_email: "",
      reset_sent: false,
      sizes: ["1-10", "11-25", "25-100", "100+"],
      step: 1,
      timezone: null,
      website: "",
      workspace: "",
    };
  },
  methods: {
    checkTZ(v) {
      if (v.zones.length === 1) {
        this.timezone = v.zones[0];
      }
    },
    async createWorkspace(skip) {
      this.overlay = true;
      let workspace = {
        invites: this.invites,
        suggestions: [],
        first_name: this.first_name,
        uid: this.userProfile.uid,
        company_size: this.company_size,
        timezone: this.timezone,
        country: this.country,
        type: "company",
        name: this.workspace,
        referral: this.referral,
        description: this.description,
        audience: this.audience,
        product: this.product,
        website: this.website,
        skip: skip,
      };
      await this.$store.dispatch("createWorkspace", workspace);
      this.overlay = false;
      this.$router.push("/");
    },
  },
};
</script>

<style scoped lang="scss">
.signup-container {
  height: 100vh;
  background-color: white;

  &.desk {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .left {
    display: grid;
    grid-template-rows: 100px auto;

    .logo {
      display: flex;
      gap: 8px;
      width: 100px;
      align-items: center;
      padding-left: 30px;
    }

    .sub-container {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      max-width: 450px;

      &.form {
        justify-content: flex-start;
      }

      &.reset {
        display: block;
      }

      &.signin {
        justify-content: center;
      }

      .steps {
        display: flex;
        gap: 10px;
        margin-bottom: 30px;
      }

      .login-buttons {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .or {
          display: grid;
          grid-template-columns: 1fr 220px 1fr;
          align-items: center;
          text-align: center;
          margin: 25px 0;
        }

        .email {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }
      }
      .sent {
        display: grid;
        grid-template-columns: auto 60px;
        grid-template-rows: 1fr 50px;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
.animate__animated.animate__slideInRight {
  --animate-duration: 0.5s;
}
</style>
