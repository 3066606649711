<template>
  <div>
    <v-menu
      nudge-width="300px"
      :close-on-content-click="false"
      offset-y
      z-index="990"
      v-model="menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon dark
          ><v-icon size="18">mdi-link</v-icon></v-btn
        >
      </template>

      <v-card flat width="300px">
        <div>
          <v-sheet
            class="px-6 d-flex justify-space-between align-center py-2"
            color="accent"
            style="
              font-size: 10pt;
              font-weight: 600;
              border-top-right-radius: 7px;
              border-top-left-radius: 7px;
            "
          >
            Add link
            <v-btn small icon elevation="0" @click="menu = false"
              ><v-icon small>mdi-close</v-icon></v-btn
            >
          </v-sheet>
          <v-divider class="primary"> </v-divider>
        </div>
        <v-window v-model="link_step">
          <v-window-item :value="0" :key="0">
            <div class="pa-2">
              <v-text-field
                placeholder="Paste link here.."
                flat
                solo
                hide-details=""
                background-color="smoke"
                style="border-radius: 8px"
                v-model="link"
                autofocus
                @keypress="enter"
              ></v-text-field>
              <v-switch
                label="Write post"
                class="pt-0 mt-2"
                hide-details=""
                v-model="write_post"
              ></v-switch>
            </div>
          </v-window-item>

          <v-window-item :value="2" class="linkone" :key="2">
            <p style="font-size: 12px" class="mb-1 pa-0">
              Fetching data from url..
            </p>

            <v-progress-linear
              indeterminate
              color="primary"
              style="width: 100px"
              rounded
            ></v-progress-linear>
          </v-window-item>

          <v-window-item :value="3" :key="3" class="linkone" @click="success">
            <p style="font-size: 12px" class="mb-1 pa-0">
              Link added succesfully
            </p>
            <v-icon large color="success darken-2">mdi-check-circle</v-icon>
          </v-window-item>

          <v-window-item :value="1" class="" :key="1">
            <div class="pa-2">
              <p style="font-size: 12px" class="mb-1 pa-0">Instructions:</p>
              <v-textarea
                placeholder="Use informative tone. Maximum 500 words. Structure like this... Etc."
                flat
                solo
                hide-details=""
                background-color="smoke"
                style="border-radius: 8px; font-size: 10pt"
                v-model="instructions"
                autofocus
                rows="3"
              ></v-textarea>
            </div>
          </v-window-item>
        </v-window>
        <v-divider class="my-2"></v-divider>

        <div class="px-2 pb-2 d-flex justify-end">
          <v-btn
            small
            elevation="0"
            :disabled="link === ''"
            color="button"
            @click="next()"
            >Continue</v-btn
          >
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase";
import { languages } from "@/mixins/languages";

export default {
  props: ["sheet", "link_preview"],
  mixins: [languages],
  data() {
    return {
      instructions: "",
      menu: false,
      link: "",
      link_step: 0,
      preview_loading: false,
      write_post: false,
    };
  },
  methods: {
    next() {
      if (this.link_step === 0 && this.write_post) {
        this.link_step = 1;
      } else {
        this.getPreview();
      }
    },
    enter(v) {
      if (v.code === "Enter") {
        this.next();
      }
    },
    async getPreview() {
      let prefix = "https://";
      let link = this.link;
      this.link_step = 2;
      if (this.link != "") {
        if (!link.includes("http")) {
          link = prefix.concat(this.link);
        }
        this.preview_loading = true;
        const getPreview = httpsCallable(functions, "getLinkPreview");
        const res = await getPreview({
          url: link,
        });
        if (res.data.error) {
          this.$store.commit("setNotification", {
            show: true,
            text: "Something went wrong. Try another URL.",
            type: "error",
            icon: "",
          });
        } else {
          let result = res.data;
          result.write_post = this.write_post;
          result.instructions = this.instructions;
          this.$emit("preview", result);
          this.menu = false;
        }

        this.preview_loading = false;
        this.link = "";
        this.link_step = 0;
        this.write_post = false;
      } else {
        this.$store.commit("setNotification", {
          show: true,
          text: "Incorrect link",
          type: "error",
          icon: "",
        });
      }
    },
    success() {
      this.link_step = 1;
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  border-radius: 10px;
}

.linkone {
  height: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
