var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subscription"},[_c('div',[(_vm.workspace && _vm.workspace.subscription_active)?_c('v-card',{staticClass:"mb-4",style:({
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }),attrs:{"flat":"","max-width":"500"}},[_c('div',[_c('v-sheet',{staticClass:"d-flex justify-center align-center py-2",staticStyle:{"font-size":"10pt","font-weight":"600","border-top-right-radius":"7px","border-top-left-radius":"7px"},attrs:{"color":"accent"}},[_vm._v(" Subscription ")]),_c('v-divider',{staticClass:"primary"})],1),_c('div',{staticClass:"d-flex align-center justify-space-between px-6 py-4"},[_c('div',[_c('h2',[_vm._v(" Subscription "),_c('v-chip',{staticClass:"ml-2 font-weight-regular",attrs:{"small":"","color":"success"}},[_vm._v("Active")])],1),_c('p',{staticClass:"ma-0 mt-1",staticStyle:{"font-size":"14px"}},[_vm._v(" You're currently on the paid plan. ")])]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":""}},[_vm._v("Cancel")])],1),(
            _vm.workspace.subscription_active &&
            (_vm.workspace.subscription.discount_code ||
              _vm.workspace.subscription.discount_value)
          )?_c('div',[_c('v-divider'),_c('div',{staticClass:"px-6 py-4"},[_c('p',{staticClass:"ma-0",staticStyle:{"font-size":"14px"}},[_c('b',[_vm._v("Discount"),(_vm.workspace.subscription.discount_code === 'STARTUP')?_c('span',[_vm._v(" Startup Program")]):_vm._e()]),_vm._v(": $"+_vm._s(_vm.workspace.subscription.discount_value)+" monthly: "),_c('span',{staticStyle:{"text-decoration":"line-through"}},[_vm._v("$89")]),_c('v-icon',{staticClass:"ml-1 pb-1",attrs:{"x-small":""}},[_vm._v("mdi-arrow-right")]),_vm._v(" $"+_vm._s(89 - _vm.workspace.subscription.discount_value)+" ")],1)])],1):_vm._e()]):_vm._e(),(_vm.workspace && !_vm.workspace.subscription_active)?_c('v-card',{staticClass:"mb-4",style:({
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }),attrs:{"flat":"","max-width":"500"}},[_c('v-sheet',{staticClass:"d-flex justify-center align-center py-2",staticStyle:{"font-size":"10pt","font-weight":"600","border-top-right-radius":"7px","border-top-left-radius":"7px"},attrs:{"color":"accent"}},[_vm._v(" Get your subscription ")]),_c('v-divider',{staticClass:"primary"}),_c('div',{staticClass:"py-8 px-10"},[_c('h1',{staticClass:"mb-2",staticStyle:{"font-size":"16pt","font-weight":"800"}},[_vm._v(" Early Adopter ")]),_c('p',{staticClass:"mb-0",staticStyle:{"font-size":"40pt","font-weight":"600","line-height":"100%"}},[_vm._v(" €25 ")]),_c('p',{staticStyle:{"font-size":"10pt","max-width":"80%"}},[_c('i',[_vm._v(" Monthly per Workspace license. Pricing will go up to €49 later in 2025.")])]),_c('ul',[_c('li',{staticStyle:{"font-size":"11pt"}},[_vm._v("Unlimited Users")]),_c('li',{staticStyle:{"font-size":"11pt"}},[_vm._v("Unlimited Accounts")]),_c('li',{staticStyle:{"font-size":"11pt"}},[_vm._v("Unlimited Schedules")]),_c('li',{staticStyle:{"font-size":"11pt"}},[_vm._v("140.000 Stix AI tokens")])]),_c('v-btn',{staticClass:"mt-4",attrs:{"block":"","elevation":"0","color":"button"}},[_vm._v("Purchase")])],1)],1):_vm._e(),(_vm.workspace && _vm.workspace.subscription_active)?_c('v-card',{staticClass:"mb-4",style:({
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }),attrs:{"flat":"","max-width":"500"}},[_c('div',[_c('v-sheet',{staticClass:"d-flex justify-center align-center py-2",staticStyle:{"font-size":"10pt","font-weight":"600","border-top-right-radius":"7px","border-top-left-radius":"7px"},attrs:{"color":"accent"}},[_vm._v(" Invoices ")]),_c('v-divider',{staticClass:"primary"})],1),_c('div',{staticStyle:{"width":"100%"}},[_c('v-data-table',{attrs:{"headers":_vm.headersSub,"items":_vm.itemsSub,"hide-default-header":"","mobile-breakpoint":"100","hide-default-footer":_vm.itemsSub.length > 0},scopedSlots:_vm._u([{key:`item.amount`,fn:function({ item }){return [_c('span',[_vm._v("$"+_vm._s(item.amount))])]}},{key:`item.status`,fn:function({ item }){return [_c('v-chip',{attrs:{"small":"","color":item.status === 'paid' ? 'success' : 'info'}},[_vm._v(_vm._s(_vm.capitalize(item.status)))])]}},{key:`item.actions`,fn:function({ item }){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.download(item)}}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-download ")])],1)]}}],null,true)}),_c('div',[_c('v-divider'),_c('div',{staticClass:"px-6 pb-2"},[_c('p',{staticClass:"mb-2 mt-4",staticStyle:{"font-size":"10px"}},[_vm._v("Send invoices to:")]),_c('v-text-field',{staticClass:"mb-2",staticStyle:{"border-radius":"8px","font-size":"10pt"},attrs:{"label":"invoices@yourcompany.com","hide-details":"","solo":"","dense":"","flat":"","background-color":"smoke"},on:{"keypress":_vm.enter},model:{value:(_vm.workspace.subscription.invoices_email),callback:function ($$v) {_vm.$set(_vm.workspace.subscription, "invoices_email", $$v)},expression:"workspace.subscription.invoices_email"}},[_c('template',{slot:"append"},[_c('v-btn',{attrs:{"color":"primary","small":"","elevation":"0"},on:{"click":_vm.updateEmail}},[_vm._v("Save")])],1)],2)],1)],1)],1)]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }