import Vue from "vue";
import VueRouter from "vue-router";
import { getAuth } from "firebase/auth";
const auth = getAuth();

// MAGNET
import Magnet from "@/views/magnet/magnetPage.vue";

// SIGN IN // SIGN UP
import Access from "@/views/access/AccessPage";
import SignupForm from "@/views/access/SignupForm.vue";
import Verify from "@/views/access/AccessVerify";
import Reset from "@/views/access/AccessReset";
import Join from "@/views/access/AccessJoin";
import AddWorkspace from "@/views/access/AccessWorkspace.vue";

// DEMO
import demo from "@/views/demo/social_demo.vue";
import demo_posts from "@/views/demo/demo_posts.vue";
import demo_offer from "@/views/demo/demo_offer.vue";
import demo_intro from "@/views/demo/demo_intro.vue";
import demo_topics from "@/views/demo/demo_topics.vue";
import demo_variants from "@/views/demo/demo_variants.vue";
import demo_scheduling from "@/views/demo/demo_scheduling.vue";

// WORKSPACE
import workspace from "@/views/settings/WorkspacePage.vue";
import settings from "@/views/settings/SettingsPage.vue";
import profile from "@/views/settings/SettingsProfile.vue";
import users from "@/views/settings/SettingsUsers.vue";
import subscription from "@/views/settings/SettingsSubscription.vue";
import workspace_profile from "@/views/settings/SettingsWorkspace.vue";
import referrals from "@/views/settings/SettingsReferrals.vue";
import stixAI from "@/views/settings/SettingsStixAI.vue";
import accounts from "@/views/settings/SettingsAccounts.vue";

// SOCIAL
import social_home from "@/views/home/social_home.vue";
import social_accounts from "@/views/accounts/social_accounts.vue";
import social_post from "@/views/post/social_post.vue";
import social_feed from "@/views/feed/social_feed.vue";
import social_schedule from "@/views/schedule/social_schedule.vue";
import social_categories from "@/views/categories/social_categories.vue";
import social_inbox from "@/views/inbox/social_inbox.vue";
import social_calendar from "@/views/calendar/social_calendar.vue";
import social_template from "@/views/template/social_template.vue";
import social_chat from "@/views/chat/social_chat.vue";

// LIBRARY
import social_library from "@/views/library/social_library.vue";
import library_posts from "@/views/library/posts/library_posts.vue";
import library_templates from "@/views/library/templates/library_templates.vue";
import library_topics from "@/views/library/topics/library_topics.vue";
import library_media from "@/views/library/media/library_media.vue";
import library_structures from "@/views/library/structures/library_structures.vue";

// ADMIN
import social_admin from "@/views/admin/SocialAdmin.vue";
import admin_users from "@/views/admin/users/AdminUsers.vue";
import admin_workspaces from "@/views/admin/workspaces/AdminWorkspaces.vue";
import admin_notifications from "@/views/admin/notifications/AdminNotifications.vue";
import admin_calendar from "@/views/admin/calendar/AdminCalendar.vue";
import admin_posts from "@/views/admin/posts/AdminPosts.vue";
import admin_settings from "@/views/admin/settings/AdminSettings.vue";
import admin_backlog from "@/views/admin/backlog/AdminBacklog.vue";
import admin_emails from "@/views/admin/emails/AdminEmails.vue";

// PUBLIC
import public_view from "@/views/public/public_view.vue";
import public_backlog from "@/views/public/backlog/public_backlog.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: social_home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/access",
    name: "Access",
    component: Access,
    meta: {
      noNav: true,
    },
  },
  {
    path: "/magnet",
    name: "Magnet",
    component: Magnet,
    meta: {
      noNav: true,
      requiresAuth: false,
    },
  },
  {
    path: "/demo",
    name: "Demo",
    component: demo,
    redirect: "/demo/posts",
    meta: {
      requiresAuth: false,
      noNav: true,
    },
    children: [
      {
        path: "/demo/posts",
        name: "Posts (demo)",
        component: demo_posts,
        meta: {
          requiresAuth: false,
          noNav: true,
        },
      },
      {
        path: "/demo/offer",
        name: "Offer (demo)",
        component: demo_offer,
        meta: {
          requiresAuth: false,
          noNav: true,
        },
      },
      {
        path: "/demo/intro",
        name: "Intro (demo)",
        component: demo_intro,
        meta: {
          requiresAuth: false,
          noNav: true,
        },
      },
      {
        path: "/demo/topics",
        name: "Generate topics (demo)",
        component: demo_topics,
        meta: {
          requiresAuth: false,
          noNav: true,
        },
      },
      {
        path: "/demo/variants",
        name: "Generate variants (demo)",
        component: demo_variants,
        meta: {
          requiresAuth: false,
          noNav: true,
        },
      },
      {
        path: "/demo/scheduling",
        name: "Scheduling (demo)",
        component: demo_scheduling,
        meta: {
          requiresAuth: false,
          noNav: true,
        },
      },
    ],
  },
  {
    path: "/verify",
    name: "Verify",
    component: Verify,
    meta: {
      noNav: true,
    },
  },
  {
    path: "/reset",
    name: "Reset",
    component: Reset,
    meta: {
      noNav: true,
    },
  },
  {
    path: "/join",
    name: "Join",
    component: Join,
    meta: {
      noNav: true,
    },
  },
  {
    path: "/create-workspace",
    name: "Create workspace",
    component: AddWorkspace,
    meta: {
      noNav: true,
    },
  },
  {
    path: "/waiting-list",
    name: "Waiting List Form",
    component: SignupForm,
    meta: {
      noNav: true,
    },
  },
  {
    path: "/workspace",
    name: "Workspace",
    component: workspace,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/library",
    name: "Library",
    component: social_library,
    redirect: "/library/posts",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/library/posts",
        name: "Posts",
        component: library_posts,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/library/templates",
        name: "Templates",
        component: library_templates,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/library/topics",
        name: "Topics",
        component: library_topics,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/library/media",
        name: "Media",
        component: library_media,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/library/structures",
        name: "Structures",
        component: library_structures,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/public",
    name: "Public",
    component: public_view,
    redirect: "/public/backlog",
    meta: {
      requiresAuth: false,
      noNav: true,
    },
    children: [
      {
        path: "/public/backlog",
        name: "Public Backlog",
        component: public_backlog,
        meta: {
          requiresAuth: false,
          noNav: true,
        },
      },
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    component: social_admin,
    redirect: "/admin/users",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/admin/users",
        name: "Admin - Users",
        component: admin_users,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/workspaces",
        name: "Admin - Workspaces",
        component: admin_workspaces,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/notifications",
        name: "Admin - Notifications",
        component: admin_notifications,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/calendar",
        name: "Admin - Calendar",
        component: admin_calendar,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/posts",
        name: "Admin - Posts",
        component: admin_posts,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/settings",
        name: "Admin - Settings",
        component: admin_settings,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/backlog",
        name: "Admin - Backlog",
        component: admin_backlog,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/emails",
        name: "Admin - Emails",
        component: admin_emails,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/settings",
    name: "Settings",
    component: settings,
    redirect: "/settings/profile",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/settings/profile",
        name: "Settings - Profile",
        component: profile,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/settings/accounts",
        name: "Settings - Accounts",
        component: accounts,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/settings/users",
        name: "Settings - Users",
        component: users,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/settings/subscription",
        name: "Settings - Subscription",
        component: subscription,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/settings/workspace",
        name: "Settings - Workspace",
        component: workspace_profile,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/settings/referrals",
        name: "Settings - Referrals",
        component: referrals,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/settings/stixai",
        name: "Settings - Stix AI",
        component: stixAI,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/accounts",
    name: "Accounts",
    component: social_accounts,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: social_calendar,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/post",
    name: "Post",
    component: social_post,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/feed",
    name: "Feed",
    component: social_feed,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/categories",
    name: "Categories",
    component: social_categories,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/chat",
    name: "Chat",
    component: social_chat,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/template/:id",
    name: "Edit template",
    component: social_template,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/schedule",
    name: "Schedule",
    component: social_schedule,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/inbox",
    name: "Inbox",
    component: social_inbox,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

  if (requiresAuth && !auth.currentUser) {
    next("/access");
  } else {
    next();
  }
});

export default router;
