<template>
  <div>
    <v-dialog :value="dialog" width="400">
      <v-card
        :style="{
          borderBottom: '2px solid #272727',
          borderRight: '2px solid #272727',
        }"
      >
        <div>
          <v-date-picker
            width="398"
            v-model="picker"
            color="primary"
          ></v-date-picker>
          <div
            v-if="picker"
            class="my-4 animate__animated animate__slideInLeft"
          >
            <p style="text-align: center" class="mb-1 font-weight-medium">
              Pick a time:
            </p>
            <div class="d-flex align-center justify-center" style="gap: 10px">
              <v-text-field
                style="max-width: 64px; border-radius: 8px"
                placeholder="10"
                type="number"
                solo
                hide-details=""
                background-color="smoke"
                flat
                v-model="hour"
              ></v-text-field>
              :
              <v-text-field
                style="max-width: 64px; border-radius: 8px"
                placeholder="00"
                type="number"
                solo
                hide-details=""
                background-color="smoke"
                flat
                v-model="minutes"
              ></v-text-field>
            </div>
          </div>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$emit('close')">Cancel</v-btn>
          <v-btn @click="schedule" color="primary" class="px-4" elevation="0">
            Schedule
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["dialog"],
  data() {
    return {
      hour: 8,
      minutes: 30,
      picker: null,
    };
  },
  methods: {
    setPicker(v) {
      this.picker = v.date;
      this.hour = v.hour;
      this.minutes = v.minutes;
    },
    schedule() {
      let year = this.picker.split("-")[0];
      let month = this.picker.split("-")[1];
      let day = this.picker.split("-")[2];
      let date = {
        day: Number(day),
        month: Number(month),
        year: Number(year),
        minutes: Number(this.minutes),
        hour: Number(this.hour),
      };

      this.$emit("schedule", date);
    },
  },
};
</script>

<style lang="scss" scoped>
.animate__animated.animate__slideInLeft {
  --animate-duration: 0.5s;
}
</style>
