<template>
  <div
    :class="{
      desktop_container: !$vuetify.breakpoint.smAndDown,
    }"
  >
    <EditTemplate
      :dialog="template_dialog"
      :template="media"
      @result="
        (r) => {
          media.thumbnail = r;
          template_dialog = false;
        }
      "
    />

    <StructuresBrowser
      :drawer="show_structures"
      @structure="
        (v) => {
          structure = v;
          show_structures = false;
        }
      "
      @close="show_structures = false"
    />

    <ConfirmDialog
      text="Are you sure?"
      :dialog="confirm_dialog"
      @confirm="confirmResult"
    />
    <ConfirmDialog
      text="Adding media will remove the link preview. Continue?"
      :dialog="remove_link"
      @confirm="removeLink"
    />
    <SchedulePost
      :dialog="dialog"
      @close="dialog = false"
      @schedule="schedule"
      ref="schedulepost"
    />

    <v-dialog v-model="dialog2" width="500">
      <v-card color="primary">
        <v-card-title class="text-h5 white--text">
          Oops, something's missing..
        </v-card-title>

        <v-card-text class="mt-3 white--text">
          <ul>
            <li v-for="(m, index) in complete.missing" :key="index">
              {{ m }}
            </li>
          </ul>
          <p class="mb-0 mt-4 white--text">
            Or save the post as draft and finish later.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="button" @click="dialog2 = false"> Continue </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="post-container">
      <div class="newcontent">
        <h1 class="mb-4 d-flex justify-space-between align-center">
          {{ post ? "Edit" : "New" }} post

          <div>
            <v-chip
              class="font-weight-regular"
              v-if="schedule_date && workspace"
              >Scheduled
              {{ formatDate(schedule_date) }}
              <v-tooltip right color="primary" max-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2"
                    small
                    @click="
                      dialog = true;
                      changing_schedule = true;
                    "
                    v-bind="attrs"
                    v-on="on"
                    icon
                  >
                    <v-icon small dark> mdi-autorenew </v-icon>
                  </v-btn>
                </template>
                <div>
                  <!-- <p class="white--text font-weight-bold mb-1">
                    Change the scheduled date
                  </p> -->
                  <p style="font-size: 12px" class="white--text mb-0">
                    Change the scheduled date
                  </p>
                </div>
              </v-tooltip>

              <v-tooltip right color="primary" max-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="schedule_date = null"
                    v-bind="attrs"
                    v-on="on"
                    icon
                    small
                  >
                    <v-icon small dark> mdi-delete-outline </v-icon>
                  </v-btn>
                </template>
                <div>
                  <!-- <p class="white--text font-weight-bold mb-1">
                    Remove the scheduled date
                  </p> -->
                  <p style="font-size: 12px" class="white--text mb-0">
                    Remove the scheduled date
                  </p>
                </div>
              </v-tooltip>
            </v-chip>

            <v-chip
              small
              class="mt-1 ml-4"
              :color="status === 'draft' ? 'info' : 'button'"
              >{{ status }}</v-chip
            >
          </div>
        </h1>
        <div
          class="topbar"
          style="gap: 10px"
          :class="{
            mobile: $vuetify.breakpoint.smAndDown,
            desktop: !$vuetify.breakpoint.smAndDown,
          }"
        >
          <div>
            <v-select
              label="Recurring"
              v-model="recurring"
              no-data-text="No categories setup"
              item-color="button darken-2"
              solo
              flat
              dense
              style="border-radius: 8px; font-size: 10pt"
              item-text="text"
              item-value="value"
              :items="[
                {
                  text: 'Recurring',
                  value: true,
                },
                {
                  text: 'One-time',
                  value: false,
                },
              ]"
              hide-details=""
              :style="{
                borderBottom: '3px solid #272727',
                borderLeft: '1px solid #272727',
                borderTop: '1px solid #272727',
                borderRight: '3px solid #272727',
              }"
              :menu-props="{ offsetY: true }"
            >
              <template v-slot:item="{ item }">
                <div
                  class="d-flex align-center"
                  style="width: 100%; font-size: 13px"
                >
                  <v-icon
                    small
                    class="mr-2"
                    v-if="item.value"
                    color="button darken-2"
                    >mdi-leaf</v-icon
                  >
                  <v-icon
                    small
                    class="mr-2"
                    v-if="!item.value"
                    color="smoke darken-2"
                    >mdi-numeric-1-circle</v-icon
                  >
                  {{ item.text }}
                </div>
              </template>
              <template v-slot:selection="{ item }">
                <div class="d-flex align-center" style="width: 100%">
                  <v-icon
                    small
                    class="mr-2"
                    v-if="item.value"
                    color="button darken-2"
                    >mdi-leaf</v-icon
                  >
                  <v-icon
                    small
                    class="mr-2"
                    v-if="!item.value"
                    color="smoke darken-2"
                    >mdi-numeric-1-circle</v-icon
                  >
                  {{ item.text }}
                </div>
              </template>
            </v-select>
          </div>

          <div>
            <CategorySelect :category.sync="category" />
          </div>

          <div>
            <AccountSelect :selected_accounts.sync="selected_accounts" />
          </div>
        </div>

        <div
          style="
            position: relative;
            display: grid;
            grid-template-columns: 45px auto;
          "
        >
          <div style="margin-top: 70px">
            <div
              class="primary d-flex flex-column align-center py-1 px-1"
              style="border-radius: 10px 0 0 10px; gap: 5px"
            >
              <emoji-picker @emoji="insert" :search="search">
                <v-btn
                  icon
                  dark
                  id="emoji-button"
                  slot="emoji-invoker"
                  slot-scope="{ events: { click: clickEvent } }"
                  @click.stop="clickEvent"
                  ><v-icon size="18"
                    >mdi-emoticon-excited-outline</v-icon
                  ></v-btn
                >

                <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                  <v-dialog :value="true" max-width="420">
                    <div>
                      <v-sheet
                        class="d-flex justify-center align-center py-2"
                        color="accent"
                        style="
                          font-size: 10pt;
                          font-weight: 600;
                          border-top-right-radius: 7px;
                          border-top-left-radius: 7px;
                        "
                      >
                        Emoji picker
                      </v-sheet>
                      <v-divider class="primary"></v-divider>
                    </div>
                    <div
                      class="white pa-4 overflow-auto"
                      style="max-width: 100%; max-height: 400px"
                      :style="{
                        borderBottom: '3px solid #272727',
                        borderLeft: '1px solid #272727',
                        borderTop: '1px solid #272727',
                        borderRight: '3px solid #272727',
                      }"
                    >
                      <div
                        v-for="(emojiGroup, category) in emojis"
                        :key="category"
                        class="mb-4"
                      >
                        <h5>{{ category }}</h5>
                        <div class="d-flex flex-wrap" style="gap: 3px">
                          <div
                            style="cursor: default; font-size: 40px"
                            v-for="(emoji, emojiName) in emojiGroup"
                            :key="emojiName"
                            @click="insert(emoji)"
                            :title="emojiName"
                          >
                            {{ emoji }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-dialog>
                </div>
              </emoji-picker>
              <LinkDesktop
                @preview="setPreview"
                @open="sheet = true"
                @close="sheet = false"
                @remove="
                  link_preview = null;
                  topic = '';
                  sheet = false;
                "
                :sheet="sheet"
                :link_preview="link_preview"
                v-if="!$vuetify.breakpoint.smAndDown"
              />
              <div>
                <v-btn
                  v-if="media && media.length === 0"
                  icon
                  dark
                  @click="$store.commit('setMediumType', `post`)"
                >
                  <v-icon size="18">mdi-image-plus-outline</v-icon>
                </v-btn>
                <v-menu
                  v-else-if="media"
                  v-model="menu"
                  :close-on-content-click="true"
                  :nudge-width="150"
                  offset-x
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark v-bind="attrs" v-on="on" icon>
                      <v-icon size="18">mdi-image-plus</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-list dense>
                      <v-list-item
                        v-if="
                          media &&
                          media.length > 0 &&
                          media[0].type === 'template'
                        "
                        dense
                        @click="template_dialog = true"
                        >Edit template
                      </v-list-item>

                      <v-list-item @click="openMedia">
                        <v-icon small class="mr-2">mdi-sync</v-icon> Replace
                        {{ media[0].type }}
                      </v-list-item>
                      <v-list-item dense @click="media = []">
                        <v-icon small class="mr-2">mdi-delete</v-icon>
                        Remove {{ media[0].type }}
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </div>
            </div>
          </div>
          <div class="my-4">
            <div class="d-flex">
              <div class="d-flex align-center" style="gap: 5px">
                <v-btn
                  small
                  :disabled="tab === 0"
                  @click="tab = tab - 1"
                  text
                  icon
                  ><v-icon>mdi-chevron-left</v-icon></v-btn
                >
                <p class="mb-0 pb-0" style="font-size: 9pt; opacity: 0.7">
                  {{ tab + 1 }}/{{ variations.length }}
                </p>
                <v-btn
                  small
                  :disabled="tab === variations.length - 1"
                  @click="tab++"
                  text
                  icon
                  ><v-icon>mdi-chevron-right</v-icon></v-btn
                >
              </div>
              <v-spacer></v-spacer>

              <v-menu
                v-if="recurring != false"
                transition="slide-y-transition"
                bottom
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon small
                    ><v-icon size="20" color="primary"
                      >mdi-note-plus-outline</v-icon
                    ></v-btn
                  >
                </template>
                <v-list>
                  <div>
                    <v-list-item dense link class="px-4" @click="addVariation">
                      <v-list-item-icon>
                        <v-icon size="20" color="primary"
                          >mdi-note-outline</v-icon
                        >
                      </v-list-item-icon>
                      <v-list-item-title>Add empty variation</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      dense
                      link
                      class="px-4"
                      @click="generateVariation"
                    >
                      <v-list-item-icon>
                        <v-img
                          max-width="20"
                          height="20"
                          style="color: #272727"
                          src="@/assets/icons/openai.svg"
                        ></v-img>
                      </v-list-item-icon>
                      <v-list-item-title>
                        Generate variation
                      </v-list-item-title>
                    </v-list-item>
                  </div>
                </v-list>
              </v-menu>
              <div class="ml-4">
                <v-menu transition="slide-y-transition" bottom offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small icon v-bind="attrs" v-on="on"
                      ><v-icon color="primary" size="20"
                        >mdi-delete-outline</v-icon
                      ></v-btn
                    >
                  </template>
                  <v-list>
                    <div v-for="(i, index) in variations" :key="index">
                      <v-list-item
                        dense
                        link
                        class="px-4"
                        @click="removeVariation(index)"
                      >
                        <v-list-item-title> {{ index + 1 }}</v-list-item-title>
                      </v-list-item>
                    </div>
                  </v-list>
                </v-menu>
              </div>
            </div>

            <v-tabs-items
              v-model="tab"
              :style="{
                borderBottom: '3px solid #272727',
                borderLeft: '1px solid #272727',
                borderTop: '1px solid #272727',
                borderRight: '3px solid #272727',
              }"
              style="border-radius: 8px; z-index: 2"
              class="mb-2"
            >
              <v-tab-item v-for="(item, index) in variations" :key="index">
                <div style="position: relative">
                  <v-textarea
                    v-model="item.text"
                    solo
                    rows="8"
                    autofocus
                    flat
                    style="font-size: 10pt"
                    hide-details=""
                    placeholder="Your post here.."
                    :disabled="item.loading"
                    :ref="`textarea-${index}`"
                  ></v-textarea>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
        <div>
          <AskChat
            ref="askChat"
            @translate="translate"
            @anything="askAnything"
            @complete="completePost"
            @write="writePost"
            @spelling="spellingCheck"
            @topics="$store.commit('setTopicsShow', true)"
            @structures="show_structures = !show_structures"
            :empty="!variations[tab].text"
            :topic.sync="topic"
            :instructions.sync="instructions"
          />
          <div class="d-flex my-4 align-center" style="gap: 7px">
            <!-- <v-switch
              label="Comment"
              color="primary"
              v-model="show_comment"
            ></v-switch> -->
            <v-spacer></v-spacer>
            <v-btn small elevation="0" text @click="confirm">{{
              post ? "Start new post" : "Clear"
            }}</v-btn>

            <div>
              <v-menu transition="slide-x-transition" bottom offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    :style="{
                      borderBottom: '3px solid #272727',
                      borderLeft: '1px solid #272727',
                      borderTop: '1px solid #272727',
                      borderRight: '3px solid #272727',
                      borderRadius: '8px',
                    }"
                  >
                    <v-btn
                      :width="$vuetify.breakpoint.smAndDown ? ' 48%' : ''"
                      :style="{
                        'font-size': $vuetify.breakpoint.smAndDown
                          ? '12px'
                          : '',
                      }"
                      style="min-width: 130px"
                      v-bind="attrs"
                      v-on="on"
                      color="button"
                      small
                      elevation="0"
                      ><span class="pl-4">Continue</span
                      ><v-icon id="chevron" class="ml-4"
                        >mdi-chevron-down</v-icon
                      >
                    </v-btn>
                  </div>
                </template>
                <!-- TODO remove button 2 and 3 if user does not have permissions to publish, add another button 'request publish'  -->
                <v-list>
                  <!-- IF EXISTING POST -->
                  <div v-if="post">
                    <v-list-item
                      @click="add(schedule_date, status)"
                      link
                      class="px-4"
                    >
                      <v-list-item-title>Save changes</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="add(null, 'active')"
                      link
                      class="px-4"
                      v-if="!schedule_date && status === 'draft'"
                    >
                      <v-list-item-title
                        >Automatic scheduling</v-list-item-title
                      >
                    </v-list-item>

                    <v-list-item
                      v-if="post && status != 'draft'"
                      link
                      class="px-4"
                      @click="add(null, 'draft')"
                    >
                      <v-list-item-title>Save as draft</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      v-if="!schedule_date"
                      link
                      class="px-4"
                      @click="add('now', 'active')"
                    >
                      <v-list-item-title>Publish now</v-list-item-title>
                    </v-list-item>
                    <v-list-item link class="px-4" @click="dialog = true">
                      <v-list-item-title>Publish later</v-list-item-title>
                    </v-list-item>
                  </div>

                  <!-- IF NEW -->
                  <div v-else>
                    <v-list-item
                      link
                      class="px-4"
                      @click="add(schedule_date, 'draft')"
                    >
                      <v-list-item-title>Save as draft</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="add(null, 'active')" link class="px-4">
                      <v-list-item-title
                        >Automatic scheduling</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      link
                      class="px-4"
                      @click="add('now', 'active')"
                    >
                      <v-list-item-title>Publish now </v-list-item-title>
                    </v-list-item>
                    <v-list-item link class="px-4" @click="dialog = true">
                      <v-list-item-title>Publish later</v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item link class="px-4" @click="add(null, 'review')">
                    <v-list-item-title>Save for review</v-list-item-title>
                  </v-list-item> -->
                  </div>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>

        <div
          v-if="show_comment"
          class="animate__animated animate__slideInLeft mt-2"
        >
          <div class="mb-2 d-flex justify-space-between">
            <h3>Comment</h3>
            <v-btn
              @click="
                comment = '';
                show_comment = false;
              "
              small
              outlined
              elevation="0"
              icon
              ><v-icon small color="primary">mdi-delete</v-icon></v-btn
            >
          </div>
          <v-textarea
            style="border-radius: 8px"
            v-model="comment"
            solo
            rows="2"
            class="ma-0 pa-0"
            flat
            :style="{
              borderBottom: '3px solid #272727',
              borderLeft: '1px solid #272727',
              borderTop: '1px solid #272727',
              borderRight: '3px solid #272727',
            }"
            hide-details=""
            placeholder="Your message here.."
          ></v-textarea>
        </div>
      </div>

      <div
        v-if="
          selected_accounts.length > 0 ||
          this.variations[0].text ||
          (this.media && this.media.length > 0)
        "
        class="mb-10"
      >
        <v-card
          :style="{
            borderBottom: '3px solid #272727',
            borderLeft: '1px solid #272727',
            borderTop: '1px solid #272727',
            borderRight: '3px solid #272727',
          }"
          flat
          max-width="700"
          class="px-8 pt-4 primary animate__animated animate__slideInRight"
        >
          <PreviewContent
            :channels="accounts"
            :message="preview_message"
            :media="media"
            :comment="comment"
            :preview="link_preview"
            :selected_accounts="selected_accounts"
            :variation="variations[tab]"
            @delete_image="media = null"
            @alt="altImage"
          />
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import { collection, addDoc, updateDoc, doc } from "firebase/firestore";
import { db } from "@/firebase";
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase";
import SchedulePost from "@/components/SchedulePostDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import PreviewContent from "@/components/preview/previewContent.vue";
import LinkDesktop from "@/components/media/LinkDesktop.vue";
import AccountSelect from "@/components/selects/accountSelect.vue";
import CategorySelect from "@/components/selects/categorySelect.vue";
import { DateTime } from "luxon";
import AskChat from "@/components/openai/AskChat.vue";
import { post } from "@/mixins/post";
import { languages } from "@/mixins/languages";
import StructuresBrowser from "@/components/structures/structuresBrowser.vue";
import EditTemplate from "@/components/templates/EditTemplate.vue";

export default {
  mixins: [post, languages],
  components: {
    EditTemplate,
    CategorySelect,
    AccountSelect,
    SchedulePost,
    ConfirmDialog,
    PreviewContent,
    LinkDesktop,
    AskChat,
    StructuresBrowser,
  },
  watch: {
    "userProfile.workspace": {
      deep: true,
      async handler() {
        if (this.$route.query.id) {
          this.loadPost(this.$route.query.id);
        }
      },
    },
  },
  mounted() {
    if (this.$route.query.category) {
      this.category = this.categories.find(
        (c) => c.uid === this.$route.query.category
      );
    }
    if (this.$route.params.topic) {
      this.topic = this.$route.params.topic;
      this.$refs.askChat.write();
    }
    if (this.$route.params.structure) {
      this.structure = this.$route.params.structure;
      this.$refs.askChat.write();
    }

    if (this.$route.query.scheduled) {
      this.clearPost;
      this.schedule_date = this.$route.query.scheduled;
      const child = this.$refs.schedulepost;
      const dateTime = DateTime.fromMillis(this.$route.query.scheduled * 1000);
      child.setPicker({
        date: dateTime.toFormat("yyyy-MM-dd"),
        hour: dateTime.toFormat("HH"),
        minutes: dateTime.toFormat("mm"),
      });
    }
    if (
      this.$route.query.id &&
      this.userProfile &&
      this.userProfile.workspace
    ) {
      this.loadPost(this.$route.query.id);
    }
  },
  data() {
    return {
      alt: 1,
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 0,
        outlined: true,
        width: "500px",
      },
      assistant: false,
      chatGPT: false,
      chatgpt_modus: null,
      changing_schedule: false,
      complete: {
        pass: false,
        missing: [],
      },
      confirm_dialog: false,
      dialog2: false,
      image: "",
      menu: false,
      link_step: 0,
      posting: false,
      preview: "facebook",
      preview_loading: false,
      prompt: "",
      remove_link: false,
      search: "",
      sheet: false,
      show_structures: false,
      tab: 0,
      template_dialog: false,
      translating: false,
      topics_drawer: false,
    };
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      accounts: (state) => state.accounts,
      categories: (state) => state.posts.categories,
      posts: (state) => state.posts.posts,
      workspace: (state) => state.workspace.workspace,
    }),
    preview_message() {
      const m = this.variations[this.tab].text.replace(/\n/g, "<br />");
      return m;
    },
    accounts_uids() {
      let list = [];
      for (let x in this.selected_accounts) {
        list.push(this.selected_accounts[x].id);
      }
      return list;
    },
  },
  methods: {
    async add(date, status) {
      const complete = await this.checkPost();
      if (!complete.pass && status != "draft") {
        this.dialog2 = true;
        return;
      }

      if (date === "now") {
        let dt = DateTime.now();
        date = {
          day: dt.day,
          month: dt.month,
          year: dt.year,
          hour: dt.hour,
          minutes: dt.minute,
        };
      }

      this.$store.commit("setLoading", { type: "general", val: true });
      let post = {
        accounts: this.accounts_uids,
        category: this.category ? this.category.uid : null,
        created: Math.floor(Date.now() / 1000),
        created_by: this.userProfile.uid,
        created_by_email: this.userProfile.email,
        recurring: this.recurring,
        scheduled: date != null,
        schedule_date: date ? date : null,
        status: status,
        media: this.media,
        comment: this.comment,
        workspace: this.userProfile.workspace,
        variations: this.variations,
        uid: this.post ? this.post : null,
        timezone: this.workspace.timezone,
      };

      if (this.post) {
        // IF EXISTING POST, THEN UPDATE THE POST
        const updatePost = httpsCallable(functions, "postUpdate");
        await updatePost(post);

        this.$store.dispatch("refreshPost", this.post);

        this.$store.commit("setNotification", {
          show: true,
          text: "Your post has been updated!",
          type: "primary",
        });
      } else {
        // IF NEW POST, ADD POST TO DB
        const addPost = httpsCallable(functions, "postAdd");
        await addPost(post);
        this.$store.dispatch("fetchPosts");
        this.$store.commit("setNotification", {
          show: true,
          text: "Your post has been added!",
          type: "primary",
        });
      }

      this.$store.dispatch("getCalendarDays");
      this.$store.dispatch("fetchMedia");
      this.$store.commit("clearPost");
      this.$store.commit("setLoading", { type: "general", val: false });
      this.$router.push("/library/posts");
    },
    addVariation() {
      this.variations.push({
        text: "",
        type: "variation",
        loading: false,
        uid: null,
        created_at: null,
      });
      const vm = this;
      setTimeout(() => {
        vm.tab = vm.variations.length - 1;
      }, 150);
    },
    altImage() {
      this.media[0].src = this.media[0].alt[this.alt];
      if (this.alt === this.media[0].alt.length - 1) {
        this.alt = 0;
      } else {
        this.alt++;
      }
    },
    checkPost() {
      return new Promise((resolve) => {
        let complete = {
          pass: true,
          missing: [],
        };

        if (this.selected_accounts.length === 0) {
          complete.pass = false;
          complete.missing.push(
            "Please select at least one social media account"
          );
        }
        if (!this.category) {
          complete.pass = false;
          complete.missing.push("Please select a category");
        }
        if (this.recurring === null) {
          complete.pass = false;
          complete.missing.push("Please select if this post can be recurring.");
        }
        if (
          !this.media &&
          this.selected_accounts.find((a) => a.type === "instagram") !=
            undefined
        ) {
          complete.pass = false;
          complete.missing.push(
            "Please add an image. Instagram posts require an image, or remove the Instagram accounts from the list."
          );
        }

        this.complete = complete;

        resolve(complete);
      });
    },
    clearPost() {
      if (this.post) {
        this.$router.replace("/post");
      }
      this.$refs.askChat.clear();
      this.$store.commit("clearPost");
    },

    confirmResult(v) {
      this.confirm_dialog = false;
      if (v) {
        this.clearPost();
      }
    },
    confirm() {
      this.confirm_dialog = true;
    },
    enter(v) {
      if (v.code === "Enter") {
        this.link_step = 1;
        this.getPreview();
      }
    },
    formatDate(t) {
      let day = DateTime.fromMillis(t * 1000);
      return day.toFormat("ff");
    },
    insert(emoji) {
      const ref =
        this.$refs[`textarea-${this.tab}`][0].$el.querySelector("textarea");

      const cursor = ref.selectionStart;
      const start = this.variations[this.tab].text.substring(0, cursor);
      const end = this.variations[this.tab].text.substring(cursor);
      const text = start + emoji + end;

      this.variations[this.tab].text = text;
    },
    async loadPost(uid) {
      this.$store.commit("clearPost");
      this.post = uid;
      const docRef = doc(
        db,
        "workspaces",
        this.userProfile.workspace,
        "posts",
        uid
      );
      const docSnap = await getDoc(docRef);
      let p = docSnap.data();

      p.variations = [];
      const messages = await getDocs(
        collection(
          db,
          "workspaces",
          this.userProfile.workspace,
          "posts",
          uid,
          "variations"
        )
      );
      messages.forEach((d) => {
        p.variations.push(d.data());
      });

      this.category = this.categories.find((c) => c.uid === p.category);
      for (let x in p.accounts) {
        const docSnap = await getDoc(
          doc(
            db,
            "workspaces",
            this.userProfile.workspace,
            "accounts",
            p.accounts[x]
          )
        );

        if (docSnap.exists()) {
          this.selected_accounts.push(docSnap.data());
        } else {
          console.log("No such document!");
        }
      }

      this.message = p.message;
      this.variations = p.variations;
      this.comment = p.comment;
      if (p.comment) {
        this.show_comment = true;
      }
      this.media = p.media;
      this.recurring = p.recurring;
      console.log(p.schedule_date);
      this.schedule_date = p.schedule_date ? p.schedule_date : null;
      this.status = p.status;
    },
    openMedia() {
      if (this.link_preview) {
        this.remove_link = true;
      } else {
        this.menu = false;
        this.$store.commit("setMediumType", `post`);
      }
    },
    removeLink(v) {
      if (v) {
        this.link_preview = null;
        this.$store.commit("setMediumType", `post`);
        this.topic = "";
        this.$refs.askChat.clear();
      }
      this.remove_link = false;
    },
    removeVariation(i) {
      this.variations.splice(i, 1);
      if (this.variations.length === 0) {
        this.variations.push({
          text: "",
          type: "main",
          loading: false,
          uid: null,
          created_at: null,
        });
      }
      if (this.tab === i || this.tab >= this.variations.length) {
        this.tab = this.variations.length - 1;
      }
    },
    schedule(v) {
      this.dialog = false;
      const now = DateTime.now();
      let day = DateTime.fromObject(
        {
          day: v.day,
          month: v.month,
          year: v.year,
          hour: v.hour,
          minute: v.minutes,
        },
        { zone: this.workspace.timezone }
      );

      if (day > now && !this.changing_schedule) {
        this.add(day.toSeconds(), "active");
      } else if (this.changing_schedule && day > now) {
        this.schedule_date = day.toSeconds();
        this.changing_schedule = false;
      } else {
        this.changing_schedule = false;
        this.dialog = true;
        this.$store.commit("setNotification", {
          show: true,
          text: "Scheduled time is in the past!",
          type: "error",
        });
      }
    },
    selectImage(v) {
      this.image = v.src.large2x;
    },
    selectPage(v) {
      if (this.channels.find((c) => c.id === v.id)) {
        this.channels.splice(
          this.channels.findIndex((c) => c.id === v.id),
          1
        );
      } else {
        this.channels.push(v);
      }
    },
    setPreview(v) {
      this.media = [
        {
          alt: v.images,
          type: "url",
          url: v.url,
          src: v.image,
          description: v.description,
          language: v.language,
          title: v.title,
        },
      ];
      this.sheet = false;

      if (v.write_post) {
        console.log("Converting to post");

        let lang = this.languages.find((l) => l.code === v.language).name;
        let instructions = `Please carefully follow these instructions: ${v.instructions}. `;
        this.linkPost(lang, instructions, v.content, v.url);
      } else {
        this.topic = v.content;
      }
    },
    setTemplate() {
      return new Promise((resolve) => {
        const getTemplate = httpsCallable(functions, "getTemplate");
        let url =
          "https://www.nu.nl/formule-1/6300370/red-bull-onderzoekt-mogelijk-grensoverschrijdend-gedrag-teambaas-horner.html";

        const res = getTemplate({
          url: url,
        });
        console.log(res.data);
        resolve();
      });
    },
    useContent(v, t) {
      return new Promise((resolve) => {
        console.log(v);
        let x = this.variations.find((v) => v.timestamp === t);

        if (!v.includes(" ")) {
          x.text = v;
          resolve();
        } else {
          let chunks = v.split(" ");

          x.text = "";
          for (let i = 0; i <= chunks.length - 1; i++) {
            setTimeout(function () {
              let space = i === 0 ? "" : " ";
              x.text = x.text.concat(space, chunks[i]);
              if (i + 1 === chunks.length) {
                x.timestamp = null;
                resolve();
              }
            }, 50 * i);
          }
        }

        this.topics_drawer = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./styles.scss";
</style>
