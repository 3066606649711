<template>
  <v-select
    :menu-props="{ offsetY: true }"
    multiple
    :items="accounts"
    item-color="button darken-2"
    item-text="id"
    return-object
    :value="selected_accounts"
    @input="sync"
    :loading="loading.accounts"
    :style="{
      borderBottom: '3px solid #272727',
      borderLeft: '1px solid #272727',
      borderTop: '1px solid #272727',
      borderRight: '3px solid #272727',
    }"
    dense
    solo
    flat
    style="border-radius: 8px; font-size: 10pt"
    label="Accounts"
    hide-details=""
    :no-data-text="loading.accounts ? 'Fetching accounts..' : 'No accounts'"
  >
    <template v-slot:item="{ item }">
      <div class="d-flex align-center" style="width: 100%">
        <v-avatar class="mr-3" size="20"
          ><img :src="item.profile_picture" /></v-avatar
        >{{ item.name }}
        <v-spacer></v-spacer>
        <v-avatar tile size="20"
          ><v-img :src="require(`@/assets/${item.type}.png`)"></v-img
        ></v-avatar>
      </div>
    </template>

    <template v-slot:selection="{ item }">
      <div
        class="d-flex align-center"
        style="width: 100%"
        v-if="selected_accounts.length === 1"
      >
        <v-avatar class="mr-3" size="20"
          ><img :src="item.profile_picture" /></v-avatar
        >{{ item.name }}
        <v-spacer></v-spacer>
        <v-avatar tile size="20"
          ><v-img :src="require(`@/assets/${item.type}.png`)"></v-img
        ></v-avatar>
      </div>

      <div v-if="selected_accounts.length > 1">
        <div>
          <v-avatar class="mr-2" size="20"
            ><img :src="item.profile_picture"
          /></v-avatar>
        </div>
      </div>
    </template>
  </v-select>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["selected_accounts"],
  computed: {
    ...mapState({
      accounts: (state) => state.accounts,
      loading: (state) => state.loading,
    }),
  },
  methods: {
    sync(v) {
      this.$emit("update:selected_accounts", v);
      this.$emit("input", v);
    },
  },
};
</script>
