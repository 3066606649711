<template>
  <div>
    <v-dialog
      width="500"
      style="border-radius: 10px"
      :value="dialog"
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card
        flat
        width="500"
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }"
      >
        <v-sheet
          class="d-flex justify-center align-center py-2"
          color="accent"
          style="font-size: 10pt; font-weight: 600"
        >
          Save structure
        </v-sheet>
        <v-divider class="primary"></v-divider>
        <div class="pa-4">
          <p class="mb-1" style="font-size: 10pt">Title:</p>
          <v-text-field
            class="mb-2"
            solo
            style="border-radius: 6px"
            flat
            background-color="smoke"
            label="Title"
            hide-details=""
            :value="structure.title"
            @input="(e) => $emit('inputTitle', e.target.value)"
          ></v-text-field>
          <p class="mb-1" style="font-size: 10pt">Structure:</p>
          <v-textarea
            solo
            style="border-radius: 6px"
            hide-details=""
            rows="10"
            flat
            background-color="smoke"
            label="Title"
            :value="structure.text"
            @input="(e) => $emit('inputText', e.target.value)"
          ></v-textarea>
        </div>
        <div class="px-4 pb-4 d-flex justify-space-between align-center">
          <v-btn @click="$emit('close')" elevation="0" text>Close</v-btn>
          <v-btn @click="$emit('save')" elevation="0" color="button"
            >Save</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["structure", "dialog"],
};
</script>

<style lang="scss" scoped>
.email {
  margin-bottom: 15px;
}

::v-deep .v-dialog {
  border-radius: 10px;
}
</style>
