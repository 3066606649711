import { db } from "@/firebase";
import {
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
  addDoc,
  serverTimestamp,
  collection,
  arrayRemove,
  arrayUnion,
  getDocs,
  query,
  where,
  limit,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase";

const workspace = {
  state: {
    workspace: null,
    workspaces: [],
  },
  mutations: {
    setWorkspace(state, val) {
      state.workspace = val;
    },
    setWorkspaces(state, val) {
      state.workspaces = val;
    },
    setKeywords(state, val) {
      state.workspace.about.keywords = val;
    },
    removeInvite(state, val) {
      let index = state.workspace.invites.findIndex((i) => i === val);
      state.workspace.invites.splice(index, 1);
    },

    updateWorkspace(state, val) {
      if (val.type.includes(".")) {
        state.workspace[val.type.split(".")[0]][val.type.split(".")[1]] =
          val.value;
      } else {
        state.workspace[val.type] = val.value;
      }
      console.log(state);
    },
  },
  actions: {
    async removeInvite({ state, commit }, item) {
      await updateDoc(doc(db, "workspaces", state.workspace.uid), {
        invites: arrayRemove(item),
      });
      commit("removeInvite", item);
      commit("setNotification", {
        show: true,
        text: `Invite removed!`,
        type: "primary",
      });
    },
    async updateWorkspace({ commit, state }, val) {
      commit("updateWorkspace", val);
      const ref = doc(db, "workspaces", state.workspace.uid);
      let value = val.value;
      if (val.type.includes("brand.logo_") && value) {
        value = val.value.uid;
      }
      try {
        await updateDoc(ref, {
          [val.type]: value,
        });
        commit("setNotification", {
          show: true,
          text: "Workspace updated!",
          type: "primary",
          icon: "",
        });
      } catch (error) {
        console.log(error);
        commit("setNotification", {
          show: true,
          text: "Error updating workspace!",
          type: "error",
          icon: "",
        });
      }
    },
    async addKeyword({ commit, state }, val) {
      let list = state.workspace.about.keywords;
      list.push(val);
      commit("setKeywords", list);

      const ref = doc(db, "workspaces", state.workspace.uid);
      await updateDoc(ref, {
        "about.keywords": arrayUnion(val),
      });

      commit("setNotification", {
        show: true,
        text: "Keyword added!",
        type: "primary",
        icon: "",
      });
    },
    async removeKeyword({ commit, state }, val) {
      let list = state.workspace.about.keywords;
      let index = list.findIndex((k) => k === val);
      list.splice(index, 1);
      commit("setKeywords", list);
      console.log(val);
      const ref = doc(db, "workspaces", state.workspace.uid);
      await updateDoc(ref, {
        "about.keywords": arrayRemove(val),
      });

      commit("setNotification", {
        show: true,
        text: "Keyword removed!",
        type: "primary",
        icon: "",
      });
    },
    async fetchWorkspace({ commit, rootState }) {
      commit("setLoading", { type: "workspaces", val: true });
      let current_workspace = null;
      let workspaces = [];

      await Promise.all(
        rootState.users.userProfile.workspaces.map(async (w) => {
          let ws = null;
          const docRef = doc(db, "workspaces", w);
          const workspace = await getDoc(docRef);

          ws = workspace.data();

          if (ws.subscription_active) {
            const q = query(
              collection(db, "workspaces", w, "subscriptions"),
              where("active", "==", true),
              limit(1)
            );
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              ws.subscription = doc.data();
            });
          }

          ws.members.map(async (m, i) => {
            let member = m;
            console.log(m);
            const docRef = doc(db, "users", m.uid);
            const docSnap = await getDoc(docRef);

            member.name = docSnap.data().name;
            member.email = docSnap.data().email;
            ws.members[i] = member;
          });
          console.log(ws);

          if (ws.brand) {
            if (ws.brand.logo_light) {
              const docSnapL = await getDoc(
                doc(db, "workspaces", ws.uid, "brand", ws.brand.logo_light)
              );

              if (docSnapL.exists()) {
                ws.brand.logo_light = docSnapL.data();
              }
            }

            if (ws.brand.logo_dark) {
              const docSnapD = await getDoc(
                doc(db, "workspaces", ws.uid, "brand", ws.brand.logo_dark)
              );

              if (docSnapD.exists()) {
                ws.brand.logo_dark = docSnapD.data();
              }
            }
          }

          workspaces.push(ws);
          if (rootState.users.userProfile.workspace === w) {
            current_workspace = ws;
          }
        })
      );

      commit("setWorkspace", current_workspace);
      commit("setWorkspaces", workspaces);
      commit("setLoading", { type: "workspace", val: false });
    },
    async changeWorkspace({ dispatch, rootState }, uid) {
      await updateDoc(doc(db, "users", rootState.users.userProfile.uid), {
        workspace: uid,
      });

      const auth = getAuth();
      const user = auth.currentUser;
      dispatch("fetchUserProfile", user);
    },
    async deleteWorkspace({ dispatch, rootState }, uid) {
      return new Promise((resolve, reject) => {
        (async () => {
          try {
            const docRef = doc(db, "workspaces", uid);
            const workspace = await getDoc(docRef);
            let allowed =
              workspace
                .data()
                .members.find((u) => u.uid === rootState.users.userProfile.uid)
                .role === "admin";
            if (!allowed) {
              reject();
            } else {
              let newWorkspace = rootState.users.userProfile.workspaces.find(
                (w) => w != uid
              );
              await updateDoc(
                doc(db, "users", rootState.users.userProfile.uid),
                {
                  workspaces: arrayRemove(uid),
                  workspace: newWorkspace != undefined ? newWorkspace : null,
                }
              );

              await deleteDoc(doc(db, "workspaces", uid));

              const auth = getAuth();
              const user = auth.currentUser;
              dispatch("fetchUserProfile", user);
              resolve();
            }
          } catch (error) {
            reject(error); // Handle errors
          }
        })();
      });
    },
    async createWorkspace({ commit, dispatch, rootState }, data) {
      commit("setLoading", { type: "general", val: true });
      let emails = [];
      if (!data.skip) {
        for (let x in data.invites) {
          if (data.invites[x].email) {
            emails.push(data.invites[x].email);
          }
        }
      }

      console.log("Creating workspace for:");
      console.log(rootState.users.userProfile.email);

      const newWork = await addDoc(collection(db, "workspaces"), {
        about: {
          name: data.name,
          target: data.audience,
          company: data.description,
          product: data.product,
          website: data.website,
          company_size: data.company_size,
          keywords: [],
        },
        country: data.country,
        timezone: data.timezone,
        members: [
          {
            uid: data.uid,
            role: "admin",
          },
        ],
        type: data.type,
        invites: emails,
        created_at: serverTimestamp(),
        referral: data.referral,
        suggestions: [],
        brand: {
          primary: "",
          text: "",
          secondary: "",
          button: "",
          name: data.name,
          logo_light: "",
          logo_dark: "",
        },
        preferences: {
          emojis: false,
        },
        counter: {
          posts: 0,
          categories: 0,
          media: 0,
          media_size: 0,
          tokens: 10000,
          accounts: 0,
          schedules: 0,
          templates: 0,
        },
        subscription: {
          active: true,
          cancelled: false,
          discount_code: null,
          discount_value: null,
          invoices_email: "",
          renewal_date: Math.floor(Date.now() / 1000),
          trial: true,
          trial_end: Math.floor(Date.now() / 1000),
        },
      });

      await updateDoc(doc(db, "workspaces", newWork.id), {
        uid: newWork.id,
      });

      await updateDoc(doc(db, "users", data.uid), {
        uid: data.uid,
        workspace: newWork.id,
        workspaces: arrayUnion(newWork.id),
      });
      const fillCalendar = httpsCallable(functions, "fillCalendar");
      let reqData = { workspace_uid: newWork.id };
      console.log(reqData);
      await fillCalendar(reqData);

      const auth = getAuth();
      const user = auth.currentUser;
      dispatch("fetchUserProfile", user);
      if (!data.skip) {
        await addDoc(collection(db, "mail"), {
          bcc: emails,
          from: "Stix <info@stix.social>",
          template: {
            name: "colleague-invite",
            data: {
              first_name: data.first_name,
            },
          },
          message: {},
        });
      }
      commit("setLoading", { type: "general", val: false });

      return 200;
    },
  },
  getters: {},
};

export default workspace;
