var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.loading.workspace && _vm.workspace)?_c('v-card',{staticClass:"pb-4",style:({
        borderBottom: '3px solid #272727',
        borderLeft: '1px solid #272727',
        borderTop: '1px solid #272727',
        borderRight: '3px solid #272727',
      }),attrs:{"flat":"","max-width":"800"}},[_c('div',[_c('v-sheet',{staticClass:"d-flex justify-center align-center py-2",staticStyle:{"font-size":"10pt","font-weight":"600","border-top-right-radius":"7px","border-top-left-radius":"7px"},attrs:{"color":"accent"}},[_vm._v(" Users ")]),_c('v-divider',{staticClass:"primary"})],1),_c('div',{staticClass:"d-flex justify-space-between align-center mb-2 px-6 pt-4",staticStyle:{"gap":"20px"}},[_c('InviteDialog',{ref:"invite"})],1),_c('v-data-table',{staticClass:"px-2",attrs:{"headers":_vm.headers,"items":_vm.members,"mobile-breakpoint":"100","sort-by":"email","hide-default-footer":_vm.workspace.members.length < 11},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [(item.invited)?_c('v-tooltip',{attrs:{"bottom":"","color":"primary","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.resendInvite(item.email)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","size":"16"}},[_vm._v("mdi-email-sync")])],1)]}}],null,true)},[_c('div',[_c('span',{staticClass:"white--text"},[_vm._v("Resend invite")])])]):_vm._e(),_c('v-btn',{attrs:{"icon":"","disabled":_vm.userProfile.role != 'admin' ||
              _vm.userProfile.uid === item.uid ||
              (item.role === 'admin' && !item.invited)}},[_c('v-icon',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.remove(item)}}},[_vm._v(" mdi-delete ")])],1)]}},{key:`item.email`,fn:function({ item }){return [_c('span',{style:({ opacity: item.invited ? 0.7 : 1 })},[_vm._v(" "+_vm._s(item.email))]),(item.invited)?_c('v-chip',{staticClass:"ml-3",attrs:{"small":"","color":"accent"}},[_vm._v("Invite pending")]):_vm._e()]}},{key:`item.role`,fn:function({ item }){return [(
              _vm.members.find((m) => m.uid === _vm.userProfile.uid).role === 'admin' &&
              item.uid != _vm.userProfile.uid
            )?_c('v-select',{staticStyle:{"border-radius":"6px","max-width":"140px"},attrs:{"solo":"","background-color":"smoke","flat":"","label":"Role","dense":"","hide-details":"","items":_vm.roles},on:{"change":function($event){return _vm.setRole(item)}},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.capitalize(item)))])]}},{key:"item",fn:function({ item }){return [_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.capitalize(item)))])]}}],null,true),model:{value:(item.role),callback:function ($$v) {_vm.$set(item, "role", $$v)},expression:"item.role"}}):_c('span',[_vm._v(_vm._s(_vm.capitalize(item.role)))])]}}],null,true)})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }