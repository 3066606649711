<template>
  <v-navigation-drawer
    dark
    permanent
    app
    color="primary"
    mini-variant
    mini-variant-width="90"
    v-if="!$route.meta.noNav"
  >
    <div class="mx-auto d-flex flex-column align-center mb-8 mt-4">
      <v-img contain width="35" src="@/assets/logo/logo-white.svg" alt="" />
    </div>

    <v-list nav dark>
      <v-list-item
        v-for="i in items"
        :key="i.route"
        link
        :to="i.route"
        :style="{ opacity: getOpacity(i.route) }"
        active-class="active"
      >
        <div class="d-flex flex-column py-2">
          <v-icon size="20" style="padding: 1px 0" :color="getColor(i.route)">{{
            i.icon
          }}</v-icon>
          <p
            class="white--text mt-1 mb-0"
            style="font-size: 12px; font-weight: 500"
          >
            {{ i.title }}
          </p>
        </div>
      </v-list-item>

      <v-list-item
        v-if="
          $store.state.users.userProfile &&
          $store.state.users.userProfile.superadmin
        "
        link
        to="/admin"
        :style="{ opacity: $route.path.includes('/admin') ? 1 : 0.7 }"
      >
        <div class="d-flex flex-column py-2">
          <v-icon
            size="20"
            style="padding: 1px 0"
            :color="$route.path.includes('/admin') ? 'button' : 'white'"
            >mdi-shield-crown</v-icon
          >
          <p
            class="white--text mt-1 mb-0"
            style="font-size: 12px; font-weight: 500"
          >
            Admin
          </p>
        </div>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <WorkspacePicker v-if="$store.state.users.userProfile" />
    </template>
  </v-navigation-drawer>
</template>
<script>
import WorkspacePicker from "@/components/WorkspacePicker.vue";

export default {
  components: { WorkspacePicker },
  data: () => ({
    items: [
      { title: "Dashboard", route: "/", icon: "mdi-home" },
      {
        title: "Feed",
        route: "/feed",
        icon: "mdi-view-column",
      },
      {
        title: "Library",
        route: "/library",
        icon: "mdi-bookshelf",
      },
      {
        title: "Calendar",
        route: "/calendar",
        icon: "mdi-calendar",
      },
      { title: "Categories", route: "/categories", icon: "mdi-shape" },
      {
        title: "Schedules",
        route: "/schedule",
        icon: "mdi-book-clock-outline",
      },
      {
        title: "Settings",
        route: "/settings",
        icon: "mdi-cog",
      },
    ],
    menu: false,
  }),
  methods: {
    getColor(route) {
      let path = this.$route.path;

      if (path === route) {
        return "success";
      } else if (route.includes("/settings") && path.includes("/settings")) {
        return "success";
      } else if (route.includes("/library") && path.includes("/library")) {
        return "success";
      } else {
        return "white";
      }
    },
    getOpacity(route) {
      let path = this.$route.path;

      if (path === route) {
        return 1;
      } else if (route.includes("/settings") && path.includes("/settings")) {
        return 1;
      } else if (route.includes("/library") && path.includes("/library")) {
        return 1;
      } else {
        return 0.7;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  // border-bottom: 3px solid black;
  // border-left: 1px solid black;
  // border-top: 1px solid black;
  // border-right: 3px solid black;
}
</style>
