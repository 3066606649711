<template>
  <div>
    <v-dialog
      width="500"
      v-model="dialog"
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          elevation="0"
          color="primary"
          class="px-8 topic-button"
          height="40"
          >Add structure</v-btn
        >
      </template>
      <v-card
        flat
        :style="{
          borderBottom: '3px solid #272727',
          borderLeft: '1px solid #272727',
          borderTop: '1px solid #272727',
          borderRight: '3px solid #272727',
        }"
      >
        <div>
          <v-sheet
            class="d-flex justify-center align-center py-2"
            color="accent"
            style="
              font-size: 10pt;
              font-weight: 600;
              border-top-right-radius: 7px;
              border-top-left-radius: 7px;
            "
          >
            Add structure
          </v-sheet>
          <v-divider class="primary"></v-divider>
        </div>
        <div class="pa-4">
          <v-text-field
            placeholder="Title"
            dense
            hide-details=""
            solo
            flat
            style="font-size: 10pt"
            class="mb-2"
            v-model="title"
          ></v-text-field>
          <v-divider class="ma-2"></v-divider>
          <v-textarea
            placeholder="Write your structure here.."
            solo
            v-model="structure"
            flat
            autofocus
            hide-details=""
            dense
            style="border-radius: 6px; font-size: 10pt"
            hint="Create your own structure, so Stix AI can write better posts for you."
            no-resize
            rows="10"
            full-width
          ></v-textarea>
        </div>
        <v-divider></v-divider>
        <div class="d-flex pa-4">
          <v-btn icon><v-icon small>mdi-help-circle</v-icon></v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="close" elevation="0" small text class="px-6 mr-2"
            >Close</v-btn
          >
          <v-btn elevation="0" small class="px-6" color="button" @click="save"
            >Save</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      title: "",
      uid: null,
      structure: "",
    };
  },
  methods: {
    close() {
      this.title = "";
      this.uid = null;
      this.structure = "";
      this.dialog = false;
    },
    edit(s) {
      this.dialog = true;
      this.uid = s.uid;
      this.structure = s.structure;
      this.title = s.title;
    },
    save() {
      this.$store.dispatch("saveStructure", {
        uid: this.uid,
        text: this.structure,
        title: this.title,
      });
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
</style>
